import React from 'react';
import {makeStyles} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    spinnerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

const ContentLoading = () => {
    const classes = useStyles();
    return (
        <div className={classes.spinnerContainer}>
            <CircularProgress />
        </div>
    )
}

export default ContentLoading;
