import React, { useCallback } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ASPECHeader from "./ASPECHeader";
import withRouter from "../../../../helpers/withRouter";
import AnalysisDetails from "./LeftMenuItems/AnalysisDetails";
import { NotificationManager } from "react-notifications";
import ASPECPredefinedData from "./LeftMenuItems/ASPECPredefinedData";
import { setNestedValue, checkForData } from "../../../../helpers";
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import ContentLoading from "../../../common/ContentLoading";
import ActivityLog from "./LeftMenuItems/ActivityLog";
import MissionMixChangeDialog from "./MissionMixChangeDialog";
import { fetchAuthSession } from 'aws-amplify/auth';
import { cfg } from "../../../../config";
import axios from "axios";
import moment from "moment";
import AnalysisInfo from "./LeftMenuItems/AnalysisInfo";
import FlightSegmentDef from "./LeftMenuItems/FlightSegmentDef";
import ASPECForm_variant2 from "./ASPECForm_variant2";
import { useParams } from "react-router-dom";

const ASPECAnalysis = (props) => {
  const params = useParams();

  const [data, setData] = React.useState({});
  const [lock, setLock] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRunning, setIsRunning] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [missionLock, setMissionLock] = React.useState(false);
  const [project, setProject] = React.useState({
    projectName: "",
    aircraft: "",
    projectID: 1,
    refs: [],
  });
  const [mmOpen, setMmOpen] = React.useState(false);
  const [config, setConfig] = React.useState({});
  const [tempMissionMix, setTempMissionMix] = React.useState("");

  React.useEffect(() => {
    fetchData()
      .then((aircraft) => fetchConfig(aircraft))
      .catch((e) => console.log(e));
  }, []);

  const saveDraft = async () => {
    setIsSaving(true);

    let newData = { ...data };

    if (newData.name === lock.name) {
      delete newData.name;
    }
    delete newData.activityLog;
    delete newData.files;

    return new Promise((resolve, reject) => {
      fetchAuthSession().then((session) => {
        axios
          .patch(
            `${cfg.apiUrl}/app/aspec/analysis/${params.projectId}/${params.id}`,
            {
              ...newData,
            },
            {
              headers: {
                Authorization: session.tokens?.idToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((resp) => {
            let tempLock = { ...lock };
            tempLock.name = data.name;
            setLock(tempLock);

            if (resp.status === 200) {
              console.log("Draft Saved.");
              NotificationManager.success(`Data saved succesfully`, 'SUCCESS');
              resolve();
            } else {
              reject();
              NotificationManager.error(
                "Something went wrong when fetching data. Please check console for more info.",
                "ERROR"
              );
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 400) {
                NotificationManager.error(
                  err.response.data.error.message,
                  "Error"
                );
              } else {
                NotificationManager.error(
                  "An error has occurred, please try again.",
                  "Error"
                );
              }
            } else if (err.request) {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            } else {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            }
          })
          .finally(() => {
            setIsSaving(false);
          });
      });
    });
  };

  // const memoizedSaveDraft = useCallback(saveDraft, [data]);

  // React.useEffect(() => {
  //   if (!isLoading) {
  //     memoizedSaveDraft();
  //   }
  // }, [data]); // memoizedSaveDraft

  const fetchConfig = (aircraft) => {
    fetchAuthSession().then((session) => {
      axios
        .get(
          `${cfg.apiUrl}/app/aspec/config/${aircraft.replace(/\s/g, "-").toLowerCase()}`,
          {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((resp) => {
          setConfig(resp.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const fetchData = async () => {
    // Axios logic for fetching data pertaining to analysis ID
    return new Promise((resolve, reject) => {
      fetchAuthSession().then((session) => {
        axios
          .get(`${cfg.apiUrl}/app/aspec/analysis/${params.projectId}/${params.id}`, {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          })
          .then((resp) => {
            if (resp.status === 200) {
              const newData = {
                name: resp.data.name,
                args: {
                  DMF: checkForData(resp, "data.args.DMF", false),
                  overrideManeuverSegments: checkForData(
                    resp,
                    "data.args.overrideManeuverSegments",
                    false
                  ),
                  enableNegativeStress: checkForData(resp, "data.args.enableNegativeStress", false),
                  flightCount: checkForData(resp, "data.args.flightCount", 0),
                  aircraft: checkForData(
                    resp,
                    "data.args.aircraft",
                    checkForData(resp, "data.project.aircraft", null)
                  ),
                  missionMix: checkForData(resp, "data.args.missionMix", ""),
                  outputFiles: {
                    AFGROW: checkForData(
                      resp,
                      "data.args.outputFiles.AFGROW",
                      true
                    ),
                    NASGRO: checkForData(
                      resp,
                      "data.args.outputFiles.NASGRO",
                      false
                    ),
                    CRK2K: checkForData(
                      resp,
                      "data.args.outputFiles.CRK2K",
                      false
                    ),
                    FLIPSPEC: checkForData(
                      resp,
                      "data.args.outputFiles.FLIPSPEC",
                      false
                    ),
                  },
                  includeDamageCode: checkForData(
                    resp,
                    "data.args.includeDamageCode",
                    false
                  ),
                  verboseOutput: checkForData(
                    resp,
                    "data.args.verboseOutput",
                    false
                  ),
                  keepNegNegPairs: checkForData(
                    resp,
                    "data.args.keepNegNegPairs",
                    false
                  ),
                  gagCycleType: checkForData(
                    resp,
                    "data.args.gagCycleType",
                    "MinMax"
                  ),
                },
                data: checkForData(resp, "data.data", { missions: [] }),
                activityLog: [
                  {
                    title: "Date Created",
                    content: resp.data.created,
                  },
                  {
                    title: "Last Update",
                    content: resp.data.modified,
                  },
                ],
                results: checkForData(resp, "data.results", {}),
                status: resp.data.status,
                runDate: resp.data.runDate ? moment(resp.data.runDate).format("ll") : "",
              };

              setProject(resp.data.project);
              setData(JSON.parse(JSON.stringify(newData)));
              setLock(JSON.parse(JSON.stringify(newData)));
              resolve(newData.args.aircraft);
            } else {
              reject();
              NotificationManager.error(
                "Something went wrong when fetching data. Please check console for more info.",
                "ERROR"
              );
            }
          })
          .catch((e) => {
            console.log(e);
            NotificationManager.error(
              "Something went wrong when fetching data. Please check console for more info.",
              "ERROR"
            );
            reject(e);
          });
      });
    });
  };

  /*
    Updates the current state.data.
     */
  const updateData = (value, objectLocation, forceUpdate = false) => {
    let newData = setNestedValue({ ...data }, objectLocation, value);
    setData(newData);
  };

  const runAnalysis = async () => {
    setIsLoading(true);
    setIsRunning(true);

    await saveDraft();

    console.log("... sleeping for 1 second");
    await sleep(1000); // temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)

    fetchAuthSession().then((session) => {
      axios
        .post(
          `${cfg.apiUrl}/app/aspec/analyze`,
          {
            id: params.id,
            projectId: params.projectId,
          },
          {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (resp) => {
          if (resp.status === 200) {
            await fetchData();
            setIsLoading(false);
            NotificationManager.success(`Analysis run succesfully`, 'SUCCESS');
          } else {
            NotificationManager.warning(
              "Something went wrong, please check the console.",
              "WARNING"
            );
          }
        })
        .catch(async (e) => {
          const errorMessage = e?.response?.data?.error?.message;
          if (errorMessage) {
            NotificationManager.error(
              errorMessage,
              "ERROR"
            );
          } else {
            NotificationManager.error(
              "Something went wrong, please see console for details.",
              "ERROR"
            );
          }
          console.log(e);
          await fetchData();
          setIsLoading(false);
        })
        .finally(async (resp) => {
          setIsRunning(false);
        });
    });
  };

  /*
    Mission Mix methods
        When a missionMix is selected, the form component will populate the form.
        If the form has already been manipulated, changing mission mix will create a new empty form.
        These require the user to confirm mission mix change if form is manipulated.
     */
  const setMissionMix = (e) => {
    let missionMix = e.target.value
    if (data.args.missionMix !== "") {
      setMmOpen(true);
      setTempMissionMix(missionMix);
    } else {
      let tempData = { ...data };
      tempData.args.missionMix = missionMix;
      tempData.args.flightCount = getFlightCountForMissionMix(missionMix);
      tempData.data.missions = getMissionsForMissionMix(missionMix);
      setData(tempData);
    }
  };

  const getMissionsForMissionMix = (missionMixName) => {
    let tempMissions = [];
    for (let i = 0; i < config.missionMixes.length; i++) {
      if (config.missionMixes[i].name === missionMixName) {
        tempMissions = config.missionMixes[i].missions;
      }
    }
    return tempMissions;
  };

  const getFlightCountForMissionMix = (id) => {
    for (let i = 0; i < config.missionMixes.length; i++) {
      if (config.missionMixes[i].name === id) {
        return config.missionMixes[i].flight_count;
      }
    }
    return 0;
  };

  const confirmMissionMix = () => {
    let tempData = { ...data };
    tempData.args.missionMix = tempMissionMix;
    tempData.args.flightCount = getFlightCountForMissionMix(tempMissionMix);
    console.log(tempData.args.flightCount)
    tempData.data.missions = getMissionsForMissionMix(tempMissionMix);
    setTempMissionMix("");
    setData(tempData);
    setMmOpen(false);
  };

  const pasteAnalysis = async () => {
    const copied = JSON.parse(window.sessionStorage.getItem("copyProject"));
    if (!copied) return;
    if (
      copied.args.aircraft === data.args.aircraft &&
      copied.args.missionMix === data.args.missionMix
    ) {
      let name = data.name;
      copied.name = name;
      setData(copied);
      setLock(copied);
      window.sessionStorage.removeItem("copyProject");
    } else {
      NotificationManager.warning(
        "Cannot copy analysis. MissionMix/Aircraft mismatch.",
        "FAILED"
      );
    }
  };

  // Modeled Data

  const addModeledData = async (missionId) => {
    let modeled = {
      name: "modeled-data", // keep for backward compatibility
      type: "modeled",
      group: {
        type: " ", // need blank space for regular segment
        id: 1,
      },
      equation: 3, // Ground and Taxi
      loadPairs: [{ max: 0, min: 0, cycles: 0 }],
      values: {
        dmf: null,
        constantLoad: 0,
        alternatingLoad: 0,
        pressureLoad: null,
      },
    };
    let newData = { ...data };
    // Only add modeled data to the current mission{
    newData.data.missions[missionId].segments.unshift(
      JSON.parse(JSON.stringify(modeled))
    );
    setData(newData);
  };

  const moveSegment = async (from, to, missionId) => {
    let newData = { ...data };
    const [removed] = newData.data.missions[missionId].segments.splice(from, 1);
    newData.data.missions[missionId].segments.splice(to, 0, removed);
    setData(newData);
  };

  const removeSegment = (idx, missionId) => {
    let newData = { ...data };
    newData.data.missions[missionId].segments.splice(idx, 1);
    setData(newData);
  };

  const addModeledPair = (missionIndex, segmentIndex) => {
    let newData = { ...data };
    let pair = { min: 0, max: 0, cycles: 0 };
    newData.data.missions[missionIndex].segments[segmentIndex].loadPairs.push(
      pair
    );
    setData(newData);
  };

  const removeModeledDataPair = (missionIndex, segmentIndex, loadPairIndex) => {
    let newData = { ...data };
    newData.data.missions[missionIndex].segments[segmentIndex].loadPairs.splice(
      loadPairIndex,
      1
    );
    setData(newData);
  };

  // Custom segment

  const addCustomSegment = async (missionId, occurrence_distributions) => {
    // Use 1st occurence distribution as default for custom segment id and name
    let segmentId, segmentName, equation;
    if (occurrence_distributions && occurrence_distributions.length  > 0) {
      segmentId = occurrence_distributions[0].id;
      segmentName = occurrence_distributions[0].name;
      equation = occurrence_distributions[0].equation;
    } else {
      segmentId = "01";
      segmentName = "";
      equation = null;
    }

    // Create custom segment data
    let customSegment = {
      name: segmentName,
      id: segmentId,
      type: "custom",
      group: {
        type: " ", // need blank space for regular segment
        id: 1,
      },
      duration: 1,
      values: {
        dmf: null,
        constantLoad: 0,
        alternatingLoad: 0,
        pressureLoad: equation === 3 ? null : 0, // pressure is not allowed for equation 3
      },
    };

    // Add new segment to mission
    let newData = { ...data };
    // Only add custom segment to the current mission{
    newData.data.missions[missionId].segments.unshift(
      JSON.parse(JSON.stringify(customSegment))
    );
    setData(newData);
  };

  return !isLoading ? (
    <div>
      <ASPECHeader
        projectID={project.projectID}
        data={data}
        status={data.status}
        isSaving={isSaving}
        isRunning={isRunning}
        runButtonDisabled={data.args.missionMix === "" || data.args.gagCycleType === ""}
        functions={{
          updateData: updateData,
          saveDraft: saveDraft,
          submit: runAnalysis,
          paste: pasteAnalysis,
        }}
      />
      <MissionMixChangeDialog
        open={mmOpen}
        confirmMissionMixChange={confirmMissionMix}
        cancel={() => setMmOpen(false)}
      />
      <Grid container spacing={2} style={{ paddingBottom: "8px" }}>
        <Grid item md={7}>
          <AnalysisDetails
            refs={project.refs}
            name={data.name}
            updateData={updateData}
            project={project}
            run={data.runDate}
            status={data.status}
          />
        </Grid>
        <Grid item md={5}>
          <ActivityLog data={data} />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingBottom: "18px" }}>
        <Grid item md={4}>
          <ASPECPredefinedData
            status={data.status}
            isSaving={isSaving}
            isRunning={isRunning}
            data={data}
            updateData={updateData}
            setMissionMix={setMissionMix}
            config={config}
          />
        </Grid>
        <Grid item md={4}>
          <div>
            <AnalysisInfo config={config} missionMix={data.args.missionMix} />
          </div>
        </Grid>
        <Grid item md={4}>
          <FlightSegmentDef />
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <ASPECForm_variant2
            refresh={fetchData}
            removeModeledDataPair={removeModeledDataPair}
            addModeledPair={addModeledPair}
            removeSegment={removeSegment}
            moveSegment={moveSegment}
            addModeledData={addModeledData}
            addCustomSegment={addCustomSegment}
            aircraft={data.args.aircraft}
            dmf={data.args.DMF}
            overrideManeuverSegments={data.args.overrideManeuverSegments}
            enableNegativeStress={data.args.enableNegativeStress}
            flightCount={data.args.flightCount}
            missionMix={data.args.missionMix}
            data={data.data.missions}
            args={data.args}
            config={config}
            status={data.status}
            isSaving={isSaving}
            isRunning={isRunning}
            update={updateData}
            saveDraft={saveDraft}
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <div>
      <ContentLoading />
    </div>
  );
};

// Temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default withStyles(ASPECStyles, { withTheme: true })(
  withRouter(ASPECAnalysis)
);
