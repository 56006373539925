export const cfg = {
  Auth: {
    Cognito: {
      userPoolClientId: '245k87rdbahu452elprl76c2ds',
      userPoolId: 'us-east-1_xcId7CI5E',
      mandatorySignIn: true,
      region: 'us-east-1',
      identityPoolId: 'us-east-1:4d1482cb-daa5-4c29-9c47-ef4ffbf62b29',
    },
  },
  Storage: {
    S3: {
      region: 'us-east-1',
      bucket: 'aspec-data-dev',
      identityPoolId: 'us-east-1:4d1482cb-daa5-4c29-9c47-ef4ffbf62b29',
      level: 'public',
      customPrefix: {
        public: ''
      },
    },
  },
  apiUrl:  process.env.REACT_APP_API_URL || 'https://oie5gyk0ce.execute-api.us-east-1.amazonaws.com/dev',
  domain: 'aeronauticausa', // used to show 2 skin effectivity options on aeronauticausa.com (only 1 option on govCloud)
};
