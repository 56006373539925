/*
Basic link card found on the Dashboard page.
Props: Title, Subtitle, Link, and Icon
Icons use Material font icons: https://material.io/resources/icons/?style=baseline
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Card, CardContent, Typography, makeStyles, CssBaseline} from '@material-ui/core';
import Icon from '@material-ui/core/Icon'
import {useTheme} from "@material-ui/core/styles";
import LinkCardStyles from "../../styles/jss/components/common/LinkCardStyles";
import { useNavigate  } from "react-router-dom";

const useStyles = makeStyles(LinkCardStyles);

const LinkCard = (props) => {
    const {title, subtitle, link, icon, history} = props;
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    return(
      <Grid container>
        <Grid xs={12} md={6} lg={3} item className={classes.rootContainer}>
            <CssBaseline/>
            <Card className={classes.cardContainer} onClick={() => navigate(link)}>
                <CardContent className={classes.content}>
                    <Icon className={classes.icon} color={theme.palette.grey[3]}>{icon}</Icon>
                    <Typography className={classes.title} variant={'h6'} >
                        {title}
                    </Typography>
                    <Typography className={classes.subtitle} variant={'body2'} >
                        {subtitle}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
      </Grid>
    );
};

LinkCard.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string
};

LinkCard.defaultProps = {
    title: "Title",
    subtitle: "Subtitle",
    link: '/apps',
    icon: 'apps'
};

export default LinkCard;

