import { Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
  const { tokens } = useTheme();

  return (
    <div style={{textAlign: 'center'}}>
      <Image
        alt="Aeronautica Logo"
        src={'/AeronauticaDark.svg'}
        padding={tokens.space.medium}
      />
      <h2 style={{marginBottom: '30px'}}>Sign in or sign up to Aeronautica</h2>
    </div>
  );
}
