import * as React from 'react';
import TextField from '@material-ui/core/TextField';
// import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Checkbox,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
 const convertPixelsToRem = (unitValue) => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

const CellDataTable = (props) => {
  const tableUnlocked = props.status !== "SUCCESSFUL";

  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
    },

  });

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  // used to setup styles for short text fields
  const ShortTextField = withStyles((theme) => ({
    root: {
      minWidth: 60,
      maxWidth: 85,
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);


  const handleTextfieldChange = async (e, cellRowIdx, column, section, props) => {
    if (section !== '') {
      props.update(parseFloat(e.target.value), `data.cellData[${cellRowIdx}].${section}.${column}`);
    } else {
      props.update(parseFloat(e.target.value), `data.cellData[${cellRowIdx}].${column}`);
    }
  };

  const cellDataColumns = ['t', 'delS', 'geG'];


  const getField = (cellData, column, cellRowIdx, section='') => {
    const value = getValue(cellData, column);
    return (
      <ShortTextField
        name={column}
        defaultValue={value}
        error={value ? Boolean(value.error) : false}
        focused={value ? value.focus : false}
        type={'text'}
        variant="outlined"
        onBlur={(e) => handleTextfieldChange(e, cellRowIdx, column, section, props)}
      />
    );

  };

  const getValue = (cellData, column, digits=6) => {
    const val = cellData[column];
    return Math.round((val + Number.EPSILON) * 10**digits) / 10**digits // round to x digits
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && props.data && props.data.cellData) {
      const newSelecteds = props.data.cellData.map((_, i) => i);
      props.setSelectedRows(newSelecteds);
      return;
    }
    props.setSelectedRows([]);
  };

  const handleClick = (event, elecIdent) => {
    const selectedIndex = props.selectedRows.indexOf(elecIdent);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedRows, elecIdent);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedRows.slice(1));
    } else if (selectedIndex === props.selectedRows.length - 1) {
      newSelected = newSelected.concat(props.selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedRows.slice(0, selectedIndex),
        props.selectedRows.slice(selectedIndex + 1)
      );
    }

    props.setSelectedRows(newSelected);
  };

  const isSelected = (elecIdent) => props.selectedRows.indexOf(elecIdent) !== -1;

  const rowCount = () =>
    props.data && props.data.cellData ? props.data.cellData.length : 0;

  // overflowX: 'unset' to avoid small vertical scroll issue (space after the last row)
  return (
    <TableContainer className="hideScrollbar" style={{overflowX: 'unset'}}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
          {tableUnlocked && (
            <TableCell rowSpan={2} padding="checkbox" align='center' style={{padding: 4}}>
              <Checkbox
                color="primary"
                size="small"
                style={{ padding: 2 }}
                indeterminate={
                  props.selectedRows.length > 0 && props.selectedRows.length < rowCount()
                }
                checked={rowCount() > 0 && props.selectedRows.length === rowCount()}
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all circuit breakers',
                }}
              />
            </TableCell>
          )}
            <TableCell
              align="center"
              rowSpan={2}
              style={{
                padding: 8,
                width: "16%",
                borderRight: 1,
                borderRightStyle: "solid",
                borderRightColor: "rgba(224, 224, 224, 1)",
              }}>
                <b>From Cell No</b>
            </TableCell>
            <TableCell
              align="center"
              rowSpan={2}
              style={{
                padding: 8,
                width: "16%",
                borderRight: 1,
                borderRightStyle: "solid",
                borderRightColor: "rgba(224, 224, 224, 1)",
              }}>
                <b>To Cell No</b>
            </TableCell>
            <TableCell align="center" style={{padding: 8, width: "24%"}}><b>t</b></TableCell>
            <TableCell align="center" style={{padding: 8, width: "24%"}}><b>Del S</b></TableCell>
            <TableCell
              align="center"
              style={{padding: 8, width: "24%"}}
              ><b>Ge/G</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.cellData
            ? props.data.cellData.map((row, rowIdx) => {
                const isItemSelected = isSelected(rowIdx);

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`cellData-${rowIdx}`}
                    selected={isItemSelected}
                  >
                    {tableUnlocked && (
                      <TableCell padding="checkbox" align='center'>
                        <Checkbox
                          color="primary"
                          size="small"
                          style={{ padding: 0 }}
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, rowIdx)}
                        />
                      </TableCell>
                    )}
                      <TableCell
                        className={useStyles.tableRightBorder}
                        align='center'
                        key='FromCellNo'
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingRight: 0,
                          paddingLeft: 0,
                          width: "16%",
                          borderRight: 1,
                          borderRightStyle: "solid",
                          borderRightColor: "rgba(224, 224, 224, 1)",
                        }}
                      >
                        {rowIdx + 1}
                      </TableCell>
                      <TableCell
                        className={useStyles.tableRightBorder}
                        align='center'
                        key='ToCellNo'
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingRight: 0,
                          paddingLeft: 0,
                          width: "16%",
                          borderRight: 1,
                          borderRightStyle: "solid",
                          borderRightColor: "rgba(224, 224, 224, 1)",
                        }}
                      >
                        {rowIdx + 2}
                      </TableCell>

                    {cellDataColumns.map(function (column) {
                      return (
                        <TableCell
                          align={'center'}
                          key={column}
                          style={{
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: 1,
                            paddingLeft: 1,
                            width: "24%",
                          }}
                        >
                          {tableUnlocked
                            ? getField(row, column, rowIdx)
                            : getValue(row, column)}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CellDataTable;
