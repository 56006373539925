import React from "react";
import { Card, CardContent, Typography, TextField, InputAdornment, makeStyles, Box, CardHeader, Grid } from "@material-ui/core";
import ASPECStyles from "../../../../../styles/jss/components/apps/ASPECStyles";
import ImportData from "../ImportData";

const useStyles = makeStyles(ASPECStyles);

const FuseBodyGeometry = (props) => {
  const status = props.status === "SUCCESSFUL";
  const { data, updateData } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    // e.preventDefault();
    const target = e.target;
    const name = e.target.name;
    const value = target.type === "checkbox" ? target.checked : parseFloat(target.value);
    console.log("handleChange: ", value, name);
    updateData(value, name);
  };

  // const hasError = React.useMemo(() => value === "error", [value]);
  // function isBlank(str) {
  //   return /^\s*$/.test(str); // test for blank string
  // }

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title={"Body Geometry"}
        classes={{
          title: classes.cardHeaderTitle,
          root: classes.cardHeaderRoot,
        }}
        action={
          <div>
            <ImportData
              status={status}
              isRunning={props.isRunning}
              isSaving={props.isSaving}
              refresh={props.refresh}
              updateData={props.updateData}
              aircraft={props.aircraft}
              saveDraft={props.saveDraft}
            />
          </div>
        }
      />
      <CardContent>
        {status ? (
          <div>
            <Grid container spacing={4}>
              <Grid item lg={2} md={3} sm={6}>
                <Typography variant={"caption"}>Frame spacing:</Typography>
                <Typography variant={"body2"}>{data.args.frameSpacing}</Typography>
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <Typography variant={"caption"}>Modulus of Elasticity:</Typography>
                <Typography variant={"body2"}>{data.args.modulusOfElasticity}</Typography>
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <Typography variant={"caption"}>Body station:</Typography>
                <Typography variant={"body2"}>{data.args.bodyStation}</Typography>
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <Typography variant={"caption"}>Pressure factor:</Typography>
                <Typography variant={"body2"}>{data.args.pressureFactor}</Typography>
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <Typography variant={"caption"}>Reference waterline:</Typography>
                <Typography variant={"body2"}>{data.args.referenceWaterline}</Typography>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid container spacing={4}>
              <Grid item lg={2} md={3} sm={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={data.args.frameSpacing}
                  name={"args.frameSpacing"}
                  onChange={(e) => handleChange(e)}
                  label="Frame spacing"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={data.args.modulusOfElasticity}
                  name={"args.modulusOfElasticity"}
                  onChange={(e) => handleChange(e)}
                  label="Modulus of Elasticity"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">psi</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={data.args.bodyStation}
                  name={"args.bodyStation"}
                  onChange={(e) => handleChange(e)}
                  // error={(v) => !isBlank(v)}
                  label="Body station"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={data.args.pressureFactor}
                  name={"args.pressureFactor"}
                  onChange={(e) => handleChange(e)}
                  // error={(v) => !isBlank(v)}
                  label="Pressure factor"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={data.args.referenceWaterline}
                  name={"args.referenceWaterline"}
                  onChange={(e) => handleChange(e)}
                  label="Reference waterline"
                  variant="outlined"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">in</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default FuseBodyGeometry;
