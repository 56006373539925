import React from "react";
import ProjectList from "../components/Apps/Ring/projectList/ProjectList";
import { CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Boxbeam = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ProjectList navigate={navigate} />
    </div>
  );
};

export default Boxbeam;
