import theme from '../../theme';

const initialState = () => ({
    screenWidth: typeof window === 'object' ? window.innerWidth : null,
    open: window.innerWidth >= theme.breakpoints.values.sm
});

const SidebarReducer = (state = initialState(), action) => {
    switch(action.type) {
        case "TOGGLE_SIDEBAR":
            return {
                ...state,
                open: !state.open
            };
        default:
            return state
    }
};

export default SidebarReducer;