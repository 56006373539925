// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import { cfg } from "../config";
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';
import { useQuery } from "react-query";

const isSubscriptionActive = async () => {
  const session = await fetchAuthSession();

  return await axios.get(`${cfg.apiUrl}/org`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: session.tokens?.idToken,
    },
  });
};

const PrivateRoute = () => {
  const {
    data: sub,
    error,
    isLoading,
  } = useQuery("subscriptionActive", isSubscriptionActive);
  if (error) console.log(error);

  return isLoading ? (
    <div></div>
  ) : sub && sub.data.billing.active ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/company_profile",
        // state: { from: props.location },
      }}
    />
  );
};

export default PrivateRoute;
