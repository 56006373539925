import React from "react";
import { Card, CardContent, Typography, TextField, InputAdornment, makeStyles, Box, CardHeader, Grid, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import ASPECStyles from "../../../../../styles/jss/components/apps/ASPECStyles";
import ImportData from "../ImportData";

const useStyles = makeStyles(ASPECStyles);

const RingBodyGeometry = (props) => {
  const status = props.status === "SUCCESSFUL";
  const tableUnlocked = props.status !== "SUCCESSFUL";
  const { data, updateData } = props;
  const classes = useStyles();

  const getValue = (args, column) => {
    if (args) {
      return args[column];
    }
    return null;
  };

  const isFieldDisabled = () => {
    return !tableUnlocked || props.isRunning || props.isSaving
  }

  const getField = (args, column, type='number', label=column, unit='') => {
    const value = getValue(args, column)
    return (
      <TextField
        type={type}
        name={`args.${column}`}
        label={label}
        fullWidth
        value={value}
        disabled={isFieldDisabled()}
        error={value ? Boolean(value.error) : false}
        focused={value ? value.focus : false}
        variant="outlined"
        size="small"
        InputProps={ unit ? {
          endAdornment:
          <InputAdornment position="end">
            <Typography variant={'caption'} style={{ color: "rgba(0,0,0,0.54)" }}>{unit}</Typography>
          </InputAdornment>
        } : {}}
        onChange={(e) => handleChange(e)}
        style={{margin: '16px 0px 0px 0px'}}
      />
    );
  };

  const getCheckboxField = (args, column, section='', unit='') => {
    const value = getValue(args, column)
    console.log(value)
    return (
      <Checkbox
        color="primary"
        name={`args.${column}`}
        checked={value}
        onChange={e => handleChange(e)}
        // disabled={status}
      />
    );

  };

  const handleChange = (e) => {
    // e.preventDefault();
    const target = e.target;
    console.log(target.type)
    const name = e.target.name;
    // Get field value
    let value;
    switch (target.type) {
      case 'number':
        value = parseFloat(target.value);
        break;
      case 'checkbox':
        value = target.checked;
        break;
      default:
        value = target.value;
    }
    // Update data
    console.log("handleChange: ", value, name);
    updateData(value, name);
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title={"Options"}
        classes={{
          title: classes.cardHeaderTitle,
          root: classes.cardHeaderRoot,
        }}
        action={
          <div>
            <ImportData
              status={status}
              isRunning={props.isRunning}
              isSaving={props.isSaving}
              refresh={props.refresh}
              updateData={props.updateData}
              aircraft={props.aircraft}
              saveDraft={props.saveDraft}
            />
          </div>
        }
      />
      <CardContent>
        <div>
          <Grid container spacing={2}>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'loadedRing', 'text', 'Loaded ring')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'adjacentRing', 'text', 'Adjacent ring')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '16px 8px 0px 8px'}}>
              <FormGroup>
                <FormControlLabel
                    control={getCheckboxField(data.args, 'legacyMode')}
                    label="Legacy mode"
                    classes={{label: classes.selectLabels}}
                    disabled={isFieldDisabled()}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'shellRadius', 'number', 'Radius of shell')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'ringRadius', 'number', 'Ring radius (to neutral axis)')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'shellThickness', 'number', 'Thickness of shell')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'ringMomentInertia', 'number', 'Ring moment of inertia')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'equivalentShellThickness', 'text', 'Equivalent thickness of shell')}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'modulusElasticity', 'text', 'Modulus of elasticity')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'ringSpacing', 'text', 'Ring spacing')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'shearModulus', 'text', 'Shear modulus')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'structuralParamA', 'text', 'Structural parameter A')}
            </Grid>
            <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
              {getField(data.args, 'structuralParamB', 'text', 'Structural parameter B')}
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default RingBodyGeometry;
