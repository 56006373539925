const PasswordDialogStyles = theme => ({
    buttonContainer: {
        padding: '16px 24px'
    },
    dialogContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '95%',
            margin: '0'
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: '35%'
        },
    },
    titleContainer: {
        paddingBottom: 0
    },
    forgotPasswordText: {
        marginBottom: '20px',
    }
})

export default PasswordDialogStyles;