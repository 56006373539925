import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import * as _ from 'lodash';
import FlangeTable from "./FlangeTable";
import LoadcaseTable from "./LoadcaseTable";

// Icon Imports
import WarningIcon from "@material-ui/icons/Warning";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(ASPECStyles);

function isBlank(str) {
  return /^\s*$/.test(str); // test for blank string
}

const LoadcaseSection = (props) => {

  const classes = useStyles();
  const [itter, setItter] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [formData, setFormData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]); // to setlect and delete multiple rows
  const [error, setError] = React.useState([]);

  const tableUnlocked = props.status !== "SUCCESSFUL";

  // Update input variants when missionMix is changed.
  React.useEffect(  () => {
    setFormData(props.data);
    setLoading(false);
    setItter(i => i + 1);
    return function cleanup() {
      setFormData([]);
    }
  }, [props.data])

  const StyledGrid = withStyles((theme) => ({
    root: {
      '&&& .hideScrollbar::-webkit-scrollbar': {
        background: 'transparent' /* Chrome/Safari/Webkit */,
        width: 0,
      },
    },
  }))(Grid);

  const addRow = () => {
    props.addLoadcaseRow();
  }

  const deleteRows = () => {
    props.deleteLoadcases(selectedRows)
    setSelectedRows([]); // reset selected rows
  }

  const getDeleteButtonText = () => {
    const length = selectedRows.length;
    if (length === 1) {
      return 'Delete 1 Loadcase';
    } else if (length > 1) {
      return 'Delete ' + length + ' Loadcases';
    }
    return 'Delete Loadcase(s)';
  }

  return(
    <div>
      {props.missionMix === '' ? (
        <div>
          <Card>
            <CardContent className={classes.inputErrorCard}>
              <Typography className={classes.inputErrorCardContent}><WarningIcon color={'error'} /> Please select mission mix in the 'Predefined Data' section.</Typography>
            </CardContent>
          </Card>
        </div>
      ) : (
        <Card>
          <CardHeader
            // title={'Stringers'}
            classes={{
              title: classes.cardHeaderTitle,
              root: classes.cardHeaderRoot,
              content: classes.cardHeaderContent,
              action: classes.cardHeaderAction
            }}
          />
          <StyledGrid container>
            <Grid item md={2} >
              <Box
                  className={useStyles.container}
                  borderBottom="1px solid #9E9E9E"
                  height={48}
                >
                <Typography variant="h5" style={{fontSize: '20px', padding: '0 0 0 16px'}}>Loadcases</Typography>
              </Box>
            </Grid>
            <Grid item style={{flexGrow: "1"}}className={'root'} align="right">
              <Box
                className={useStyles.container}
                borderBottom="1px solid #9E9E9E"
                height={48}
              >
                <Button
                  disabled={!tableUnlocked || props.isRunning || props.isSaving}
                  color={'primary'}
                  startIcon={<AddIcon />}
                  onClick={addRow}
                >
                  Add Loadcase
                </Button>
                <Button
                  disabled={!tableUnlocked || props.isRunning || props.isSaving || selectedRows.length === 0}
                  color={'primary'}
                  startIcon={<DeleteIcon />}
                  onClick={deleteRows}
                >
                  {getDeleteButtonText()}
                </Button>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Box
                className={useStyles.container}
              >
                <LoadcaseTable
                  data={formData}
                  status={props.status}
                  update={props.update}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                />
              </Box>
            </Grid>
          </StyledGrid>

        </Card>
      )}
      {error.length > 0 && (
        <div className={classes.errorRoot}>
          <div className={classes.errorContainer}>
            <p className={classes.errorText}>{error[error.length - 1].message}</p>
          </div>
        </div>
      )}

    </div>
  )
};

export default LoadcaseSection;