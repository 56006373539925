import {
    drawerWidth,
} from "../../aero.js";

const SidebarStyles = theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.grey[900]
    },
    selected: {
        fontWeight: 'bold'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
        // Change this once you have theme grey colors
        backgroundColor: "#1d1d1d",
    },
    logo: {
        maxWidth: '150px'
    },
    icons: {
        color: 'white'
    },
    menuItems: {
        color: 'white',
        fontWeight: 400,

        '&:hover': {
            color: 'black',
            '& button span': {
                color: 'black'
            }
        }
    },
    nested: {
        paddingLeft: 50,
    },
    expandIcons: {
        color: 'white',
    },
    topContainer:{
      height: '100%',
    },
    container:{
      height: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
});

export default SidebarStyles;
