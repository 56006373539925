import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {TextField, Button, Box} from "@material-ui/core";

const useStyles = makeStyles({
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    searchBox: {
        minWidth: '40%'
    }
})
const MemberHeader = (props) => {

    const [search, setSearch] = React.useState();
    const handleChange = (e) => {
        setSearch(e.target.value);
        filterData(e.target.value);
    }

    const classes = useStyles();
    const {filterData} = props;
    return(
        <div>
            <Box className={classes.headerContainer}>
                <TextField
                    label={'Search Members'}
                    variant={'outlined'}
                    value={search}
                    className={classes.searchBox}
                    margin={'dense'}
                    onChange={(e) => handleChange(e)}
                />
                <Button variant={'contained'} color={'primary'}>+ Invite User</Button>
            </Box>

        </div>
    )
}

export default MemberHeader;