import { combineReducers } from "redux";
import SidebarReducer from './SidebarReducer';
import MobileSidebarReducer from "./MobileSidebarReducer";
import MissionMix from "./MissionMixReducer";
import SidebarItemsReducer from "./SidebarItemsReducer";


const rootReducers = combineReducers({
    sidebar: SidebarReducer,
    mobileSidebar: MobileSidebarReducer,
    missionMix: MissionMix,
    sidebarItems: SidebarItemsReducer
});

export default rootReducers;