// ##############################
// // // File to add helper functions
// // // Example: function that adds commas to large numbers
// #############################

import { signOut } from 'aws-amplify/auth';
import moment from "moment";

async function signOutFromApp(navigate) {
    try {
        signOut().then(resp => {
          console.log('Signed Out.')
        }).catch((err) => {
          console.error(err);
        })
        .finally(() => {
          console.log('Redirect to home page');
          navigate("/"); // redirect to home page
        });

    } catch (error) {
        console.log('error signing out: ', error);
    }
}

/**
 * Summary. Filter data based on filter object
 *
 * Description. Filters data object based on [key,value] pairs in filter object. Filter keys much match object keys.
 *
 * @param {Object} data The data you would like to filter through.
 * @param {Object} filters The filters you would like to apply to the data.
 * @return {Object} filteredData The filtered data.
 */
async function filterData(data, filters) {
    return data.filter(function (item) {
        for (let key in filters) {
            if (key.split(".").length > 1) {
                let pList = key.split('.');
                let value = pList.reduce((o, s) => {
                    if (typeof (o || {})[s] == "undefined") {
                        return null;
                    } else {
                        return o[s]
                    }
                }, item)
                return value === filters[key];
            } else {
                // Match runDate if the filter's runDate in undefined or equal to the object's runDate
                if (key === 'runDate' && (filters[key] == undefined || moment(item[key]).isSame(filters[key], 'day')))
                    return true;

                if (key === 'runDate' || item[key] === '' || item[key].toString().toLowerCase().indexOf(filters[key].toString().toLowerCase()) === -1)
                    return false;
            }
        }
        return true;
    });
}

function searchData(data, term){
    // Pass in filtered data
    //

    let sortedData = [];
    for (let i = 0; i < data.length; i++ ) {
        for (const [key] of Object.entries(data[i])) {
            if(data[i][key].toString().toLowerCase().indexOf(term.toLowerCase()) !== -1) {
                sortedData.push(data[i])
                break;
            }
        }
    }
    console.log(sortedData)
    return sortedData;
}

/**
 * Summary. Gets a list of available values in an object (used for filtering).
 *
 * @param data The Object you are trying to filter.
 * @param key The string notation of the object location you are trying to capture.
 * @returns {[]} Array of options.
 */
function getAvailableOptions(data, key) {
    let array = [];
    let pList = key.split('.');
    for(let i = 0; i < data.length; i++) {
        let current = pList.reduce((o,s)=> {
            if(typeof (o || {})[s] == "undefined") {
                return null;
            } else { return o[s] }
        }, data[i])
        if(!array.includes(current)) array.push(current)
    }
    return array;
}

/**
 * Summary. Sets a key value in a nested object using string location ('key1.key2.key3')
 *
 * @param {Object} obj The full object you would like to manipulate.
 * @param {Object} address The address of the key you would like to change.
 * @param {any} value The value to set the nested object key.
 * @return {Object} value The desired value of the final key.
 */
function setNestedValue(obj, address, value) {
    let schema = obj;
    let pList = address.split('.');
    let len = pList.length;
    for(let i = 0; i < len-1; i++) {
        let elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }
    schema[pList[len-1]] = value;
    if(pList[len-1] === 'missionMix') {
        setMissionMixInputs(value)
    }
    return obj;
}

/**
 * Summary. Checks if a nested key exists within an object.
 * @param obj {Object} The object you want to check.
 * @param key {string} The location of the key you are trying to access (use dot notation).
 * @param def {*} The value you would like it to return if key is not found in the object.
 * @returns {*}
 */
function checkForData(obj, key, def) {
    return key.split(".").reduce((object, path) => {
        return typeof (object || {})[path] == "undefined" || (object || {})[path] === null ? def : (object || {})[path];
    }, obj)
}

function setMissionMixInputs(mix) {
    console.log(mix)
}

function statusColor(status, theme) {
    switch(status.toUpperCase()) {
        case 'SUCCESSFUL':
            return theme.palette.success.main;
        case 'FAILURE':
            return theme.palette.error.main;
        case 'DRAFT':
            return theme.palette.grey['800'];
        default:
            return theme.palette.grey['800'];
    }
}

function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

// file upload
function getBase64(file, updateInputFileStateCallback) {
  var reader = new FileReader();
  reader.onload = function (e) {
    updateInputFileStateCallback(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
  reader.readAsDataURL(file);
}

export {
  signOutFromApp,
    filterData,
    searchData,
    getAvailableOptions,
    checkForData,
    setNestedValue,
    statusColor,
    downloadBlob,
    getBase64
}