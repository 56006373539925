import React from "react";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import {
  Grid,
  Divider,
  Box,
  Typography,
  Card,
  CardActions,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditCompanyProfile from "../components/companyProfile/CompanyProfileEdit";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { cfg } from "../config";
import { fetchAuthSession } from 'aws-amplify/auth';
import ContentLoading from "../components/common/ContentLoading";
import { signOutFromApp } from "../helpers";

const CompanyProfile = (props) => {
  const navigate = useNavigate();

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [deleteDialogOpened, setDeleteDialogOpened] = React.useState(false);
  const [deleteText, setDeleteText] = React.useState("");

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetchAuthSession()
      .then((session) => {
        axios
          .get(`${cfg.apiUrl}/org`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: session.tokens?.idToken,
            },
          })
          .then((resp) => {
            console.log(resp);
            setData(resp.data);
            setIsLoading(false);
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const manageBilling = () => {
    console.log(window.location.href);
    let data = { returnUrl: window.location.href }; // The return URL is the Company Profile page
    fetchAuthSession()
      .then((session) => {
        axios
          .post(
            `${cfg.apiUrl}/create-customer-portal-session`,
            {
              ...data,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: session.tokens?.idToken,
              },
            }
          )
          .then((resp) => {
            console.log(resp.data.sessionUrl);
            window.location = resp.data.sessionUrl;
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkout = (plan) => {
    console.log(window.location.href);
    let data = {
      plan,
      successUrl: window.location.href,
      cancelUrl: window.location.href,
    };
    fetchAuthSession()
      .then((session) => {
        axios
          .post(
            `${cfg.apiUrl}/create-checkout-session`,
            {
              ...data,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: session.tokens?.idToken,
              },
            }
          )
          .then((resp) => {
            console.log(resp.data.sessionUrl);
            window.location = resp.data.sessionUrl;
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteAccount = () => {
    setIsLoading(true);

    fetchAuthSession()
      .then((session) => {
        axios
          .delete(`${cfg.apiUrl}/org`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: session.tokens?.idToken,
            },
          })
          .then((resp) => {
            if (resp.status === 200) {
              setDeleteDialogOpened(false);
            } else {
              setDeleteDialogOpened(false);
              NotificationManager.error(
                "Something went wrong, please see console for details.",
                "ERROR"
              );
              console.log(resp);
            }

            // Sign out
            signOutFromApp(navigate); // TODO: FIX
          })
          .catch((e) => {
            console.log(e);
            if (e.response || e.request) {
              NotificationManager.error(
                "Something went wrong, please see console for details.",
                "ERROR"
              );
            } else {
              NotificationManager.error(
                "Something went wrong, please see console for details.",
                "ERROR"
              );
            }

            setIsLoading(false);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeDeleteText = (e) => {
    setDeleteText(e.target.value);
  };

  const cancelDialog = () => {
    setDeleteDialogOpened(false);
    setDeleteText(""); // reset delete text
  };

  return (
    <div>
      {isLoading ? (
        <div>
          <ContentLoading />
        </div>
      ) : (
        <>
          <Grid container spacing={2}>
            {data.billing.plan && (
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={6} alignItems="center">
                      <Grid item xs={11}>
                        <Box p={1}>
                          <Typography variant={"h6"}>Organization</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <b>Name</b>
                        </Typography>
                        <Typography variant="body1">{data.name}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          <b>Address</b>
                        </Typography>
                        <Typography variant="body1">
                          {data.address.line1} {data.address.line2}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1">
                          <b>City</b>
                        </Typography>
                        <Typography variant="body1">
                          {data.address.city}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1">
                          <b>State</b>
                        </Typography>
                        <Typography variant="body1">
                          {data.address.state}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1">
                          <b>Zip Code</b>
                        </Typography>
                        <Typography variant="body1">
                          {data.address.postal_code}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant="body1">
                          <b>Phone Number</b>
                        </Typography>
                        <Typography variant="body1">{data.phone}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {!data.billing.active && (
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={6} alignItems="center">
                      <Grid item xs={11}>
                        <Box p={1}>
                          <Typography variant={"h6"}>Choose a plan</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Basic
                            </Typography>
                            <Typography variant="h5">$500 / year</Typography>
                            <Typography sx={{ mb: 1.5 }}>
                              includes ...
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              color="secondary"
                              size="small"
                              onClick={() => checkout("basic")}
                            >
                              Select
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item md={6}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Premium
                            </Typography>
                            <Typography variant="h5">$1000 / year</Typography>
                            <Typography sx={{ mb: 1.5 }}>
                              includes ...
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              color="secondary"
                              size="small"
                              onClick={() => checkout("premium")}
                            >
                              Select
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {data.billing.plan && (
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={6} alignItems="center">
                      <Grid item xs={11}>
                        <Box p={1}>
                          <Typography variant={"h6"}>Subscription</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        <CircleIcon
                          style={{
                            color: data.billing.active ? "green" : "FireBrick",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={5} style={{ paddingBottom: "20px"}}>
                      <Grid item md={1}>
                        <Typography variant="body1">
                          <b>Status</b>
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography variant="body1">
                          {data.billing.status}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={5} style={{ paddingBottom: "20px"}}>
                      <Grid item md={1}>
                        <Typography variant="body1">
                          <b>Plan</b>
                        </Typography>
                      </Grid>
                      <Grid item md={1}>
                        <Typography variant="body1">
                          {data.billing.plan}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                      <Grid item md={2}>
                        <Button
                          color="secondary"
                          size="small"
                          onClick={() => manageBilling()}
                        >
                          Manage subscription
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={6} alignItems="center">
                    <Grid item xs={12}>
                      <Box p={1}>
                        <Typography variant={"h6"}>
                          Frequently asked questions
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item md={2}>
                      <Grid container spacing={4}>
                        <Grid item md={12}>
                          <Typography variant="body1">
                            Have another question? Looking for more detail?
                            Contact us by email!
                          </Typography>
                        </Grid>
                        <Grid item md={12}>
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            target="_top"
                            rel="noopener noreferrer"
                            href={`mailto:test@example.com`}
                          >
                            <Typography
                              variant="button"
                              style={{ fontSize: "0.69rem" }}
                            >
                              Contact us
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={10}>
                      <div>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Question 1?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Suspendisse malesuada lacus ex, sit amet
                              blandit leo lobortis eget.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Typography>Question 2?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Suspendisse malesuada lacus ex, sit amet
                              blandit leo lobortis eget.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                          >
                            <Typography>Question 3?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Suspendisse malesuada lacus ex, sit amet
                              blandit leo lobortis eget.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={12}>
              <Button
                color="secondary"
                size="small"
                onClick={() => setDeleteDialogOpened(true)}
              >
                Delete account
              </Button>
            </Grid>
          </Grid>
          <Dialog
            open={deleteDialogOpened}
            onClose={() => setDeleteDialogOpened(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Delete the organization?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                This will delete the organization. This action cannot be undone.
              </DialogContentText>
              <FormControl fullWidth>
                <TextField
                  type={"text"}
                  name={"delete"}
                  fullWidth
                  label={"To confirm deletion, type permanently delete"}
                  value={deleteText}
                  onChange={(e) => changeDeleteText(e)}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button disabled={isLoading} onClick={() => cancelDialog()}>
                Cancel
              </Button>
              <Button
                disabled={isLoading || deleteText !== "permanently delete"}
                onClick={() => deleteAccount()}
                color="primary"
                variant={"contained"}
              >
                Delete Organization
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};
export default CompanyProfile;
