import React from 'react';
import {IconButton, Menu, MenuItem, List, ListItem, Chip, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Typography} from '@material-ui/core';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    mainListItem: {
        width: '100%'
    },
    chipMargin: {
        marginRight: '10px'
    }
})
const MemberList = (props) => {
    const [anchorEl, setAnchorEl] = React.useState();
    const openMenu = (e) => setAnchorEl(e.currentTarget)
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const {members} = props;
    const classes = useStyles();

    const chipColor = (item) => {
        switch(item) {
            case 'Admin':
                return 'primary'
            case 'Billing':
                return 'secondary'
            default:
                return 'default'
        }
    }
    return (
        <List dense>
            {members.map((member, idx) => {
                const labelId = `checkbox-list-secondary-label-1`;
                return (
                    <ListItem key={idx} button>
                        <ListItemAvatar>
                            <Avatar>M</Avatar>
                        </ListItemAvatar>
                        <ListItemText classes={{multiline: classes.mainListItem}} id={labelId} primary={member.first_name + ' ' + member.last_name} secondary={member.email} />
                        <ListItem>
                            {member.type.map((item, idx) => (
                                <Chip size="small" color={chipColor(item)} label={item} key={idx} className={classes.chipMargin} />
                            ))}
                        </ListItem>
                        <ListItemSecondaryAction>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={openMenu}
                                size="large">
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={closeMenu}
                            >
                                <MenuItem onClick={closeMenu}>
                                    Edit Member
                                </MenuItem>
                                <MenuItem onClick={closeMenu}>
                                    <Typography color={'error'}>Delete Member</Typography>
                                </MenuItem>
                            </Menu>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    );
}

export default MemberList;