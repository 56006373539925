const ASPECStyles = theme => ({

    rowContainer: {
        paddingBottom: '10px'
    },
    // ASPEC header styles
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    headerTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        overflow: 'hidden'
    },
    headerTitleOverflow: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    headerBackIcon: {
        marginRight: theme.spacing(1),
        '&:hover': {
            cursor: 'pointer'
        }
    },
    headerRightContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    headerAnalysisTitle: {
        width: '100%'
    },

    cardHeaderTitle: {
        fontSize: '20px',
    },

    cardHeaderRoot: {
        padding: '16px 16px 0 16px',
        alignItems: 'flex-start'
    },

    cardBody: {
        padding: '0, 16px, 16px, 16px'
    },

    // ASPEC Left Menu styles
    cardContainer: {
        paddingTop: 0,
    },
    predefinedFormControl: {
        marginBottom: '20px'
    },
    accordionContainer: {
        marginTop: '16px',
        borderTop: '1px solid ' + theme.palette.grey['300']

    },
    selectWithIcon: {
        display: 'flex',
        alignItems: 'center'
    },
    selectLabels: {
        fontSize: '14px'
    },

    // ASPEC Input Content
    inputErrorCard: {
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputErrorCardContent: {
        display: 'flex',
        alignItems: 'center',
    },
    errorRoot: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 10000,
        height: 30,
        width: '100vw',
        backgroundColor: theme.palette.warning.main,
        color: 'white',
    },
    errorContainer: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    errorText: {
        padding: 0,
        fontWeight: 700
    },
    enabledTextField: {
        maxWidth: 210
    },
    disabledTextField: {
        maxWidth: 210,
        backgroundColor: '#dedede',
        content: "--"
    },
    endAdornment: {
      padding: "0px"
    },
    // input: {
    //     "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    //         "-webkit-appearance": "inner-spin-button !important",
    //         display: 'none'
    //     },
    //     "&::before": {
    //         content: '--'
    //     }
    // },
    disabledInput: {
        content: "--"
    },
    modeledDataRow: {
        '&td': {
            padding: 40
        }
    },
    cardHeaderContent: {
        flex: '0 1 auto'
    },
    cardHeaderAction: {
        flex: '1 1 auto',
    },
    cardHeaderActionContent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: 24,
        alignItems: 'center'
    },

    // FLIGHT SEMGMENT DEFINITION

    FSDImage: {
        width: '100%'
    }
});

export default ASPECStyles;