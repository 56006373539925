import React from 'react';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    IconButton,
    Menu,
    MenuItem, TextField,
    Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {makeStyles} from "@material-ui/core/styles";
import {cfg} from "../../../../config";
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import {NotificationManager} from "react-notifications";
import ProjectStyles from "../../../../styles/jss/components/apps/ProjectStyles";
import withRouter from '../../../../helpers/withRouter';
import { useNavigate, useParams  } from "react-router-dom";

const useStyles = makeStyles(ProjectStyles)

const AnalysisOptions = (props) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    // const missionMix = props.data.args.missionMix;
    const projectCopied = window.sessionStorage.getItem("copyProject");
    const {data, status, paste} = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

    // Duplicate Project dialog logic
    const [duplicateName, setDuplicateName] = React.useState(data.name + ' [copy]');
    const changeDuplicateName = (e) => {
        let value = e.target.value;
        if(value.length > 30) {
            NotificationManager.warning('Cannot have a name over 30 characters', 'WARNING', 2000)
        }
        if(value.length === 0) {
            NotificationManager.warning('Cannot have a whitespace name.', 'WARNING')
        }
        setDuplicateName(value.slice(0,30))
    };

    const [duplicateDialog, setDuplicateDialog] = React.useState(false);
    const openDuplicateDialog = () => { setDuplicateDialog(true); setAnchorEl(null); };
    const closeDuplicateDialog = () => setDuplicateDialog(false);

    const sendDuplicate = () => {
        setLoading(true);
        fetchAuthSession()
            .then(session => {
                axios.post(`${cfg.apiUrl}/app/wingspan/analysis/duplicate`, {
                    "name": duplicateName,
                    "id": params.id,
                    "projectId": params.projectId
                }, {
                    headers: {
                        "Authorization": session.tokens?.idToken,
                        "Content-Type": "application/json"
                    }
                })
                    .then(resp => {
                        setLoading(false);
                        if(resp.status === 200) {
                            closeDuplicateDialog();
                            navigate('/apps/wingspan/project/' + params.projectId);
                            console.log('Duplicated ' + data.name + ' with name ' + duplicateName);
                        } else {
                            setLoading(false)
                            console.log('Something went wrong please try again.')
                        }
                    })
                    .catch(e => {
                        setLoading(false)
                        console.log(e)
                        if (e.response || e.request) {
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                        } else {
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                        }
                    })
            })


    }

    // Delete dialog logic
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const openDeleteDialog = () => { setDeleteDialog(true); setAnchorEl(null); }
    const closeDeleteDialog = () => setDeleteDialog(false);

    const [loading, setLoading] = React.useState(false);

    const sendDelete = () => {
        // Axios DELETE request API logic
        setLoading(true);
        fetchAuthSession()
            .then(session => {
                axios.delete(`${cfg.apiUrl}/app/wingspan/analysis/${params.projectId}/${params.id}`, {
                    headers: {
                        "Authorization": session.tokens?.idToken,
                        "Content-Type": "application/json"
                    }
                })
                    .then(resp => {
                        setLoading(false);
                        if(resp.status === 200) {
                            closeDeleteDialog();
                            navigate('/apps/wingspan/project/' + params.projectId);
                            console.log('Deleted '+ data.name);
                        } else {
                            closeDeleteDialog();
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                            console.log(resp)
                        }
                    })
                    .catch(e => {
                        setLoading(false);
                        console.log(e)
                        if (e.response || e.request) {
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                        } else {
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                        }
                    })
            })

    }

    const ITEM_HEIGHT = 48;

    const deleteDialogSection = () => (
        <Dialog open={deleteDialog} onClose={closeDeleteDialog}  aria-labelledby="form-dialog-title" classes={{paper: classes.dialogContainer}}>
            <DialogTitle id="form-dialog-title">Delete the "{data.name}" analysis?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This will delete the analysis. This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions classes={{root: classes.buttonContainer}}>
                <Button disabled={loading} onClick={() => closeDeleteDialog()}>
                    Cancel
                </Button>
                <Button disabled={loading} onClick={() => sendDelete()} color="primary" variant={'contained'}>
                    Delete Analysis
                </Button>
            </DialogActions>
        </Dialog>
    )

    const duplicateDialogSection = () => (
        <Dialog open={duplicateDialog} onClose={closeDuplicateDialog}  aria-labelledby="form-dialog-title" classes={{paper: classes.dialogContainer}}>
            <DialogTitle id="form-dialog-title">Duplicate Analysis</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter a name for the duplicate analysis. The name must be unique.
                </DialogContentText>
            </DialogContent>
            <DialogContent>
                <FormControl fullWidth>
                    <TextField
                        type={'text'}
                        autoFocus
                        name={'name'}
                        fullWidth
                        label={'New Name'}
                        value={duplicateName}
                        onChange={(e) => changeDuplicateName(e)}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions classes={{root: classes.buttonContainer}}>
                <Button disabled={loading} onClick={() => closeDuplicateDialog()}>
                    Cancel
                </Button>
                <Button disabled={loading} onClick={() => sendDuplicate()} color="primary" variant={'contained'}>
                    Duplicate Analysis
                </Button>
            </DialogActions>
        </Dialog>
    )

    const copyProject = () => {
        window.sessionStorage.setItem("copyProject", JSON.stringify(data));
        NotificationManager.success('Project has been copied.', 'SUCCESS')
        console.log('copy project')
        handleClose()
    }

    return (
        <div>
            {deleteDialogSection()}
            {duplicateDialogSection()}
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >

                <MenuItem onClick={() => copyProject()}>
                    Copy Analysis
                </MenuItem>

                {(projectCopied && !status) && (
                    <MenuItem onClick={() => {
                        paste()
                        handleClose()
                    }}>
                        Paste Analysis
                    </MenuItem>
                )}
                <MenuItem onClick={openDuplicateDialog}>
                    Duplicate
                </MenuItem>
                <MenuItem onClick={openDeleteDialog}>
                    <Typography color={'error'}>Delete</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default withRouter(AnalysisOptions);