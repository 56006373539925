import React from 'react';

const styles = {
    display: 'flex',
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '20px',
    backgroundColor: 'orange',
    justifyContent: 'center',
    zIndex: '10000'
}

const APIStatus = () => {
    return (
        <div style={styles}>
            Lost connection to API, please check internet & reload page.
        </div>
    )
};

export default APIStatus;