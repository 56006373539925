import React from 'react';
import {
  Button,
  Drawer,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { downloadData } from 'aws-amplify/storage';
import {downloadBlob} from "../../../../helpers";

const useStyles = makeStyles({
  drawer: {
    minWidth: '400px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15
  },
  content: {
    padding: 15
  },
  tableContainer: {
    margin: 15
  }
})

const Reference = props => {
  const [open, setOpen] = React.useState(false);
  const [aircraft, setAircraft] = React.useState(props.refs[0].type)
  const classes = useStyles();

  const handleChange = e => {
    setAircraft(e.target.value)
  }

  const downloadItem = () => {
    let key = ''
    for(let i =0; i < props.refs.length; i++) {
      if(props.refs[i].type === aircraft) {
        key = `public/${props.refs[i].path}`;
      }
    }
    downloadData({ key: key }).result
      .then(resp => {
        downloadBlob(resp.body, `${aircraft}_reference.pdf`)
      })
      .catch(e => {console.log(e)})

  }

  return <>
    <Drawer classes={{paper: classes.drawer}} anchor={'right'} open={open} onClose={() => setOpen(false)}>
      <div className={classes.header}>
        <Typography variant={'h6'}>Aircraft Type Reference</Typography>
        <IconButton onClick={() => setOpen(false)} size="large">
          <ClearIcon />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.content}>
        <FormControl fullWidth>
          <InputLabel id="aircraft-select-label">Aircraft</InputLabel>
          <Select
            labelId="aircraft-select-label"
            id="aircraft-select"
            value={aircraft}
            onChange={e => handleChange(e)}
          >
            {props.refs.map((ref, idx) => (
              <MenuItem key={idx} value={ref.type}>{ref.type}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.tableContainer}>
        <TableContainer component={Paper}>
          <Table className={classes.table} size={'small'} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Aircraft Type</TableCell>
                <TableCell>Reference Document</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{aircraft}</TableCell>
                <TableCell>
                  <Button size={'small'} variant={'text'} color={'primary'} disableElevation onClick={downloadItem}>
                    Download Document
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Drawer>
  </>;
}

export default Reference;