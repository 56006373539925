import React from 'react';
import {
    Button, Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControl,
    IconButton,
    Menu,
    MenuItem, TextField,
    Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {makeStyles} from "@material-ui/core/styles";
import ProjectListStyles from "../../../../styles/jss/components/apps/ProjectListStyles";
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from "axios";
import {cfg} from "../../../../config";
import {NotificationManager} from 'react-notifications';

const useStyles = makeStyles(ProjectListStyles)

const ProjectListOptions = (props) => {

    const {updateData} = props;

    const [Loading, setLoading] = React.useState(false);

    // Dropdown open/close logic
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };

    // New name dialog and input logic
    const [newName, setNewName] = React.useState('');
    const changeName = (e) => {
        if(error) setError(null);
        setNewName(e.target.value)
    };
    const [error, setError] = React.useState(null);

    const [renameDialog, setRenameDialog] = React.useState(false);
    const openRenameDialog = () => { setRenameDialog(true); setAnchorEl(null); };
    const closeRenameDialog = () => setRenameDialog(false);

    // Change name API logic
    const sendRename = (e) => {
        e.preventDefault();
        setLoading(true);
        fetchAuthSession()
            .then(session => {
                axios.patch(`${cfg.apiUrl}/app/wingspan/project/${props.row.id}`, {
                    name: newName
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': session.tokens?.idToken
                    }
                })
                    .then(resp => {
                        if(resp.status === 200) {
                            closeRenameDialog();
                            setLoading(false);
                            updateData();
                        }
                    })
                    .catch(err => {
                        if (err.response) {
                            setLoading(false);
                            if(err.response.status === 400) {
                                NotificationManager.error(err.response.data.error.message, 'Error')
                            } else {
                                NotificationManager.error("An error has occurred, please try again.", 'Error')
                            }
                        } else if (err.request) {
                            setLoading(false);
                            NotificationManager.error("An error has occurred, please try again.", 'Error')
                        } else {
                            setLoading(false);
                            NotificationManager.error("An error has occurred, please try again.", 'Error')
                        }
                    })
            })
    }

    // Delete dialog logic
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const openDeleteDialog = () => { setDeleteDialog(true); setAnchorEl(null); }
    const closeDeleteDialog = () => setDeleteDialog(false);

    const sendDelete = (e) => {
        e.preventDefault();
        setLoading(true);
        fetchAuthSession()
            .then(session => {
                axios.delete(`${cfg.apiUrl}/app/wingspan/project/${props.row.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': session.tokens?.idToken
                    }
                })
                    .then(resp => {
                        if(resp.status === 200) {
                            closeDeleteDialog();
                            setLoading(false);
                            updateData();
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response || err.request) {
                            NotificationManager.error("An error has occurred, please try again.", 'Error')
                        } else {
                            NotificationManager.error("An error has occurred, please try again.", 'Error')
                        }
                    })
            })

    }

    const ITEM_HEIGHT = 48;
    const classes = useStyles();

    return (
        <div>
            <Dialog open={deleteDialog} onClose={closeDeleteDialog}  aria-labelledby="form-dialog-title" classes={{paper: classes.dialogContainer}}>
                <DialogTitle id="form-dialog-title">Delete the "{props.row.name}" project?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will delete the project. This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions classes={{root: classes.buttonContainer}}>
                    <Button disabled={Loading} onClick={() => closeDeleteDialog()}>
                        Cancel
                    </Button>
                    <Button disabled={Loading} onClick={(e) => sendDelete(e)} color="primary" variant={'contained'}>
                        Delete Project
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={renameDialog} onClose={closeRenameDialog}  aria-labelledby="form-dialog-title" classes={{paper: classes.dialogContainer}}>
                <DialogTitle id="form-dialog-title">
                    Rename
                </DialogTitle>
                <DialogContent>
                    {error && (<Typography variant={'body2'} color={'error'}>{error}</Typography>)}
                    <FormControl fullWidth>
                        <TextField
                            type={'text'}
                            autoFocus
                            name={'name'}
                            fullWidth
                            label={'New Name'}
                            value={newName}
                            onChange={(e) => changeName(e)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions classes={{root: classes.buttonContainer}}>
                    <Button disabled={Loading} onClick={() => closeRenameDialog()}>
                        Cancel
                    </Button>
                    <Button disabled={Loading} onClick={(e) => sendRename(e)} color="primary" variant={'contained'}>
                        Rename Project
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={openRenameDialog}>
                    Rename
                </MenuItem>
                <MenuItem onClick={openDeleteDialog}>
                    <Typography color={'error'}>Delete</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default ProjectListOptions;