import React from 'react';
import { useParams } from 'react-router-dom';
import withRouter from '../../../../helpers/withRouter';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  makeStyles
} from "@material-ui/core";
import {Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent} from "@material-ui/lab";
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import {cfg} from "../../../../config";
import { getBase64 } from '../../../../helpers';

import GetAppIcon from '@material-ui/icons/GetApp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PublishIcon from '@material-ui/icons/Publish';
import LoadingButton from '@mui/lab/LoadingButton';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { DropzoneArea } from 'material-ui-dropzone';
import { NotificationManager } from 'react-notifications';

const useStyles = makeStyles({
  timeline_item: {
    '&:before': {
      flex: 0,
      padding: 0,
    }
  },
  content1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0'
  },
  dropzoneContainer: {
    border: 'dashed 1px lightgray',
    borderRadius: '5px',
    width: '100%',
    marginTop: 20
  },
  dropzoneInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    position: 'relative'
  },
  dropzone: {
    position:"absolute",
    outline:"none !important",
    width:"100%",
    height:"100%",
    cursor:"pointer",
    opacity:"0",
    marginTop: -20
  },
  dropzonearea: {
    minHeight: '0px', // reduce component height
    '& div': {
      '& p': {
        fontSize: "16px",
      }
    }
  },
  dragging: {
    border: 'solid 1px red'
  }
})

const ImportData = props => {
  const params = useParams();

  const {aircraft} = props;
  const [open, setOpen] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const handleClose = () => {
    setUploadFile(null);
    setOpen(false);
  }

  const classes = useStyles();

  const theme = createTheme({
    overrides: {
      MuiDropzoneArea: {
        root: {
          minHeight: '', // reduce component height
        },
        text: {
          fontSize: '16px',
        },
      }
    }
  });

  const download = async () => {
    await props.saveDraft(true);

    // console.log('... sleeping for 1 second')
    // await sleep(1000); // temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)

    fetchAuthSession()
      .then(session => {
        setIsDownloading(true);
        axios.post(`${cfg.apiUrl}/app/ring/template`, {
          "id": params.id,
          "projectId": params.projectId
        }, {
          headers: {
            "Authorization": session.tokens?.idToken,
            "Content-Type": "application/json"
          }
        })
          .then(resp => {
            if(resp.status === 200) {
              setIsDownloading(false);
              let url = resp.data.url.split('?')[0]
              window.open(resp.data.url, '_blank')
              props.updateData(url, 'args.template')
            }
          })
          .catch(e => {
            console.log(e);
            setIsDownloading(false);
          })
      })
  }
  const submit = async () => {
    fetchAuthSession()
      .then(session => {
        setIsUploading(true);
        axios.patch(`${cfg.apiUrl}/app/ring/template`, {
          "id": params.id,
          "projectId": params.projectId,
          "file": removeBase64Header(uploadFile)
        }, {
          headers: {
            "Authorization": session.tokens?.idToken,
            "Content-Type": "application/json"
          }
        })
          .then(async resp => {
            console.log(resp)
            if (resp.status === 200) {
              await props.refresh();
              const message = resp.data.message
              NotificationManager.success(`Data imported succesfully: ${message}`, 'SUCCESS', 10000);
              setIsUploading(false);
              // Close form
              setOpen(false);
            } else {
              NotificationManager.warning(
                'Something went wrong, please check console for response.',
                'WARNING'
              );
            }
          })
          .catch((err) => {
            console.log(err)
            setIsUploading(false);
            if (err.response) {
              if (err.response.status === 400) {
                NotificationManager.error(`The import failed: ${err.response.data}`, 'Error', 10000);
                return
              }
            }
            NotificationManager.error('An error has occurred, please try again.', 'Error');
          });
      })
  }

  const shortHandFile = () => {
    return 'template'
  }

  const handleFileChange = async (files) => {
    // Reset inputFile if the input file is removed from the UI
    if (files.length === 0) {
      setUploadFile(null); // reset inputFile
      return;
    }

    // Get the input file as base64 and update the state with the callback
    getBase64(files[0], setUploadFile); // prints the base64 string
  };

  const removeBase64Header = file => {
    return file.split('base64,')[1]
  };


  return(
    <>
      <Button
        disabled={props.status || props.isRunning || props.isSaving}
        onClick={() => setOpen(i => !i)}
        style={{marginRight: 20}}
        size={'small'}
        color={'primary'}>
        Import data (.xslx)
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="import-analysis-dialog">Import Analysis Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please follow the steps below and upload the .xlsx file filled with data in order to import analysis data
          </DialogContentText>
          <Timeline align={'left'}>
            <TimelineItem classes={{root: classes.timeline_item}}>
              <TimelineSeparator>
                <TimelineDot>
                  <GetAppIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Download .xlsx template if needed</Typography>
                <div className={classes.content1}>
                  <div>
                    <LoadingButton
                      color="primary"
                      disabled={isDownloading || isUploading}
                      onClick={download}
                      loading={isDownloading}
                      loadingPosition="start"
                      startIcon={<CloudDownloadIcon />}
                    >
                      {shortHandFile()}.xlsx
                    </LoadingButton>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem classes={{root: classes.timeline_item}}>
              <TimelineSeparator>
                <TimelineDot>
                  <PublishIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Upload .xlsx template with data</Typography>
                  <MuiThemeProvider theme={theme}>
                    <DropzoneArea
                      dropzoneText={'Drag & Drop or click to upload a .xlsx file'}
                      onChange={(e) => handleFileChange(e)}
                      acceptedFiles={['.xlsx']}
                      filesLimit={1}
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewText="Selected file"
                    />
                  </MuiThemeProvider>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </DialogContent>
        <DialogActions>
          <Button disabled={isUploading} onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            disabled={isUploading || !Boolean(uploadFile)}
            onClick={submit}
            loading={isUploading}
            loadingPosition="start"
            startIcon={<CloudUploadIcon />}
          >
            Upload File
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

// Temporary workaround for eventual consistent read issue (latest data not always fetched)
function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export default withRouter(ImportData);