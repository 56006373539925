import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment
} from "@material-ui/core";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import * as _ from 'lodash';
import StationsTable from "./StationsTable";
import SegmentsTable from "./SegmentsTable";
import LoadcasesTable from "./LoadcasesTable";

// Icon Imports
import WarningIcon from "@material-ui/icons/Warning";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { pink } from '@mui/material/colors';

const useStyles = makeStyles(ASPECStyles);

function isBlank(str) {
  return /^\s*$/.test(str); // test for blank string
}

const StationSection = (props) => {

  const classes = useStyles();
  const [itter, setItter] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [formData, setFormData] = React.useState([]);
  const [selectedLoadcases, setSelectedLoadcases] = React.useState([]); // to setlect and delete multiple rows
  const [selectedSegments, setSelectedSegments] = React.useState([]); // to setlect and delete multiple rows
  const [error, setError] = React.useState([]);

  const tableUnlocked = props.status !== "SUCCESSFUL";

  // Update input variants when missionMix is changed.
  React.useEffect(  () => {
    setFormData(props.data);
    setLoading(false);
    setItter(i => i + 1);
    return function cleanup() {
      setFormData([]);
    }
  }, [props.data])

  const StyledGrid = withStyles((theme) => ({
    root: {
      '&&& .hideScrollbar::-webkit-scrollbar': {
        background: 'transparent' /* Chrome/Safari/Webkit */,
        width: 0,
      },
    },
  }))(Grid);

  const isFieldDisabled = () => {
    return !tableUnlocked || props.isRunning || props.isSaving
  }

  const addSegmentRow = () => {
    props.addSegment();
  }

  const deleteSegmentRows = () => {
    props.deleteSegments(selectedSegments)
    setSelectedSegments([]); // reset selected rows
  }

  const addLoadcaseRow = () => {
    props.addLoadcase();
  }

  const deleteLoadcaseRows = () => {
    props.deleteLoadcases(selectedLoadcases)
    setSelectedLoadcases([]); // reset selected rows
  }

  const getSegmentDeleteButtonText = () => {
    const length = selectedSegments.length;
    if (length === 1) {
      return 'Delete 1 Segment';
    } else if (length > 1) {
      return 'Delete ' + length + ' Segments';
    }
    return 'Delete Segment(s)';
  }

  const getLoadcaseDeleteButtonText = () => {
    const length = selectedLoadcases.length;
    if (length === 1) {
      return 'Delete 1 Loadcase';
    } else if (length > 1) {
      return 'Delete ' + length + ' Loadcases';
    }
    return 'Delete Loadcase(s)';
  }

  const getValue = (station, column, section='') => {
    // if (section === 'inboard') {
    //   return station.inboard[column];
    // } else if (section === 'outboard') {
    //   return station.outboard[column];
    // } else {
    if (station) {
      return station[column];
    }
    return null;
  };

  const getField = (station, column, cbIdx, label=column, section='', unit='') => {
    const value = getValue(station, column, section);
    console.log(cbIdx, column, section)
    return (
      <TextField
        name={column}
        label={label}
        fullWidth
        defaultValue={value}
        disabled={isFieldDisabled()}
        // value={value}
        error={value ? Boolean(value.error) : false}
        focused={value ? value.focus : false}
        type={'number'}
        variant="outlined"
        size="small"
        InputProps={ unit ? {
          endAdornment:
          <InputAdornment position="end">
            <Typography variant={'caption'} style={{ color: "rgba(0,0,0,0.54)" }}>{unit}</Typography>
          </InputAdornment>
        } : {}}
        onBlur={(e) => handleTextfieldChange(e, cbIdx, column, section, props)}
        // onChange={(e) => handleTextfieldChange(e, cbIdx, column, section, props)}
        style={{margin: '16px 0px 0px 0px'}}
      />
    );
  };

  const getCheckboxField = (station, column, cbIdx, section='', unit='') => {
    // console.log(station)
    const value = getValue(station, column, section)
    // console.log(value)
    return (
      <Checkbox
        color="primary"
        name={'args.calculate.shearCenter'}
        checked={value}
        onChange={e => handleTextfieldChange(e, cbIdx, column, section, props)}
        // disabled={status}
      />
    );

  };

  const handleTextfieldChange = async (e, cbIdx, column, section, props) => {
    // console.log(column + "-" + e.target.value)
    // if (column === "index") {
    //   const newId = parseFloat(e.target.value);
    //   console.log(props.stationIdAlreadyTaken(newId))
    //   if (props.stationIdAlreadyTaken(newId)) {
    //     e.preventDefault();
    //     e.target.reset();
    //     return; // Skip update if station ID already taken
    //   }
    // }
    // console.log(`handleTextfieldChange: data.stations[${cbIdx}].${column}`)
    if (section !== '') {
      props.update(parseFloat(e.target.value), `data.stations[${cbIdx}].${section}.${column}`);
    } else {
      if (e.target.type === "checkbox") {
        props.update(e.target.checked, `data.stations[${cbIdx}].${column}`);
      } else {
        props.update(parseFloat(e.target.value), `data.stations[${cbIdx}].${column}`);
      }
    }

  };

  return(
    <div style={{height: "100%"}}>
        <Card style={{height: "100%", minHeight: "600px"}}>
          <CardHeader
            // title={'Stringers'}
            classes={{
              title: classes.cardHeaderTitle,
              root: classes.cardHeaderRoot,
              content: classes.cardHeaderContent,
              action: classes.cardHeaderAction
            }}
          />
          {props.selectedStation ? (
            <StyledGrid container>
              <Grid item md={12} >
                <Box
                    className={useStyles.container}
                    borderBottom="1px solid #9E9E9E"
                    height={48}
                  >
                  <Typography variant="h5" style={{fontSize: '18px', padding: '5px 0 0 16px'}}>Station</Typography>
                </Box>
              </Grid>
              <Grid container spacing={2} style={{fontSize: '20px', padding: '10px 16px 16px 16px'}}>
                <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
                  {getField(props.selectedStation, 'id', props.stationIdx, 'ID')}
                </Grid>
                {/* <Grid item lg={2} md={3} sm={6} style={{padding: '8px 8px'}}>
                  {getField(props.selectedStation, 'appliedLoadPointCount', props.stationIdx, 'Number of load points for applied loads')}
                </Grid> */}
              </Grid>
              <Grid item md={2}>
                <Box
                    className={useStyles.container}
                    borderBottom="1px solid #9E9E9E"
                    height={48}
                  >
                  <Typography variant="h4" style={{fontSize: '16px', padding: '8px 0 0 16px'}}>Segments</Typography>
                </Box>
              </Grid>
              <Grid item style={{flexGrow: "1"}}className={'root'} align="right">
                <Box
                  className={useStyles.container}
                  borderBottom="1px solid #9E9E9E"
                  height={48}
                >
                  <Button
                    disabled={isFieldDisabled()}
                    color={'primary'}
                    startIcon={<AddIcon />}
                    onClick={addSegmentRow}
                  >
                    Add Segment
                  </Button>
                  <Button
                    disabled={isFieldDisabled() || selectedSegments.length === 0}
                    color={'primary'}
                    startIcon={<DeleteIcon />}
                    onClick={deleteSegmentRows}
                  >
                    {getSegmentDeleteButtonText()}
                  </Button>
                </Box>
              </Grid>
              <Grid item md={12} style={{margin: '0px 0px 15px 0px'}}>
                <Box
                  className={useStyles.container}
                >
                  <SegmentsTable
                    data={formData}
                    analysis={props.analysis}
                    status={props.status}
                    update={props.update}
                    selectedLoadcases={selectedLoadcases}
                    selectedSegments={selectedSegments}
                    setSelectedSegments={setSelectedSegments}
                    setSelectedLoadcases={setSelectedLoadcases}
                    selectedStation={props.selectedStation}
                    stationIdx={props.stationIdx}
                  />
                </Box>
              </Grid>


              <Grid item md={2}>
                <Box
                    className={useStyles.container}
                    borderBottom="1px solid #9E9E9E"
                    height={48}
                  >
                  <Typography variant="h4" style={{fontSize: '16px', padding: '8px 0 0 16px'}}>Loadcases</Typography>
                </Box>
              </Grid>
              <Grid item style={{flexGrow: "1"}}className={'root'} align="right">
                <Box
                  className={useStyles.container}
                  borderBottom="1px solid #9E9E9E"
                  height={48}
                >
                  <Button
                    disabled={isFieldDisabled()}
                    color={'primary'}
                    startIcon={<AddIcon />}
                    onClick={addLoadcaseRow}
                  >
                    Add Loadcase
                  </Button>
                  <Button
                    disabled={isFieldDisabled() || selectedLoadcases.length === 0}
                    color={'primary'}
                    startIcon={<DeleteIcon />}
                    onClick={deleteLoadcaseRows}
                  >
                    {getLoadcaseDeleteButtonText()}
                  </Button>
                </Box>
              </Grid>
              <Grid item md={12} >
                <Box
                  className={useStyles.container}
                >
                  <LoadcasesTable
                    data={formData}
                    analysis={props.analysis}
                    status={props.status}
                    update={props.update}
                    selectedLoadcases={selectedLoadcases}
                    setSelectedLoadcases={setSelectedLoadcases}
                    selectedStation={props.selectedStation}
                    stationIdx={props.stationIdx}
                  />
                </Box>
              </Grid>
            </StyledGrid>
          ) : (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '100vh' }}
              style={{ height: "655px"}}
            >
              <Grid item xs={3}>
                <Typography className={classes.inputErrorCardContent}><WarningIcon  sx={{ color: pink[500] }} /> Please select a station.</Typography>
              </Grid>
            </Grid>
          )}

        </Card>

      {error.length > 0 && (
        <div className={classes.errorRoot}>
          <div className={classes.errorContainer}>
            <p className={classes.errorText}>{error[error.length - 1].message}</p>
          </div>
        </div>
      )}

    </div>
  )
};

export default StationSection;