import * as React from 'react';
import TextField from '@material-ui/core/TextField';
// import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Checkbox,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
 const convertPixelsToRem = (unitValue) => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

const FlangeTable = (props) => {
  const tableUnlocked = props.status !== "SUCCESSFUL";

  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
    },

  });

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  // used to setup styles for short text fields
  const ShortTextField = withStyles((theme) => ({
    root: {
      minWidth: 60,
      maxWidth: 120,
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);


  const handleTextfieldChange = async (e, flangeIdx, column, section, props) => {
    if (section !== '') {
      props.update(parseFloat(e.target.value), `data.flanges[${flangeIdx}].${section}.${column}`);
    } else {
      props.update(parseFloat(e.target.value), `data.flanges[${flangeIdx}].${column}`);
    }
  };

  const stationColumns = ['capArea', 'effFactor', 'x', 'z', 'py'];
  const panelColumns = ['del2A', 't', 'delS', 'geG'];
  const leftBorderColumns = ['flangeNo']

  const getStringerStrn = (idx) => {
    return (idx + 1) * 100
  }

  const getField = (flange, column, flangeIdx, section='') => {
    const value = getValue(flange, column, section)
    return (
      <ShortTextField
        name={column}
        defaultValue={value}
        error={value ? Boolean(value.error) : false}
        focused={value ? value.focus : false}
        type={'text'}
        variant="outlined"
        onBlur={(e) => handleTextfieldChange(e, flangeIdx, column, section, props)}
      />
    );

  };

  const getValue = (flange, column, section='', digits=10) => {
    let val;
    if (section === 'inboard') {
      val = flange.inboard[column];
    } else if (section === 'outboard') {
      val = flange.outboard[column];
    } else if (section === 'panel') {
      val = flange.panel[column];
    } else {
      val = flange[column];
    }
    return Math.round((val + Number.EPSILON) * 10**digits) / 10**digits // round to x digits
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && props.data && props.data.flanges) {
      const newSelecteds = props.data.flanges.map((_, i) => i);
      props.setSelectedRows(newSelecteds);
      return;
    }
    props.setSelectedRows([]);
  };

  const handleClick = (event, elecIdent) => {
    const selectedIndex = props.selectedRows.indexOf(elecIdent);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedRows, elecIdent);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedRows.slice(1));
    } else if (selectedIndex === props.selectedRows.length - 1) {
      newSelected = newSelected.concat(props.selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedRows.slice(0, selectedIndex),
        props.selectedRows.slice(selectedIndex + 1)
      );
    }

    props.setSelectedRows(newSelected);
  };

  const isSelected = (elecIdent) => props.selectedRows.indexOf(elecIdent) !== -1;

  const rowCount = () =>
    props.data && props.data.flanges ? props.data.flanges.length : 0;

  // overflowX: 'unset' to avoid small vertical scroll issue (space after the last row)
  return (
    <TableContainer className="hideScrollbar" style={{overflowX: 'unset'}}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
          {tableUnlocked && (
            <TableCell rowSpan={2} padding="checkbox" align='center' style={{padding: 4}}>
              <Checkbox
                color="primary"
                size="small"
                style={{ padding: 2 }}
                indeterminate={
                  props.selectedRows.length > 0 && props.selectedRows.length < rowCount()
                }
                checked={rowCount() > 0 && props.selectedRows.length === rowCount()}
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all circuit breakers',
                }}
              />
            </TableCell>
          )}
          <TableCell
            align="center"
            rowSpan={2}
            style={{
              padding: 8,
              width: "6.66%",
              borderRight: 1,
              borderRightStyle: "solid",
              borderRightColor: "rgba(224, 224, 224, 1)",
            }}>
              <b>Flange No</b>
          </TableCell>
            <TableCell
              align="center"
              colSpan={5}
              style={{
                padding: 4,
                borderRight: 1,
                borderRightStyle: "solid",
                borderRightColor: "rgba(224, 224, 224, 1)",
                }}
            >
              Inboard station
            </TableCell>
            <TableCell
              align="center"
              colSpan={5}
              style={{
                padding: 4,
                borderRight: 1,
                borderRightStyle: "solid",
                borderRightColor: "rgba(224, 224, 224, 1)",
              }}
            >
              Outboard station
            </TableCell>
            <TableCell
              align="center"
              colSpan={4}
              style={{
                padding: 4,
              }}
            >
              Panel data between flanges
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>Cap Area</b></TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>Eff Factor</b></TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>X</b></TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>Z</b></TableCell>
            <TableCell
              align="center"
              style={{
                padding: 8,
                width: "6.66%",
                borderRight: 1,
                borderRightStyle: "solid",
                borderRightColor: "rgba(224, 224, 224, 1)",
              }}>
              <b>Py</b>
            </TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>Cap Area</b></TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>Eff Factor</b></TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>X</b></TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>Z</b></TableCell>
            <TableCell
              align="center"
              style={{
                padding: 8,
                width: "6.66%",
                borderRight: 1,
                borderRightStyle: "solid",
                borderRightColor: "rgba(224, 224, 224, 1)",
              }}>
              <b>Py</b>
            </TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>Del 2A</b></TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>t</b></TableCell>
            <TableCell align="center" style={{padding: 8, width: "6.66%"}}><b>Del S</b></TableCell>
            <TableCell
              align="center"
              style={{padding: 8, width: "6.66%"}}
              ><b>Ge/G</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.flanges
            ? props.data.flanges.map((flange, flangeIdx) => {
                const isItemSelected = isSelected(flangeIdx);

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`flange-${flangeIdx}`}
                    selected={isItemSelected}
                    style={{
                      backgroundColor:
                      [0, props.data.flanges.length - 1].includes(flangeIdx) ? 'cornsilk' :
                      ([1.099, 1.199].includes(flange['flangeNo']) ? 'OldLace' : '')}}
                  >
                    {tableUnlocked && (
                      <TableCell padding="checkbox" align='center'>
                        <Checkbox
                          color="primary"
                          size="small"
                          style={{ padding: 0 }}
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, flangeIdx)}
                        />
                      </TableCell>
                    )}
                      <TableCell
                        className={useStyles.tableRightBorder}
                        align='center'
                        key='flangeNo'
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingRight: 0,
                          paddingLeft: 0,
                          width: "6.66%",
                          borderRight: 1,
                          borderRightStyle: "solid",
                          borderRightColor: "rgba(224, 224, 224, 1)",
                        }}
                      >
                      {tableUnlocked
                        ? getField(flange, 'flangeNo', flangeIdx)
                        : getValue(flange, 'flangeNo')}
                      </TableCell>

                    {stationColumns.map(function (column, index) {
                      return (
                        <TableCell
                          align={'center'}
                          key={column}
                          style={column === 'py' ? {
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: 0,
                            paddingLeft: 0,
                            width: "6.66%",
                            borderRight: 1,
                            borderRightStyle: "solid",
                            borderRightColor: "rgba(224, 224, 224, 1)",
                          } : {
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: 1,
                            paddingLeft: 1,
                            width: "6.66%",
                          }}
                        >
                          {tableUnlocked
                            ? getField(flange, column, flangeIdx, 'inboard')
                            : getValue(flange, column, 'inboard')}
                        </TableCell>
                      );
                    })}

                    {stationColumns.map(function (column) {
                      return (
                        <TableCell
                          align={'center'}
                          key={column}
                          style={column === 'py' ? {
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: 0,
                            paddingLeft: 0,
                            width: "6.66%",
                            borderRight: 1,
                            borderRightStyle: "solid",
                            borderRightColor: "rgba(224, 224, 224, 1)",
                          } : {
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: 1,
                            paddingLeft: 1,
                            width: "6.66%",
                          }}
                        >
                          {tableUnlocked
                            ? getField(flange, column, flangeIdx, 'outboard')
                            : getValue(flange, column, 'outboard')}
                        </TableCell>
                      );
                    })}

                    {panelColumns.map(function (column) {
                      return (
                        <TableCell
                          align={'center'}
                          key={column}
                          style={leftBorderColumns.includes(column) ? {
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: 0,
                            paddingLeft: 0,
                            width: "6.66%",
                            borderLeftWidth: 1,
                            borderLeftColor: 'lightgray',
                            borderLeftStyle: 'solid',
                          } : {
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: 1,
                            paddingLeft: 1,
                            width: "6.66%",
                          }}
                        >
                          {tableUnlocked
                            ? getField(flange, column, flangeIdx, 'panel')
                            : getValue(flange, column, 'panel')}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlangeTable;
