import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import BoxbeamHeader from "./BoxbeamHeader";
import withRouter from "../../../../helpers/withRouter";
import AnalysisDetails from "./LeftMenuItems/AnalysisDetails";
import { NotificationManager } from "react-notifications";
import BoxbeamBodyGeometry from "./LeftMenuItems/BoxbeamBodyGeometry";
import { setNestedValue, checkForData } from "../../../../helpers";
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import ContentLoading from "../../../common/ContentLoading";
import ActivityLog from "./LeftMenuItems/ActivityLog";
import { fetchAuthSession } from 'aws-amplify/auth';
import { cfg } from "../../../../config";
import axios from "axios";
import moment from "moment";
import * as _ from 'lodash';
import FlangeSection from "./FlangeSection";
import CellDataSection from "./CellDataSection";
import LoadcaseSection from "./LoadcaseSection";
import { useParams } from "react-router-dom";

const FuseAnalysis = (props) => {
  const params = useParams();

  const [data, setData] = React.useState({});
  const [lock, setLock] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRunning, setIsRunning] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [project, setProject] = React.useState({
    projectName: "",
    aircraft: "",
    projectID: 1,
    refs: [],
  });

  React.useEffect(() => {
    fetchData()
      .then(() => setIsLoading(false))
      .catch((e) => console.log(e));
  }, []);

  const saveDraft = async (auto = false) => {
    return new Promise((resolve, reject) => {
      setIsSaving(true);

      let newData = { ...data };

      // console.log(newData)

      if (newData.name === lock.name) {
        delete newData.name;
      }
      delete newData.activityLog;
      delete newData.files;

      fetchAuthSession().then((session) => {
        axios
          .patch(
            `${cfg.apiUrl}/app/boxbeam/analysis/${params.projectId}/${params.id}`,
            {
              ...newData,
            },
            {
              headers: {
                Authorization: session.tokens?.idToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((resp) => {
            let tempLock = { ...lock };
            tempLock.name = data.name;
            setLock(tempLock);

            if (resp.status === 200) {
              console.log("Draft Saved.");
              NotificationManager.success(`Data saved succesfully`, 'SUCCESS');
              resolve();
            } else {
              NotificationManager.error(
                "Something went wrong when fetching data. Please check console for more info.",
                "ERROR"
              );
              reject();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 400) {
                NotificationManager.error(
                  err.response.data.error.message,
                  "Error"
                );
              } else {
                NotificationManager.error(
                  "An error has occurred, please try again.",
                  "Error"
                );
              }
            } else if (err.request) {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            } else {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            }
            reject(err);
          })
          .finally(() => {
            setIsSaving(false);
          });
      });
    });
  };


  const fetchData = async () => {
    // Axios logic for fetching data pertaining to analysis ID
    return new Promise((resolve, reject) => {
      fetchAuthSession().then((session) => {
        axios
          .get(`${cfg.apiUrl}/app/boxbeam/analysis/${params.projectId}/${params.id}`, {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          })
          .then(async (resp) => {
            if (resp.status === 200) {
              const newData = {
                name: resp.data.name,
                args: {
                  inboardWingStation: checkForData(resp, "data.args.inboardWingStation", 1),
                  outboardWingStation: checkForData(resp, "data.args.outboardWingStation", 2),
                  cellCount: checkForData(resp, "data.args.cellCount", 1),
                  calculate: {
                    shearCenter: checkForData(resp, "data.args.calculate.shearCenter", true),
                    torsionalConst: checkForData(resp, "data.args.calculate.torsionalConst", true),
                  },
                  print: {
                    sectionProp: checkForData(resp, "data.args.print.sectionProp", true),
                    normalLoadsAndStress: checkForData(resp, "data.args.print.normalLoadsAndStress", true),
                    staGeomMidBay: checkForData(resp, "data.args.print.staGeomMidBay", true),
                    axialLoads: checkForData(resp, "data.args.print.axialLoads", true),
                    shearFlows: checkForData(resp, "data.args.print.shearFlows", true),
                    checkAndBalances: checkForData(resp, "data.args.print.checkAndBalances", true),
                  }
                },
                data: checkForData(resp, "data.data",
                  {
                    flanges: [],
                    loadcases: [],
                    cellData: [],
                  }
                ),
                activityLog: [
                  {
                    title: "Date Created",
                    content: resp.data.created,
                  },
                  {
                    title: "Last Update",
                    content: resp.data.modified,
                  },
                ],
                results: checkForData(resp, "data.results", {}),
                status: resp.data.status,
                runDate: resp.data.runDate ? moment(resp.data.runDate).format("ll") : "",
              };

              setProject(resp.data.project);
              setData({...newData});
              setLock({...newData}); // needed to avoid "name already exist" error when saving
              // await sleep(1000)
              resolve();
            } else {
              reject();
              NotificationManager.error(
                "Something went wrong when fetching data. Please check console for more info.",
                "ERROR"
              );
            }
          })
          .catch((e) => {
            console.log(e);
            NotificationManager.error(
              "Something went wrong when fetching data. Please check console for more info.",
              "ERROR"
            );
            reject(e);
          });
      });
    });
  };

  /*
    Updates the current state.data.
     */
  const updateData = (value, objectLocation, forceUpdate = false) => {
    console.log("updateData")
    let newData = _.set({ ...data }, objectLocation, value) //setNestedValue({ ...data }, objectLocation, value);
    setData(newData);
  };

  const runAnalysis = async () => {
    setIsLoading(true);
    setIsRunning(true);

    await saveDraft(true);

    // console.log("... sleeping for 1 second");
    // await sleep(1000); // temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)

    fetchAuthSession().then((session) => {
      axios
        .post(
          `${cfg.apiUrl}/app/boxbeam/analyze`,
          {
            id: params.id,
            projectId: params.projectId,
          },
          {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (resp) => {
          if (resp.status === 200) {
            await fetchData();
            NotificationManager.success(`Analysis run succesfully`, 'SUCCESS');
            setIsLoading(false);
          } else {
            NotificationManager.warning(
              "Something went wrong, please check the console.",
              "WARNING"
            );
          }
        })
        .catch(async (e) => {
          if (e.response || e.request) {
            NotificationManager.error(
              "Something went wrong, please see console for details.",
              "ERROR"
            );
          } else {
            NotificationManager.error(
              "Something went wrong, please see console for details.",
              "ERROR"
            );
          }
          console.log(e);
          await fetchData();
          setIsLoading(false);
        })
        .finally(async (resp) => {
          setIsRunning(false);
        });
    });
  };

  const pasteAnalysis = async () => {
    const copied = JSON.parse(window.sessionStorage.getItem("copyProject"));
    if (!copied) return;
    if (copied.args.aircraft === data.args.aircraft) {
      let name = data.name;
      copied.name = name;
      setData(copied);
      setLock(copied);
      window.sessionStorage.removeItem("copyProject");
    } else {
      NotificationManager.warning(
        "Cannot copy analysis. MissionMix/Aircraft mismatch.",
        "FAILED"
      );
    }
  };

  // Flanges

  const addFlangeRow = async () => {
    let station = {
      'capArea': 0,
      'effFactor': 0,
      'x': 0,
      'z': 0,
      'py': 0
    }
    let panel = {
      'del2A': 0,
      't': 0,
      'delS': 0,
      'geG': 0
    }
    let newRow = {
      flangeNo: 1,
      inboard: { ...station },
      outboard: { ...station },
      panel: { ...panel }
    };
    let newData = { ...data };
    newData.data.flanges.push(newRow);
    setData(newData);
    console.log(newData)
  };

  const deleteFlanges = async (rowIndexes) => {
    let newData = { ...data };
    newData.data.flanges = newData.data.flanges.filter((stringer, id) => {
      return !rowIndexes.includes(id);
    });
    setData(newData);
  }

    // Cell data

    const addCellDataRow = async () => {
      let newRow = {
        't': 0,
        'delS': 0,
        'geG': 0
      };
      let newData = { ...data };
      newData.data.cellData.push(newRow);
      setData(newData);
      console.log(newData)
    };

    const deleteCellDataRows = async (rowIndexes) => {
      let newData = { ...data };
      newData.data.cellData = newData.data.cellData.filter((stringer, id) => {
        return !rowIndexes.includes(id);
      });
      setData(newData);
    }

  // Loadcases

  const addLoadcaseRow = async () => {
    let station = {
      'totalPy': 0,
      'mx': 0,
      'mz': 0,
      'my': 0,
    }
    let stringerSide = {
      'id': 1,
      'inboard': { ...station },
      'outboard': { ...station }
    }
    let newRow = stringerSide
    let newData = { ...data };
    newData.data.loadcases.push(newRow);
    setData(newData);
  };

  const deleteLoadcases = async (rowIndexes) => {
    let newData = { ...data };
    newData.data.loadcases = newData.data.loadcases.filter((loadcase, id) => {
      return !rowIndexes.includes(id);
    });
    setData(newData);
  }

  return !isLoading ? (
    <div>
      <BoxbeamHeader
        projectID={project.projectID}
        data={data}
        status={data.status}
        isSaving={isSaving}
        isRunning={isRunning}
        functions={{
          updateData: updateData,
          saveDraft: saveDraft,
          submit: runAnalysis,
          paste: pasteAnalysis,
        }}
      />
      <Grid container spacing={2} style={{ paddingBottom: "8px" }}>
        <Grid item md={7}>
          <AnalysisDetails
            refs={project.refs}
            name={data.name}
            updateData={updateData}
            project={project}
            run={data.runDate}
            status={data.status}
          />
        </Grid>
        <Grid item md={5}>
          <ActivityLog data={data} />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <BoxbeamBodyGeometry
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            data={data}
            updateData={updateData}
            saveDraft={saveDraft}
            refresh={fetchData}
            aircraft={project.aircraft?.toUpperCase() ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <FlangeSection
            refresh={fetchData}
            data={data.data}
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            update={updateData}
            saveDraft={saveDraft}
            tableUnlocked={true}
            addFlangeRow={addFlangeRow}
            deleteFlanges={deleteFlanges}
          />
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <CellDataSection
            refresh={fetchData}
            data={data.data}
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            update={updateData}
            saveDraft={saveDraft}
            tableUnlocked={true}
            addCellDataRow={addCellDataRow}
            deleteCellDataRows={deleteCellDataRows}
          />
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <LoadcaseSection
            refresh={fetchData}
            data={data.data}
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            update={updateData}
            saveDraft={saveDraft}
            tableUnlocked={true}
            addLoadcaseRow={addLoadcaseRow}
            deleteLoadcases={deleteLoadcases}
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <div>
      <ContentLoading />
    </div>
  );
};

// Temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default withStyles(ASPECStyles, { withTheme: true })(
  withRouter(FuseAnalysis)
);
