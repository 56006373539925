import React from 'react';
import {Menu, MenuItem, IconButton, Hidden, Grid, Typography, Divider} from '@material-ui/core';
import { useNavigate  } from "react-router-dom";
import withRouter from '../../../helpers/withRouter';
import {signOutFromApp} from "../../../helpers";
import PasswordDialog from "./PasswordDialog";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NotificationStyles from '../../../styles/jss/components/NotificationStyles';
import { fetchUserAttributes } from 'aws-amplify/auth';

const UserOptions = (props) => {

    const useStyles = makeStyles(NotificationStyles);
    const classes = useStyles();
    const navigate = useNavigate();

    const StyledMenu = withStyles({
    })((props) => (
      <Menu
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...props}
      />
    ));

    // Password Dialog
    const [open, setOpen] = React.useState(false);
    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    // Menu handler
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    // Initials
    const [userInitials, setUserInitials] = React.useState('');

    React.useEffect(() => {
      fetchUserAttributes()
        .then((attributes) => setUserInitials(attributes.email.charAt(0).toUpperCase()))
        .catch((e) => console.log(e));
    }, []);

    const handleClose = (action) => {
        switch(action) {
            // case 'profile':
            //     props.history.push('/profile');
            //     setAnchorEl(null);
            //     break;
            case 'password':
                openDialog()
                setAnchorEl(null);
                break;
            default:
                setAnchorEl(null);
        }
    };

    return (
        <div >
            <PasswordDialog open={open} closeDialog={closeDialog} />
            <IconButton size="small"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => handleClick(e)}
            >
                <Hidden smUp>
                    <MoreVertIcon />
                </Hidden>
                <Hidden smDown>
                    <Avatar className={classes.grey}>{userInitials}</Avatar>
                </Hidden>
            </IconButton>
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Grid container spacing={0} className={classes.typography}>
            <Grid item xs={9} >
                <Typography className={classes.header} variant="subtitle1">{props.org.name}</Typography> {/*Placeholder for Company name */}
            </Grid>
            </Grid>
            <Divider />
                {/*<MenuItem className={classes.menuSpacing} onClick={() => handleClose('profile')}>Profile</MenuItem>*/}
                <MenuItem className={classes.menuSpacing} onClick={() => handleClose('password')}>Change Password</MenuItem>
                <MenuItem className={classes.menuSpacing} onClick={() => signOutFromApp(navigate)}>Sign Out</MenuItem>
            </StyledMenu>
        </div>
    );
}

export default withRouter(UserOptions);