import React from 'react';
import {Card, CardContent, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SecurityIcon from '@mui/icons-material/Security';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const MemberMenu = (props) => {

    const {changeMembers} = props;

    const switchMembers = (type) => {
        changeMembers(type)
    }

    return(
        <Card>
            <CardContent>
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button onClick={() => switchMembers('allMembers')}>
                        <ListItemIcon>
                            <PeopleAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="All Members" />
                    </ListItem>
                    <ListItem button onClick={() => switchMembers('admin')}>
                        <ListItemIcon>
                            <SecurityIcon />
                        </ListItemIcon>
                        <ListItemText primary="Admin Members" />
                    </ListItem>
                    <ListItem button onClick={() => switchMembers('team')}>
                        <ListItemIcon>
                            <PermDataSettingIcon />
                        </ListItemIcon>
                        <ListItemText primary="Team Members" />
                    </ListItem>
                    <ListItem button onClick={() => switchMembers('deleted')}>
                        <ListItemIcon>
                            <RemoveCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Removed members" />
                    </ListItem>
                </List>

            </CardContent>
        </Card>
    )
}

export default MemberMenu;