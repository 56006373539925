import React from 'react';
import {
  Card,
  CardContent,
  TextField,
  Typography,
  TableContainer,
  Table,
  Button,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CardHeader,
  Chip,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Icon,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import * as _ from 'lodash';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ImportData from "./ImportData";

// Icon Imports
import WarningIcon from "@material-ui/icons/Warning";
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ExposureIcon from '@mui/icons-material/Exposure';
import ExposureTwoToneIcon from '@mui/icons-material/ExposureTwoTone';

const useStyles = makeStyles(ASPECStyles);

function isBlank(str) {
  return /^\s*$/.test(str); // test for blank string
}

function getLoadConfigDmf(value, min, inputProps = {}) {
  const validator = (v) => !isBlank(v) && v >= min;
  return {
    value,
    type: "number",
    validator: validator,
    validatorError: `DMF Factor must be greater than ${min}.`,
    disabled: value === null,
    inputProps,
    error: !validator(value),
    focus: false,
  };
}

function getLoadConfig(value, min, max, inputProps = {}) {
  const validator = (v) => !isBlank(v);
  return {
    value,
    type: "number",
    validator: validator,
    validatorError: `Load Stress (KSI) must be an integer.`,
    disabled: value === null,
    inputProps,
    error: !validator(value),
    focus: false,
  };
}

function getIntegerConfig(name, value, min, inputProps = {}) {
  const validator = (v) => !isBlank(v) && v > min;
  return {
    value,
    type: "number",
    validator: validator,
    validatorError: `${name} must be an integer greater than ${min}.`,
    disabled: value === null,
    inputProps,
    error: !validator(value),
    focus: false,
  };
}

const generateFormData = (data = [], overrideManeuverSegments) => {
  // var overrideManeuverSegments = typeof args.overrideManeuverSegments !== 'undefined' ? args.overrideManeuverSegments : false;
  return data.map((mission, midx) => ({
    ...mission,
    id: midx.toString(),
    segments: mission.segments.map((segment, segmentId) => {
      var overrideDmf = typeof segment.overrideDmf !== 'undefined' ? segment.overrideDmf : false;
      var enableNegativeStress = typeof segment.enableNegativeStress !== 'undefined' ? segment.enableNegativeStress : false;
      const isDisabledManeuverDMF = isManeuverSegment(segment) && !overrideManeuverSegments;

      if(segment.name === 'modeled-data' // keep for backwards compatibility
      || segment.type === 'modeled') {
        return({
          ...segment,
          uniqueId: `${midx}-${segmentId}`,
          overrideDmf: overrideDmf,
          enableNegativeStress: enableNegativeStress,
          values: {
            dmf: getLoadConfigDmf((segment.values.dmf ? 1 : 0), 0, {step: .1, min: 0}), // use zero if undefined and 1 for maneuver segments
            dmfNeg: getLoadConfigDmf((segment.values.dmfNeg ? 1 : 0), 0, {step: .1, min: 0}), // use zero if undefined and 1 for maneuver segments
            constantLoad: getLoadConfig(segment.values.constantLoad, -1000, 1000),
            constantLoadNeg: getLoadConfig(segment.values.constantLoadNeg ?? 0, -1000, 1000),
            alternatingLoad: getLoadConfig(segment.values.alternatingLoad, -1000, 1000, {}),
            alternatingLoadNeg: getLoadConfig(segment.values.alternatingLoadNeg ?? 0, -1000, 1000, {}),
            pressureLoad: getLoadConfig(segment.values.pressureLoad, -1000, 1000),
            mergeId: getIntegerConfig("Merge ID", segment.group && segment.group.id ? segment.group.id : 1, 0, {step: 1, min: 1}),
          }
        })
      } else { // custom or existing segments
        return({
          ...segment,
          uniqueId: `${midx}-${segmentId}`,
          name: segment.name,
          overrideDmf: overrideDmf,
          enableNegativeStress: enableNegativeStress,
          values: {
            dmf: getLoadConfigDmf(isDisabledManeuverDMF ? 1 : (segment.values.dmf ?? 0), 0, {step: .1, min: 0}), // use zero if undefined and 1 for maneuver segments
            dmfNeg: getLoadConfigDmf(isDisabledManeuverDMF ? 1 : (segment.values.dmfNeg ?? 0), 0, {step: .1, min: 0}), // use zero if undefined and 1 for maneuver segments
            constantLoad: getLoadConfig(segment.values.constantLoad, -1000, 1000),
            constantLoadNeg: getLoadConfig(segment.values.constantLoadNeg ?? 0, -1000, 1000),
            alternatingLoad: getLoadConfig(segment.values.alternatingLoad, -1000, 1000, {}),
            alternatingLoadNeg: getLoadConfig(segment.values.alternatingLoadNeg ?? 0, -1000, 1000, {}),
            pressureLoad: getLoadConfig(segment.values.pressureLoad, -1000, 1000),
            mergeId: getIntegerConfig("Merge ID", segment.group && segment.group.id ? segment.group.id : 1, 0, {step: 1, min: 1}),
            duration: getIntegerConfig("Length", segment.duration, 0, {step: 1, min: 1}),
          }
        })
      }
    })
  }))
}

function isManeuverSegment(segment) {
  if (segment.type === 'custom') {
    return isManeuverSegmentId(segment.id); // custom segments have no name, use the segment ID
  } else {
    return isManeuverSegmentName(segment.name);
  }
}

function isManeuverSegmentName(segmentName) {
  // A maneuver segment ends with 'Man'
  return segmentName && segmentName.length >= 3
    && segmentName.substr(segmentName.length - 3) === 'Man';
}

function isManeuverSegmentId(segmentId) {
  // A maneuver segment ID starts with 1 (11, 12, 13...)
  const idStr = String(Math.abs(segmentId));
  return idStr.length === 2 && idStr[0] === '1';
}

const ASPECForm_variant2 = (props) => {

  const classes = useStyles();
  const status = props.status === "SUCCESSFUL";

  const [EnableDmfFactor, setEnableDmfFactor] = React.useState(props.dmf);
  const [OverrideManeuverSegments, setOverrideManeuverSegments] = React.useState(props.overrideManeuverSegments);
  const [EnableNegativeStress, setEnableNegativeStress] = React.useState(props.enableNegativeStress);
  const [FlightCount, setFlightCount] = React.useState(props.flightCount);
  const [itter, setItter] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [formData, setFormData] = React.useState([]);
  const [expandedMissionId, setExpandedMissionId] = React.useState(0)
  const expander = (missionId) => (event, isExpanded) => {
    setExpandedMissionId(isExpanded ? missionId : false);
  };
  const [error, setError] = React.useState([]);

  // Update input variants when missionMix is changed.
  React.useEffect(  () => {
    if(props.missionMix !== '') {
      setFormData(generateFormData(props.data, props.overrideManeuverSegments));
      setFlightCount(props.flightCount);
      setLoading(false);
      setItter(i => i + 1);
    }
    return function cleanup() {
      setFormData([]);
    }
  }, [props.missionMix, props.data])


  // const createCustomError = (message, fdIndex, segmentId, name) => {
  //     let errorID = _.uniqueId('error-')
  //     let tempData = formData;
  //     tempData[fdIndex].segments[segmentId].values[name].error = errorID;
  //     let newError = {
  //         id: errorID,
  //         message: `ERROR: at mission ${formData[fdIndex].name + formData[fdIndex].percentage}, segment ${formData[fdIndex].segments[segmentId].name}, location ${name}. ${message}`
  //     }
  //     let tempErrors = error;
  //     tempErrors.push(newError)
  //     setError(tempErrors)
  //     setFormData(tempData)
  // }

  const toggleEnableDmfFactor = async () => {
    let current = EnableDmfFactor;
    await setEnableDmfFactor(i => !i)
    props.update(!current, `args.DMF`)
  }

  const toggleOverrideManeuverSegments = async () => {
    let current = OverrideManeuverSegments;
    await setOverrideManeuverSegments(i => !i)
    props.update(!current, `args.overrideManeuverSegments`);

    // Reset all maneuver DMF to 1 if OverrideManeuverSegments is being set to false
    if (current) { // current value is true, so we are setting it to false
      console.log("RESET")
      let tempData = formData;
      tempData.forEach((mission, missionId) => {
        mission.segments.forEach((segment, segmentId) => {
          if (isManeuverSegment(segment)) {
            var values = segment.values;
            const value = 1;

            // Update DMF
            values.dmf.value = value;
            values.dmfNeg.value = value;
            props.update(value, `data.missions.${missionId}.segments.${segmentId}.values.dmf`)
            props.update(value, `data.missions.${missionId}.segments.${segmentId}.values.dmfNeg`)

            // Update alternating load
            values.alternatingLoad.value = segment.values.constantLoad.value;
            values.alternatingLoadNeg.value = segment.values.constantLoadNeg.value;
            props.update(values.alternatingLoad.value, `data.missions.${missionId}.segments.${segmentId}.values.alternatingLoad`)
            props.update(values.alternatingLoadNeg.value, `data.missions.${missionId}.segments.${segmentId}.values.alternatingLoadNeg`)
          }
        })
      })
      setFormData(generateFormData(props.data, props.overrideManeuverSegments));
      setItter(i => i+1);
    }
  }

  const toggleEnableNegativeStress = async () => {
    let current = EnableNegativeStress;
    await setEnableNegativeStress(i => !i)
    props.update(!current, `args.enableNegativeStress`)
  }

  const handleFlightCountChange = async (e) => {
    // let current = FlightCount;
    let value = e.target.value;
    await setFlightCount(value)
    props.update(value, `args.flightCount`)
  }

  const toggleEditable = (fdIndex, segmentId) => {
    let tempData = formData;
    tempData[fdIndex].segments[segmentId].edit = !tempData[fdIndex].segments[segmentId].edit;
    setFormData(tempData)
    setItter(i => i+1)
    props.update(tempData[fdIndex].segments[segmentId].edit, `data.missions.${fdIndex}.segments.${segmentId}.edit`)
  }

  const handleTextfieldChange = async (e, segmentId, fdIndex, negativeField=false) => {
    let tempData = formData;

    let name = e.target.name;
    if (name === '') return;

    let baseName = `${name}`;
    if (negativeField) {
      baseName = baseName.replace('Neg', ''); // remove Neg to get basename
    }

    var values = tempData[fdIndex].segments[segmentId].values;
    // if (!values[name]) {
    //   values[name] = values[baseName]; // copy positive value to negative value
    // }
    var property = values[name];

    // const textColumns = ['name'];
    const integerColumns = ['mergeId', 'duration'];
    if (integerColumns.includes(baseName)) {
      property.value = parseInt(e.target.value);
    } else {
      property.value = parseFloat(e.target.value);
    }

    if(!formData[fdIndex].segments[segmentId].values[name].validator(e.target.value)) {
      let errorID = _.uniqueId('error-')
      property.error = errorID;
      let newError = {
        id: errorID,
        message: `ERROR: ${formData[fdIndex].segments[segmentId].values[name].validatorError}`
      }
      let tempErrors = error;
      tempErrors.push(newError)
      setError(tempErrors)
    } else {
      let tempErrors = error;
      for (let i = 0; i < tempErrors.length; i++) {
        let obj = tempErrors[i];
        if(obj.id === values[e.target.name].error) {
          tempErrors.splice(i, 1);
        }
      }
      setError(tempErrors);
      property.error = false;

      const isDisabledManeuverDMF = isManeuverSegment(tempData[fdIndex].segments[segmentId]) && !OverrideManeuverSegments;
      const isDmfEnabled = EnableDmfFactor || tempData[fdIndex].segments[segmentId].overrideDmf

      let dmfName = "dmf";
      let constantLoadName = "constantLoad";
      let alternatingLoadName = "alternatingLoad";
      if (negativeField) {
        const suffix = "Neg";
        dmfName += suffix;
        constantLoadName += suffix;
        alternatingLoadName += suffix;
      }

      switch(baseName) {
        case('dmf'):
          // Update alternatingLoad if DMF > 0
          if (e.target.value > 0 && isDmfEnabled) {
            values[alternatingLoadName].value = e.target.value * values[constantLoadName].value;
            props.update(values[alternatingLoadName].value, `data.missions.${fdIndex}.segments.${segmentId}.values.${alternatingLoadName}`)
          }
          props.update(parseFloat(e.target.value), `data.missions.${fdIndex}.segments.${segmentId}.values.${name}`)
          break;
        case 'constantLoad':
          // Update alternatingLoad if DMF > 0
          if (values[dmfName].value > 0 && (isDmfEnabled || isDisabledManeuverDMF)) {
            values[alternatingLoadName].value = e.target.value * values[dmfName].value;
            props.update(values[alternatingLoadName].value, `data.missions.${fdIndex}.segments.${segmentId}.values.${alternatingLoadName}`)
          }
          // Update DMF
          if(e.target.value / values[alternatingLoadName].value > 0 && !isDmfEnabled) {
            values[dmfName].value = values[alternatingLoadName].value / e.target.value;
            props.update(values[dmfName].value, `data.missions.${fdIndex}.segments.${segmentId}.values.${dmfName}`)
          }
          props.update(parseFloat(e.target.value), `data.missions.${fdIndex}.segments.${segmentId}.values.${name}`)
          break;
        case 'alternatingLoad':
          // Update DMF
          if(values[constantLoadName].value / e.target.value > 0) {
            values[dmfName].value = e.target.value / values[constantLoadName].value;
            props.update(values[dmfName].value, `data.missions.${fdIndex}.segments.${segmentId}.values.${dmfName}`)
          }
          props.update(parseFloat(e.target.value), `data.missions.${fdIndex}.segments.${segmentId}.values.${name}`)
          break;
        case 'pressureLoad':
          props.update(parseFloat(e.target.value), `data.missions.${fdIndex}.segments.${segmentId}.values.${name}`)
          break;
        case('mergeId'):
          props.update(parseInt(e.target.value), `data.missions.${fdIndex}.segments.${segmentId}.group.id`)
          break;
        case('duration'):
          props.update(parseInt(e.target.value), `data.missions.${fdIndex}.segments.${segmentId}.duration`)
          break;
        default:
      }
      setFormData(generateFormData(props.data, props.overrideManeuverSegments)); // to refresh mission duration in Accordion
    }
    setItter(itter + 1);
  }

  const inputField = (row, column, index, formDataIndex, negativeField=false) => {
    const segment = formData[formDataIndex].segments[index];
    const canSegmentHaveNegativeStress =  canHaveNegativeStress(segment, props.config.occurrence_distributions); // (equation !== 3);

    // Create or get negative column
    const baseColumn = `${column}`;
    column += negativeField ? 'Neg' : '';

    const values = segment.values[column];

    const isDisabledManeuverDMF = isManeuverSegment(segment) && !OverrideManeuverSegments;
    const isDmfEnabled = EnableDmfFactor || segment.overrideDmf
    const isNegativeStressEnabled = EnableNegativeStress || segment.enableNegativeStress
    let isDisabledDmfColumn = (baseColumn === 'dmf' && (!isDmfEnabled || isDisabledManeuverDMF));
    let isDisabledAltLoadColumn = (baseColumn === 'alternatingLoad' && (isDmfEnabled|| isDisabledManeuverDMF));

    const handleClickOverrideDmf = async (e, segmentId, fdIndex) => {
      segment.overrideDmf = !segment.overrideDmf;
      props.update(segment.overrideDmf, `data.missions.${formDataIndex}.segments.${index}.overrideDmf`)
    };

    const handleClickEnableNegativeStress = async (e, segmentId, fdIndex) => {
      segment.enableNegativeStress = !segment.enableNegativeStress;
      props.update(segment.enableNegativeStress, `data.missions.${formDataIndex}.segments.${index}.enableNegativeStress`)
    };

    var endAdornment = (
      <InputAdornment position="end">
          <IconButton
            aria-label="Edit DMF Factor"
            onClick={(e) => handleClickOverrideDmf(e, props.index, props.formDataIndex)}
            size="large">
          {segment.overrideDmf ? <VisibilityOffIcon/> : <VisibilityIcon/>}
        </IconButton>
      </InputAdornment>
    )

    let inputProps = { ...values.inputProps }
    if (baseColumn === 'dmf') {
      if (!EnableDmfFactor && !isDisabledManeuverDMF) {
        inputProps = {
          ...values.inputProps,
          endAdornment: endAdornment,
          classes: {
            adornedEnd: classes.endAdornment
          }
        }
      }
    } else {
      let startAdornment = <InputAdornment position="start"/>
      if (isNegativeStressEnabled && canSegmentHaveNegativeStress && ["constantLoad", "alternatingLoad"].includes(baseColumn)) {
        startAdornment =
        <InputAdornment position="start">
          <IconButton size="small" style={{padding: 0}} disabled>
            {negativeField ? <RemoveCircleOutlineIcon/> : <AddCircleOutlineIcon/>}
          </IconButton>
        </InputAdornment>
      }
      endAdornment = <InputAdornment position="end"/>
      if (column === "constantLoad" && canSegmentHaveNegativeStress) {
        endAdornment =
          <InputAdornment position="end">
          {EnableNegativeStress ? // hide segment level enableNegativeStress if global is set
            <IconButton size="small" style={{padding: 0}} disabled>
              <Icon/>
            </IconButton> :
            <IconButton
              size="small"
              style={{padding: 0}}
              onClick={(e) => handleClickEnableNegativeStress(e, props.index, props.formDataIndex)}
            >
              {isNegativeStressEnabled ? <ExposureTwoToneIcon/> : <ExposureIcon/>}
            </IconButton>}
          </InputAdornment>
      }
      inputProps = {
        ...values.inputProps,
        startAdornment: startAdornment,
        endAdornment:endAdornment
      }
    }

    return (
      <TextField
        margin={'none'}
        size={'small'}
        variant={'outlined'}
        name={column}
        defaultValue={(isDisabledDmfColumn && !isDisabledManeuverDMF) ? "--" : values.value}
        error={Boolean(values.error)}
        focused={values.focus}
        type={isDisabledDmfColumn ? "text" : 'number'}
        InputProps={inputProps}
        // inputProps={{className: classes.input}}
        disabled={values.disabled ||
          isDisabledDmfColumn ||
          isDisabledAltLoadColumn
        }
        onBlur={(e) => handleTextfieldChange(e, index, formDataIndex, negativeField)}
        className={values.disabled ? classes.disabledTextField : classes.enabledTextField}
      />
    )
  }

  const successField = (row, column, index, formDataIndex, negativeField=false) => {
    // Create or get negative column
    column += negativeField ? 'Neg' : '';

    return(
      <Typography variant={'caption'}>{formData[formDataIndex].segments[index].values[column].value}</Typography>
    )
  }

  const successNonValueField = (row, column, index, formDataIndex) => {
    return(
      <Typography variant={'caption'}>{formData[formDataIndex].segments[index][column]}</Typography>
    )
  }

  const successEquationField = (row, column, index, formDataIndex) => {
    const equations = [
      {id: 2, name: "Bislope cruise"},
      {id: 3, name: "Ground and taxi"},
      {id: 5, name: "Landing"},
    ];
    const equation = equations.find(eq => eq.id === formData[formDataIndex].segments[index][column]);
    const equationName = equation ? equation.name : "Ground and taxi"; // default value (backward compatibility)
    return(
      <Typography variant={'caption'}>{equationName}</Typography>
    )
  }

  const mergeField = (row, column, index, formDataIndex) => {
    const segment = formData[formDataIndex].segments[index];
    const values = segment.values[column];

    let isMerge = segment.group && segment.group.type === "MERGE";

    const handleClickEnableMerge = async (e, segmentId, fdIndex) => {
      if (segment.group.type === " ") {
        segment.group.type = "MERGE";
      } else {
        segment.group.type = " "; // need blank space for regular segment
      }
      props.update(segment.group.type, `data.missions.${formDataIndex}.segments.${index}.group.type`)
    };

    var endAdornment = (
      <InputAdornment position="end">
          <IconButton
            aria-label="Is Merge Segment"
            onClick={(e) => handleClickEnableMerge(e, props.index, props.formDataIndex)}
            size="large">
          {isMerge ? <VisibilityOffIcon/> : <VisibilityIcon/>}
        </IconButton>
      </InputAdornment>
    )
    var inputProps = {
      ...values.inputProps,

      endAdornment: endAdornment,
      classes: {
        adornedEnd: classes.endAdornment
      }
    }

    return (
      <TextField
        // label="Merge ID"
        margin={'dense'}
        size={'small'}
        variant={'outlined'}
        name={column}
        defaultValue={isMerge ? values.value : "--"}
        error={Boolean(values.error)}
        focused={values.focus}
        type={isMerge ? 'number' : "text"}
        InputProps={inputProps}
        // inputProps={{className: classes.input}}
        disabled={values.disabled || !isMerge}
        onBlur={(e) => handleTextfieldChange(e, index, formDataIndex)}
        // onBlur={(e) => props.update(values.value, `data.missions.${formDataIndex}.segments.${index}.mergeId`)}
        className={values.disabled ? classes.disabledTextField : classes.enabledTextField}
      />
    )
  }

  const durationField = (row, column, index, formDataIndex) => {
    const segment = formData[formDataIndex].segments[index];
    const values = segment.values[column];

    return (
      <TextField
        // label="Length"
        size={'small'}
        variant={'outlined'}
        name={column}
        defaultValue={values.value}
        error={Boolean(values.error)}
        focused={values.focus}
        type="number"
        InputProps={{
          endAdornment:
          <InputAdornment position="end">
              <Typography variant={'caption'} style={{ color: "rgba(0,0,0,0.54)" }}>min</Typography>
            </InputAdornment>
        }}
        disabled={values.disabled}
        onBlur={(e) => handleTextfieldChange(e, index, formDataIndex)}
        className={values.disabled ? classes.disabledTextField : classes.enabledTextField}
      />
    )
  }

  const idField = (row, column, index, formDataIndex, occurrence_distributions) => {
    const segment = formData[formDataIndex].segments[index];
    const segmentId = segment.id;

    const handleIdChange = async (e, segmentId, fdIndex) => {
      const id = e.target.value;
      const occ_dist = occurrence_distributions.find(occ_dist => occ_dist.id === id);
      const name = occ_dist.name;

      var values = formData[fdIndex].segments[segmentId].values;

      // Set DMF to 1 for Man segments
      const isManeuver = isManeuverSegment(segment);
      if(isManeuver) {
        // Update DMF
        values.dmf.value = 1;
        props.update(values.dmf.value, `data.missions.${fdIndex}.segments.${segmentId}.values.dmf`)
        // Update alternatingLoad
        values.alternatingLoad.value = values.constantLoad.value;
        props.update(values.alternatingLoad.value, `data.missions.${fdIndex}.segments.${segmentId}.values.alternatingLoad`);
      }

      // Set pressure load to null for equation 3 and 0 otherwise
      if (occ_dist.equation === 3) {
        values.pressureLoad.value = null;

      } else {
        if (values.pressureLoad.value === null) {
          values.pressureLoad.value = 0;
        }
      }
      props.update(values.pressureLoad.value, `data.missions.${fdIndex}.segments.${segmentId}.values.pressureLoad`)

      // Update segment id and name
      props.update(id, `data.missions.${fdIndex}.segments.${segmentId}.id`)
      props.update(name, `data.missions.${fdIndex}.segments.${segmentId}.name`);

      setFormData(generateFormData(props.data, props.overrideManeuverSegments))
    };

    return (
      <FormControl  variant="outlined" style={{width: '100%', margin: "0px"}} margin={'dense'}>
        <TextField
            select
            variant="outlined"
            size='small'
            labelId="segment-id"
            id="segment-id"
            value={segmentId}
            name={'id'}
            onChange={(e) => handleIdChange(e, index, formDataIndex)}
            SelectProps={{
              renderValue: occ_dist_id => occ_dist_id
            }}
        >
            {props.config.occurrence_distributions && props.config.occurrence_distributions.map((occ_dist, idx) => (
              <MenuItem key={idx} value={occ_dist.id}><em>{occ_dist.id} - {occ_dist.name}</em></MenuItem>
          ))}
        </TextField>
      </FormControl>
    )
  }

  const equationField = (row, column, index, formDataIndex, occurrence_distributions) => {
    const segment = formData[formDataIndex].segments[index];
    const segmentEquation = segment.equation;
    const equations = [
      {id: 2, name: "Bislope cruise"},
      {id: 3, name: "Ground and taxi"},
      {id: 5, name: "Landing"},
    ];

    const setLoadValuesToNull = (properties, values) => {
      // Set selected properties to null
      for (const prop of properties) {
        values[prop].value = null;
      }
      // Set all other properties to 0 if they were null
      const allProperties = ["constantLoad", "alternatingLoad", "pressureLoad"];
      for (const prop of allProperties) {
        if (!properties.includes(prop) && values[prop].value === null) {
          values[prop].value = 0;
        }
      }
    }

    const handleEquationChange = async (e, segmentId, fdIndex) => {
      const equation = e.target.value;
      var values = formData[fdIndex].segments[segmentId].values;

      // Set pressure load to null for equation 3 and 0 otherwise
      if (equation === 3) {
        // Disable pressure load, enable constant and alternating load
        setLoadValuesToNull(["pressureLoad"], values);
      } else if (equation === 5) {
        // Disable all load values
        setLoadValuesToNull(["constantLoad", "alternatingLoad", "pressureLoad"], values);
      } else {
        // Enable all load values
        setLoadValuesToNull([], values);
      }

      // Update load values
      props.update(values.constantLoad.value, `data.missions.${fdIndex}.segments.${segmentId}.values.constantLoad`)
      props.update(values.alternatingLoad.value, `data.missions.${fdIndex}.segments.${segmentId}.values.alternatingLoad`)
      props.update(values.pressureLoad.value, `data.missions.${fdIndex}.segments.${segmentId}.values.pressureLoad`)

      // Update equations
      props.update(equation, `data.missions.${fdIndex}.segments.${segmentId}.equation`)

      setFormData(generateFormData(props.data, props.overrideManeuverSegments))
    };

    return (
      <FormControl  variant="outlined" style={{width: '100%', margin: "0px"}} margin={'dense'}>
        <TextField
            select
            variant="outlined"
            size='small'
            label='Regression equation'
            labelId="equation-id"
            id="equation-id"
            value={segmentEquation}
            name={'id'}
            onChange={(e) => handleEquationChange(e, index, formDataIndex)}
            // SelectProps={{
            //   renderValue: occ_dist_id => occ_dist_id
            // }}
        >
            {equations.map(equation => (
              <MenuItem key={equation.id} value={equation.id}><em>{equation.name}</em></MenuItem>
          ))}
        </TextField>
      </FormControl>
    )
  }

  // Modeled data functions

  const addModeledData = () => {
    props.addModeledData(expandedMissionId); // only add modeled data to the expanded mission
    setFormData(generateFormData(props.data, props.overrideManeuverSegments))
    setItter(i => i + 1)
  }

  const addModeledSegment = (midx, sidx) => {
    props.addModeledPair(midx, sidx);
    setFormData(generateFormData(props.data, props.overrideManeuverSegments))
    setItter(i => i + 1)
  }

  const removeModeledData = (segmentId) => {
    props.removeSegment(segmentId, expandedMissionId);
    setFormData(generateFormData(props.data, props.overrideManeuverSegments))
    setItter(i => i + 1)
  }

  const removeModeledDataPair = (missionId, segmentId, loadPairId) => {
    props.removeModeledDataPair(missionId, segmentId, loadPairId);
    setFormData(generateFormData(props.data, props.overrideManeuverSegments))
    setItter(i => i + 1)
  }

  const modeledDataInput = (missionId, sidx, loadPairId, key) => {
    return(
      <TextField
        defaultValue={formData[missionId].segments[sidx].loadPairs[loadPairId][key]}
        name={key}
        onBlur={(e) => {
          let tempData = formData;
          tempData[missionId].segments[sidx].loadPairs[loadPairId][key] = parseFloat(e.target.value);
          setFormData(tempData)
          props.update(parseFloat(e.target.value), `data.missions.${missionId}.segments.${sidx}.loadPairs.${loadPairId}.${key}`)
        }}
      />
    )
  }

  const modeledDataSuccess = (missionId, sidx, loadPairId, key) => (
    <Typography variant={'caption'}>{formData[missionId].segments[sidx].loadPairs[loadPairId][key]}</Typography>
  )

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return
    }

    props.moveSegment(source.index, destination.index, expandedMissionId)
    setFormData(generateFormData(props.data, props.overrideManeuverSegments))
  }

  const getMissionName = (mission) => {
    const durationMin = mission.segments.map(s => s.duration ?? 0).reduce((a, b) => a + b, 0);
    const durationHours = (durationMin / 60).toFixed(1);
    let length = durationHours > 0 ? `${durationHours} HR ` : '' // add mission length if available
    return `${mission.name} ${length}${mission.percentage}%`
  }

  // Custom segment functions

  const addCustomSegment = () => {
    props.addCustomSegment(expandedMissionId, props.config.occurrence_distributions); // only add custom segment to the expanded mission
    setFormData(generateFormData(props.data, props.overrideManeuverSegments))
    setItter(i => i + 1)
  }

  const getEquation = (segment, occurrence_distributions) => {
    let equation = 3; // default value (backward compatibility)
    if (segment.type === "modeled") {
      equation = segment.equation;
    } else {
      const occ_dist = occurrence_distributions.find(occ_dist => occ_dist.id === segment.id);
      if (occ_dist) {
        equation = occ_dist.equation;
      }
    }
    return equation;
  }

  const canHaveNegativeStress = (segment, occurrence_distributions) => {
    const equation = getEquation(segment, occurrence_distributions);
    return equation === 2;
  }

  return (
    <div>
      {props.missionMix === '' ? (
        <div>
          <Card>
            <CardContent className={classes.inputErrorCard}>
              <Typography className={classes.inputErrorCardContent}><WarningIcon color={'error'} /> Please select mission mix in the 'Predefined Data' section.</Typography>
            </CardContent>
          </Card>
        </div>
      ) : (
        <Card>
          <CardHeader
            title={'Analysis Inputs'}
            classes={{
              title: classes.cardHeaderTitle,
              root: classes.cardHeaderRoot,
              content: classes.cardHeaderContent,
              action: classes.cardHeaderAction
            }}
            action={
              <div className={classes.cardHeaderActionContent}>
                <div>
                  <FormControlLabel
                    style={{paddingLeft: 14, marginLeft: 4, borderLeft: '1px solid lightgray'}}
                    control={
                      <Checkbox
                        disabled={status}
                        checked={EnableDmfFactor}
                        onChange={() => toggleEnableDmfFactor()}
                        name="DMF"
                        color="primary"
                      />
                    }
                    label="Enable DMF Factor"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={status}
                        checked={OverrideManeuverSegments}
                        onChange={() => toggleOverrideManeuverSegments()}
                        name="OverrideManeuverSegments"
                        color="primary"
                      />
                    }
                    label="Override maneuver segments"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={status}
                        checked={EnableNegativeStress}
                        onChange={() => toggleEnableNegativeStress()}
                        name="EnableNegativeStress"
                        color="primary"
                      />
                    }
                    label="Enable Non-Constant 1g"
                  />
                  {formData.length === 1 && (
                    <TextField
                      disabled={status || formData.length !== 1}
                      hidden={true}
                      label="Number of flights"
                      size={'small'}
                      variant={'outlined'}
                      name={'flightCount'}
                      value={FlightCount}
                      type="number"
                      onChange={(e) => handleFlightCountChange(e)}
                    />
                  )}
                </div>

                <div>
                  <ImportData
                    status={status}
                    isRunning={props.isRunning}
                    isSaving={props.isSaving}
                    refresh={props.refresh}
                    updateData={props.update}
                    aircraft={props.aircraft}
                    missionMix={props.missionMix}
                    saveDraft={props.saveDraft}
                  />
                  <Button
                    disabled={status || props.isRunning || props.isSaving || !props.config.occurrence_distributions || formData.length !== 1}
                    style={{marginRight: 10, color: status || props.isRunning || props.isSaving || ! props.config.occurrence_distributions || formData.length !== 1 ? '#bdbdbd' : '#2e7d32'}}
                    variant={'outlined'}
                    size={'small'}
                    onClick={() => addCustomSegment()}>
                    + Add Segment
                  </Button>
                  <Button
                    disabled={status  || props.isRunning || props.isSaving}
                    style={{marginRight: 10}}
                    variant={'outlined'}
                    color={'primary'}
                    size={'small'}
                    onClick={() => addModeledData()}>
                      + Add Modeled Segment
                    </Button>
                </div>

              </div>
            }
          />
          {!loading && (
            <>
              {formData.map((mission, missionId) => (
                <Accordion key={missionId} expanded={expandedMissionId === missionId} onChange={expander(missionId)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{getMissionName(mission)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table size="small" key={itter}>
                      <TableHead>
                        <TableRow>
                          <TableCell width="5%" style={{padding: "0px 8px"}}>ID</TableCell>
                          <TableCell width="13%" style={{padding: "0px 8px"}}>Segment Description</TableCell>
                          <TableCell width="11%" align="center">Segment Length (min)</TableCell>
                          <TableCell width="11%" align="center">Merge ID</TableCell>
                          <TableCell width="11%" align="center">DMF Factor</TableCell>
                          <TableCell width="11%" align="center">Constant Load Stress 1G (KSI)</TableCell>
                          <TableCell width="11%" align="center">Alternating Load Stress (KSI)</TableCell>
                          <TableCell width="11%" align="center">Pressure Load Stress (KSI)</TableCell>
                          <TableCell width="1%"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody component={DroppableComponent(onDragEnd)}>
                        {mission.segments.map((segment, segmentId) => {
                          if(segment.name === 'modeled-data' // keep for backwards compatibility
                          || segment.type === 'modeled') {
                            return (
                              <TableRow classes={{root: classes.modeledDataRow}} component={DraggableComponent(missionId, segmentId, status)} key={`${segment.name}-${missionId}-${segmentId}`} >
                                <TableCell width="1%" colSpan={9} style={{padding: "0px"}}>
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell width="5%" style={{padding: "0px 8px", border: "0px"}}>
                                          <Chip label="Modeled" color="primary" variant="outlined" />
                                        </TableCell>
                                        <TableCell width="13%" style={{padding: "0px 8px", border: "0px"}} align="right">
                                        </TableCell>
                                        <TableCell width="11%" style={{padding: "0px 8px", border: "0px"}} align="center">
                                        </TableCell>
                                        <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                          {status ? successField(segment, "mergeId", segmentId, missionId) : mergeField(segment, "mergeId", segmentId, missionId)}
                                        </TableCell>
                                        {Object.keys(segment.values).filter(key => ['dmf', 'constantLoad', 'alternatingLoad', 'pressureLoad'].includes(key)).map(function(key, index) {
                                          return(
                                            <TableCell width="11%" style={{padding: "0px 8px", border: "0px"}} align="center" key={index}>
                                              {status ? successField(segment, key, segmentId, missionId) : inputField(segment, key, segmentId, missionId)}
                                            </TableCell>
                                          )
                                        })}
                                        <TableCell width="1%" align={'right'} style={{border: "0px", padding: "0px"}}>
                                          <IconButton
                                            aria-label="settings"
                                            disabled={status}
                                            onClick={() => removeModeledData(segmentId)}
                                            size="large">
                                            {status ? <Icon/> : <DeleteForeverIcon />}
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  {(EnableNegativeStress || segment.enableNegativeStress) && canHaveNegativeStress(segment, props.config.occurrence_distributions) && (
                                    <Table>
                                      <TableBody>
                                        <TableRow>

                                          <TableCell width="5%" style={{border: "0px", padding: "0px 8px"}}>
                                          </TableCell>
                                          <TableCell width="13%" style={{border: "0px", padding: "0px 8px"}}>
                                          </TableCell>
                                          <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                          </TableCell>
                                          <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                          </TableCell>

                                          {Object.keys(segment.values).filter(key => ['dmf', 'constantLoad', 'alternatingLoad', 'pressureLoad'].includes(key)).map(function(key, index) {
                                            return(
                                              <TableCell width="11%" style={{padding: "0px 8px", border: "0px"}} align="center" key={index}>
                                                {!['pressureLoad'].includes(key) && (
                                                 status ? successField(segment, key, segmentId, missionId, true) : inputField(segment, key, segmentId, missionId, true)
                                                )}
                                              </TableCell>
                                            )
                                          })}

                                          <TableCell width="1%" align={'right'} style={{border: "0px", padding: "0px"}}>
                                            <IconButton disabled size="large">
                                              <Icon />
                                            </IconButton>
                                          </TableCell>

                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  )}
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell width="15%" style={{border: "0px", padding: "0px 0px 0px 50px"}}>
                                          {status ? successEquationField(segment, "equation", segmentId, missionId) : equationField(segment, "name", segmentId, missionId, props.config.occurrence_distributions)}
                                        </TableCell>
                                        <TableCell width="84%" align="left" style={{border: "0px", padding:"0px 8px"}}>
                                          <Typography style={{fontSize: "14px", fontWeight: "500", textAlign: 'center', marginTop: 10, padding: 0, backgroundColor: "#eeeeee"}}>Load Pairs</Typography>

                                          <TableContainer style={{marginBottom: 10}}>
                                            <Table size={'small'} style={{border: "1px solid #eeeeee"}}>
                                              <TableHead style={{backgroundColor: "#f5f5f5"}}>
                                                <TableRow>
                                                  <TableCell align="center" style={{padding: "0px 8px"}}>Min</TableCell>
                                                  <TableCell align="center" style={{padding: "0px 8px"}}>Max</TableCell>
                                                  <TableCell align="center" style={{padding: "0px 8px"}}>Cycles</TableCell>
                                                  <TableCell style={{padding: "0px 8px"}} align={'right'}>
                                                    <IconButton
                                                      style={{padding: "0px"}}
                                                      aria-label="settings"
                                                      disabled={status}
                                                      onClick={() => addModeledSegment(missionId, segmentId)}
                                                      size="large">
                                                      {status ? <Icon/> : <AddIcon />}
                                                    </IconButton>
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {segment.loadPairs.map((loadPair, loadPairId) => {
                                                  return (
                                                    <TableRow key={`loadPairs-${loadPairId}`}>
                                                      <TableCell width="33%" align="center" key={`loadPair-0`} style={{padding: "0px 16px 5px 16px", border: "0px"}}>
                                                        {status ? modeledDataSuccess(missionId, segmentId, loadPairId, 'min') : modeledDataInput(missionId, segmentId, loadPairId, 'min')}
                                                      </TableCell>
                                                      <TableCell width="33%" align="center" key={`loadPair-1`} style={{padding: "0px 16px 5px 16px", border: "0px"}}>
                                                        {status ? modeledDataSuccess(missionId, segmentId, loadPairId, 'max') : modeledDataInput(missionId, segmentId, loadPairId, 'max')}
                                                      </TableCell>
                                                      <TableCell width="33%" align="center" key={`loadPair-2`} style={{padding: "0px 16px 5px 16px", border: "0px"}}>
                                                        {status ? modeledDataSuccess(missionId, segmentId, loadPairId, 'cycles') : modeledDataInput(missionId, segmentId, loadPairId, 'cycles')}
                                                      </TableCell>
                                                      <TableCell width="1%" align={'right'} style={{padding: "0px 8px", border: "0px"}}>
                                                        <IconButton
                                                          style={{padding: "0px"}}
                                                          disabled={loadPairId === 0 || status}
                                                          aria-label="settings"
                                                          onClick={() => removeModeledDataPair(missionId, segmentId, loadPairId)}
                                                          size="large">
                                                          {status ? <Icon/> : <DeleteForeverIcon />}
                                                        </IconButton>
                                                      </TableCell>
                                                    </TableRow>
                                                  );
                                                })}

                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </TableCell>
                                        <TableCell width="1%" align={'right'} style={{border: "0px", padding: "0px"}}>
                                          <IconButton aria-label="settings" disabled size="large">
                                            <Icon/>
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableCell>
                              </TableRow>
                            );
                          } else if(segment.type === 'custom' || formData.length === 1) { // users can edit custom segments or existing segments when there is only 1 mission (otherwise changing the mission length messes up the distribution)
                            return (
                              <TableRow classes={{root: classes.modeledDataRow}} component={DraggableComponent(missionId, segmentId, status)} key={`${segment.name}-${missionId}-${segmentId}`} >
                                <TableCell width="1%" colSpan={9} style={{padding: "0px"}}>
                                  <Table>
                                    <TableBody>
                                      <TableRow>

                                        <TableCell width="5%" style={{border: "0px", padding: "0px 8px"}}>
                                          {status ? successNonValueField(segment, "id", segmentId, missionId) : idField(segment, "name", segmentId, missionId, props.config.occurrence_distributions)}
                                        </TableCell>
                                        <TableCell width="13%" style={{border: "0px", padding: "0px 8px"}}>
                                          <Typography variant={status ? 'caption' : 'body2'}>{segment.name}</Typography>
                                        </TableCell>
                                        <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                            {status ? successField(segment, "duration", segmentId, missionId) : durationField(segment, "duration", segmentId, missionId)}
                                        </TableCell>
                                        <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                          {status ? successField(segment, "mergeId", segmentId, missionId) : mergeField(segment, "mergeId", segmentId, missionId)}
                                        </TableCell>

                                        {Object.keys(segment.values).filter(key => ['dmf', 'constantLoad', 'alternatingLoad', 'pressureLoad'].includes(key)).map(function(key, index) {
                                          return(
                                            <TableCell width="11%" style={{padding: "0px 8px", border: "0px"}} align="center" key={index}>
                                              {status ? successField(segment, key, segmentId, missionId) : inputField(segment, key, segmentId, missionId)}
                                            </TableCell>
                                          )
                                        })}

                                        <TableCell width="1%" align={'right'} style={{border: "0px", padding: "0px"}}>
                                          <IconButton
                                            aria-label="settings"
                                            onClick={() => removeModeledData(segmentId)}
                                            disabled={status}
                                            size="large">
                                            {status ? <Icon/> : <DeleteForeverIcon />}
                                          </IconButton>
                                        </TableCell>

                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  {(EnableNegativeStress || segment.enableNegativeStress) && canHaveNegativeStress(segment, props.config.occurrence_distributions) && (
                                  <Table>
                                    <TableBody>
                                      <TableRow>

                                        <TableCell width="5%" style={{border: "0px", padding: "0px 8px"}}>
                                        </TableCell>
                                        <TableCell width="13%" style={{border: "0px", padding: "0px 8px"}}>
                                        </TableCell>
                                        <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                        </TableCell>
                                        <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                        </TableCell>

                                        {Object.keys(segment.values).filter(key => ['dmf', 'constantLoad', 'alternatingLoad', 'pressureLoad'].includes(key)).map(function(key, index) {
                                          return(
                                            <TableCell width="11%" style={{padding: "0px 8px", border: "0px"}} align="center" key={index}>
                                              {!['pressureLoad'].includes(key) && (
                                               status ? successField(segment, key, segmentId, missionId, true) : inputField(segment, key, segmentId, missionId, true)
                                              )}
                                            </TableCell>
                                          )
                                        })}

                                        <TableCell width="1%" align={'right'} style={{border: "0px", padding: "0px"}}>
                                          <IconButton disabled size="large">
                                            <Icon />
                                          </IconButton>
                                        </TableCell>

                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          } else {
                            return (
                              <TableRow component={DraggableComponent(missionId, segmentId, true)} hover key={segmentId}>
                                <TableCell width="1%" colSpan={9} style={{padding: "0px"}}>
                                  <Table>
                                    <TableBody>
                                      <TableRow>

                                        <TableCell width="5%" style={{border: "0px", padding: "0px 8px"}}>
                                          <Typography variant={status ? 'caption' : 'body2'}>{segment.id}</Typography>
                                        </TableCell>
                                        <TableCell width="13%" style={{border: "0px", padding: "0px 8px"}}>
                                          <Typography variant={status ? 'caption' : 'body2'}>{segment.name}</Typography>
                                        </TableCell>
                                        <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                          <Typography variant={'caption'}>{formData[missionId].segments[segmentId].duration}</Typography>
                                        </TableCell>
                                        <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                          <Typography variant={'caption'}>{formData[missionId].segments[segmentId].group && formData[missionId].segments[segmentId].group.type !== " " ? formData[missionId].segments[segmentId].group.id : ''}</Typography>
                                        </TableCell>
                                        {
                                          Object.keys(segment.values).filter(key => ['dmf', 'constantLoad', 'alternatingLoad', 'pressureLoad'].includes(key)).map(function(key, index) {
                                            return(
                                              <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center" key={index}>
                                                {status ? successField(segment, key, segmentId, missionId) : inputField(segment, key, segmentId, missionId)}
                                              </TableCell>
                                            )
                                          })
                                        }
                                        <TableCell width="1%" align={'right'} style={{border: "0px", padding: "0px"}}>
                                          <IconButton disabled size="large">
                                            <Icon />
                                          </IconButton>
                                        </TableCell>

                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  {(EnableNegativeStress || segment.enableNegativeStress) && canHaveNegativeStress(segment, props.config.occurrence_distributions) && (
                                  <Table>
                                    <TableBody>
                                      <TableRow>

                                        <TableCell width="5%" style={{border: "0px", padding: "0px 8px"}}>
                                        </TableCell>
                                        <TableCell width="13%" style={{border: "0px", padding: "0px 8px"}}>
                                        </TableCell>
                                        <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                        </TableCell>
                                        <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center">
                                        </TableCell>
                                        {
                                          Object.keys(segment.values).filter(key => ['dmf', 'constantLoad', 'alternatingLoad', 'pressureLoad'].includes(key)).map(function(key, index) {
                                            return(
                                              <TableCell width="11%" style={{border: "0px", padding: "0px 8px"}} align="center" key={index}>
                                                {!['pressureLoad'].includes(key) && (
                                                  status ? successField(segment, key, segmentId, missionId, true) : inputField(segment, key, segmentId, missionId, true)
                                                )}
                                              </TableCell>
                                            )
                                          })
                                        }
                                        <TableCell width="1%" align={'right'} style={{border: "0px", padding: "0px"}}>
                                          <IconButton disabled size="large">
                                            <Icon />
                                          </IconButton>
                                        </TableCell>

                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>

                    </Table>

                  </AccordionDetails>
                </Accordion>
              ))}

            </>
          )}
        </Card>
      )}
      {error.length > 0 && (
        <div className={classes.errorRoot}>
          <div className={classes.errorContainer}>
            <p className={classes.errorText}>{error[error.length - 1].message}</p>
          </div>
        </div>
      )}

    </div>
  );
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
})

const DraggableComponent = (missionId, segmentId, drag = false) => (props) => {
  return (
    <Draggable draggableId={`${missionId}-${segmentId}`} isDragDisabled={drag} index={segmentId}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  )
}


const DroppableComponent = (
  onDragEnd: (result, provided) => void) => (props) =>
{
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'1'}>
        {(provided) => {
          return (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}>
              {props.children}
              {provided.placeholder}
            </TableBody>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

export default ASPECForm_variant2;