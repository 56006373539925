import React from "react";
import { Card, CardContent, Typography, TextField, InputAdornment, makeStyles, Box, CardHeader, Grid, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import ASPECStyles from "../../../../../styles/jss/components/apps/ASPECStyles";
import ImportData from "../ImportData";

const useStyles = makeStyles(ASPECStyles);

const FuseBodyGeometry = (props) => {
  const status = props.status === "SUCCESSFUL";
  const { data, updateData } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    // e.preventDefault();
    const target = e.target;
    const name = e.target.name;
    const value = target.type === "checkbox" ? target.checked : parseFloat(target.value);
    console.log("handleChange: ", value, name);
    updateData(value, name);
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        title={"Options"}
        classes={{
          title: classes.cardHeaderTitle,
          root: classes.cardHeaderRoot,
        }}
        action={
          <div>
            <ImportData
              status={status}
              isRunning={props.isRunning}
              isSaving={props.isSaving}
              refresh={props.refresh}
              updateData={props.updateData}
              aircraft={props.aircraft}
              saveDraft={props.saveDraft}
            />
          </div>
        }
      />
      <CardContent>
        {(
          <div>
            <Grid container spacing={2}>
              <Grid item lg={2} md={3} sm={6}>
                <TextField
                  type="number"
                  margin="normal"
                  fullWidth
                  value={data.args.inboardWingStation}
                  name={"args.inboardWingStation"}
                  onChange={(e) => handleChange(e)}
                  label="Inboard wing station"
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <TextField
                  type="number"
                  margin="normal"
                  fullWidth
                  value={data.args.outboardWingStation}
                  name={"args.outboardWingStation"}
                  onChange={(e) => handleChange(e)}
                  label="Outboard wing station"
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={2} md={3} sm={6}>
                <TextField
                  type="number"
                  margin="normal"
                  fullWidth
                  disabled
                  value={data.data.cellData ? data.data.cellData.length + 1 : 1}
                  name={"args.cellCount"}
                  label="Number of cells"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                style={{padding: "0px 20px", width: "100px"}}
              >
                <Typography>Calculate:</Typography>
              </Box>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'args.calculate.shearCenter'}
                        checked={data.args.calculate.shearCenter}
                        onChange={e => handleChange(e)}
                        disabled={status}
                      />}
                    label="Shear center"
                    classes={{label: classes.selectLabels}}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'args.calculate.torsionalConst'}
                        checked={data.args.calculate.torsionalConst}
                        onChange={e => handleChange(e)}
                        disabled={status}
                      />}
                    label="Torsional const"
                    classes={{label: classes.selectLabels}}
                />
              </FormGroup>
            </Grid>
            <Grid container>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                style={{padding: "0px 20px", width: "100px"}}
              >
                <Typography>Print:</Typography>
              </Box>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'args.print.sectionProp'}
                        checked={data.args.print.sectionProp}
                        onChange={e => handleChange(e)}
                        disabled={status}
                      />}
                    label="Section Prop"
                    classes={{label: classes.selectLabels}}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'args.print.normalLoadsAndStress'}
                        checked={data.args.print.normalLoadsAndStress}
                        onChange={e => handleChange(e)}
                        disabled={status}
                      />}
                    label="Normal Loads and Stress"
                    classes={{label: classes.selectLabels}}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'args.print.staGeomMidBay'}
                        checked={data.args.print.staGeomMidBay}
                        onChange={e => handleChange(e)}
                        disabled={status}
                      />}
                    label="Sta Geom Mid-Bay"
                    classes={{label: classes.selectLabels}}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'args.print.axialLoads'}
                        checked={data.args.print.axialLoads}
                        onChange={e => handleChange(e)}
                        disabled={status}
                      />}
                    label="Axial Loads"
                    classes={{label: classes.selectLabels}}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'args.print.shearFlows'}
                        checked={data.args.print.shearFlows}
                        onChange={e => handleChange(e)}
                        disabled={status}
                      />}
                    label="Shear Flows"
                    classes={{label: classes.selectLabels}}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name={'args.print.checkAndBalances'}
                        checked={data.args.print.checkAndBalances}
                        onChange={e => handleChange(e)}
                        disabled={status}
                      />}
                    label="Check & Balances"
                    classes={{label: classes.selectLabels}}
                />
              </FormGroup>
            </Grid>

          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default FuseBodyGeometry;
