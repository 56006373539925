/*
New project component.
Props: Title, Description, Inputs, confirmAction
    Prop.ConfirmAction: function
 */

import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NewProjectDialogStyles from "../../styles/jss/components/common/NewProjectDialogStyles";
import { cfg } from "../../config";
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';
import { NotificationManager } from "react-notifications";
import withRouter from "../../helpers/withRouter";
import { useNavigate } from "react-router-dom";

const NewProjectDialog = (props) => {
  const navigate = useNavigate();

  const [inputValues, setInputValues] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let inputValues = {};
    for (let i = 0; i < props.inputs.length; i++) {
      inputValues[props.inputs[i].id] = "";
    }
    setInputValues(inputValues);
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const previousInputValues = inputValues;
    previousInputValues[name] = value;
    setInputValues(previousInputValues);
  };

  const createNewAnalysis = (pid) => {
    fetchAuthSession().then((session) => {
      axios
        .post(
          `${cfg.apiUrl}/app/${props.appApi}/analysis`,
          {
            projectId: pid,
          },
          {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            navigate(`/apps/${props.app}/analysis/${pid}/${resp.data.id}`);
          }
        });
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    const { updateData } = props;
    fetchAuthSession().then((session) => {
      setLoading(true);
      axios
        .post(
          `${cfg.apiUrl}/${props.postUrl}`,
          {
            ...inputValues,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: session.tokens?.idToken,
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            setLoading(false);
            props.setOpen(false);
            createNewAnalysis(resp.data.id);
            updateData();
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            if (err.response.status === 400) {
              NotificationManager.error(
                err.response.data.error.message,
                "Error"
              );
            } else {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            }
          } else if (err.request) {
            NotificationManager.error(
              "An error has occurred, please try again.",
              "Error"
            );
          } else {
            NotificationManager.error(
              "An error has occurred, please try again.",
              "Error"
            );
          }
        });
    });
  };

  const { title, description } = props;
  const { classes, handleClose } = props;

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialogContainer }}
    >
      <form onSubmit={(e) => submit(e)}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {description !== null && (
            <DialogContentText style={{ paddingBottom: '10px' }}>{description}</DialogContentText>
          )}

          {props.inputs.map((input, idx) =>
            input.type === "text" ? (
              <Box mb={3} key={idx}>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    type={input.type}
                    id={input.id}
                    autoFocus={idx === 0}
                    name={input.id}
                    fullWidth
                    required={input.required}
                    InputLabelProps={{ required: false }}
                    label={input.label + (input.required ? "* (required)" : "")}
                    value={props.inputs.id}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
              </Box>
            ) : (
              <FormControl fullWidth key={idx}>
                <InputLabel htmlFor={input.id}>
                  {input.label + (input.required ? "* (required)" : "")}
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  value={props.inputs.id}
                  id={input.id}
                  name={input.id}
                  required={input.required}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                  classes={{ select: classes.selectInput }}
                >
                  <option></option>
                  {input.options.map((option) => {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            )
          )}
        </DialogContent>
        <DialogActions classes={{ root: classes.buttonContainer }}>
          <Button disabled={loading} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            type={"submit"}
            color="primary"
            variant={"contained"}
          >
            Create Project
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

NewProjectDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  inputs: PropTypes.array,
  confirmAction: PropTypes.func,
  open: PropTypes.bool,
};

NewProjectDialog.defaultProps = {
  title: "Dialog Title",
  description: "The dialog description should describe what this project does.",
  inputs: [
    {
      type: "text",
      label: "Text Input",
      id: "text-input",
    },
    {
      type: "select",
      label: "Select Input",
      id: "option-select",
      options: [
        {
          value: "123",
          label: "Option 1",
        },
      ],
    },
  ],
  confirmAction: () => {
    console.log("Created New Project");
  },
  open: false,
};

export default withStyles(NewProjectDialogStyles, { withTheme: true })(
  withRouter(NewProjectDialog)
);
