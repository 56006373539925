const mock = {
    allMembers: [
        {
            first_name: 'Susan',
            last_name: 'Smith',
            email: 'Susan@airbus.com',
            joined: 1607791755000,
            type: ["Admin", "Billing"]
        },
        {
            first_name: 'Justin',
            last_name: 'Thomas',
            email: 'Justing@airbus.com',
            joined: 1607791755000,
            type: ["Team Member"]
        },
        {
            first_name: 'Tiger',
            last_name: 'Woods',
            email: 'Tiger@airbus.com',
            joined: 1607791755000,
            type: ["Team Member"]
        },
        {
            first_name: 'Joe',
            last_name: 'Doe',
            email: 'Joe@airbus.com',
            joined: 1607791755000,
            type: ["Team Member"]
        },
        {
            first_name: 'John',
            last_name: 'Smith',
            email: 'John@airbus.com',
            joined: 1607791755000,
            type: ["Team Member"]
        }
    ],
    admin: [
        {
            first_name: 'Susan',
            last_name: 'Smith',
            email: 'Susan@airbus.com',
            joined: 1607791755000,
            type: ["Admin", "Billing"]
        }
    ],
    team: [
        {
            first_name: 'Justin',
            last_name: 'Thomas',
            email: 'Justing@airbus.com',
            joined: 1607791755000,
            type: ["Team Member"]
        },
        {
            first_name: 'Tiger',
            last_name: 'Woods',
            email: 'Tiger@airbus.com',
            joined: 1607791755000,
            type: ["Team Member"]
        },
        {
            first_name: 'Joe',
            last_name: 'Doe',
            email: 'Joe@airbus.com',
            joined: 1607791755000,
            type: ["Team Member"]
        },
        {
            first_name: 'John',
            last_name: 'Smith',
            email: 'John@airbus.com',
            joined: 1607791755000,
            type: ["Team Member"]
        }
    ],
    deleted: [
        {
            first_name: 'Ahbad',
            last_name: 'Person',
            email: 'Ahbad@airbus.com',
            joined: 1607791755000,
            type: ["Deleted"]
        }
    ]
}

export default mock;