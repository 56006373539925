import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, FormControl} from '@mui/material';
//import CountrySelect from '../../data/country-select';
import StatesSelect from '../../data/states-select';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";
import { cfg } from '../../config';
import { fetchAuthSession } from 'aws-amplify/auth';
//import {NotificationManager} from 'react-notifications';


class EditCompanyProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      parsedData: [],
      open: false,
      loading: false,
      about: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      website: '',
      newName: '',
    }
    this.handleClose = this._handleClose.bind(this);
    this.handleOpen = this._handleOpen.bind(this);
    //this.updateInfo = this._updateInfo.bind(this);
  }

  _handleClose() {
    this.setState({ open: false });
  }

  _handleOpen() {
    this.setState({ open: true });
  }
/*
  _updateInfo() {
    this.setState({ newName: '' });
  }
*/
  componentDidMount() {
      this.fetchData();
    }

  fetchData = () => {
    fetchAuthSession()
        .then(session => {
            axios.get(`${cfg.apiUrl}/org`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': session.tokens?.idToken
                }
            })
                .then(resp => {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        data: resp.data,
                        parsedData: resp.data,
                        open: resp.data.length < 1,
                        about: resp.data.data.about,
                        address: resp.data.data.address,
                        city: resp.data.data.city,
                        state: resp.data.data.state,
                        zip: resp.data.data.code,
                        phone: resp.data.data.phone,
                        website: resp.data.data.website,
                    })
                })
                .catch(e => {
                    console.log(e)
                })
        })
}

  refreshData = () => {
    this.fetchData();
  };

  render() {

  const { parsedData, about, address, city, zip, phone, website } = this.state;

  return (
    <div>
      <IconButton size="large">
        <EditIcon onClick={this.handleOpen}></EditIcon>
      </IconButton>
      <Dialog maxWidth='md' open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-basic-info">Edit Company Profile</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <FormControl fullWidth>
          <Grid container spacing={1}>
            <Grid item xs={6} >
                <TextField autoFocus margin="normal" id="company-name" label="Company Name" defaultValue={parsedData.name} variant="filled" value={this.newName} fullWidth/>
            </Grid>
            <Grid item xs={6} >
                <TextField  margin="normal" id="website" label="Website" defaultValue={website} variant="filled" fullWidth/>
            </Grid>
            <Grid item xs={12} >
                <TextField  margin="normal" id="about" label="About Company" defaultValue={about} variant="filled" multiline rows={4} fullWidth/>
            </Grid>
            <Grid item xs={12} >
                <TextField margin="normal" id="street-address" label="Company Address" defaultValue={address} variant="filled" fullWidth/>
            </Grid>
            <Grid item xs={4} >
                <TextField  margin="normal" id="city" label="City" defaultValue={city} variant="filled" fullWidth/>
            </Grid>
            <Grid item xs={4} >
                <StatesSelect/>
            </Grid>
            <Grid item xs={4} >
                <TextField  margin="normal" id="zip-code" label="Zip Code" defaultValue={zip} variant="filled" fullWidth/>
            </Grid>
            <Grid item xs={4} >
                <TextField  type="phone" margin="normal" id="phone-number" label="Phone Number" defaultValue={phone} variant="filled" fullWidth/>
            </Grid>
            {/*<Grid item xs={4} >
                <CountrySelect/>
            </Grid>*/}
          </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleClose} color="primary" variant={'contained'}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
}

export default EditCompanyProfile;
