import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import * as _ from 'lodash';
import StationsTable from "./StationsTable";

// Icon Imports
import WarningIcon from "@material-ui/icons/Warning";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(ASPECStyles);

function isBlank(str) {
  return /^\s*$/.test(str); // test for blank string
}

const StationsSection = (props) => {

  const classes = useStyles();
  const [itter, setItter] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [formData, setFormData] = React.useState([]);
  const [error, setError] = React.useState([]);

  const tableUnlocked = props.status !== "SUCCESSFUL";

  // Update input variants when missionMix is changed.
  React.useEffect(  () => {
    setFormData(props.data);
    setLoading(false);
    setItter(i => i + 1);
    return function cleanup() {
      setFormData([]);
    }
  }, [props.data])

  const StyledGrid = withStyles((theme) => ({
    root: {
      '&&& .hideScrollbar::-webkit-scrollbar': {
        background: 'transparent' /* Chrome/Safari/Webkit */,
        width: 0,

      },
    },
  }))(Grid);

  const addRow = () => {
    props.addStation();
  }

  const deleteRows = () => {
    props.deleteLoadcase(props.selectedLoadcaseIndex)
    props.setSelectedLoadcaseIndex(null); // reset selected rows
  }

  // const getDeleteButtonText = () => {
  //   const length = selectedRows.length;
  //   if (length === 1) {
  //     return 'Delete 1 Flange';
  //   } else if (length > 1) {
  //     return 'Delete ' + length + ' Flanges';
  //   }
  //   return 'Delete Flange(s)';
  // }

  return(
    <div style={{height: "100%"}}>
      {props.missionMix === '' ? (
        <div>
          <Card>
            <CardContent className={classes.inputErrorCard}>
              <Typography className={classes.inputErrorCardContent}><WarningIcon color={'error'} /> Please select mission mix in the 'Predefined Data' section.</Typography>
            </CardContent>
          </Card>
        </div>
      ) : (
        <Card style={{height: "100%"}}>
          <CardHeader
            // title={'Stringers'}
            classes={{
              title: classes.cardHeaderTitle,
              root: classes.cardHeaderRoot,
              content: classes.cardHeaderContent,
              action: classes.cardHeaderAction
            }}
          />
          <StyledGrid container>
            <Grid item md={2} >
              <Box
                  className={useStyles.container}
                  borderBottom="1px solid #9E9E9E"
                  height={48}
                >
                <Typography variant="h5" style={{fontSize: '20px', padding: '4px 0 0 16px'}}>Stations</Typography>
              </Box>
            </Grid>
            <Grid item style={{flexGrow: "1"}}className={'root'} align="right">
              <Box
                className={useStyles.container}
                borderBottom="1px solid #9E9E9E"
                height={48}
              >
                <Button
                  disabled={!tableUnlocked || props.isRunning || props.isSaving}
                  color={'primary'}
                  startIcon={<AddIcon />}
                  onClick={addRow}
                >
                  Add Station
                </Button>
              </Box>
            </Grid>
            <Grid item md={12}>
                <StationsTable
                  data={formData}
                  status={props.status}
                  update={props.update}
                  selectedStationIndex={props.selectedStationIndex}
                  setSelectedStationIndex={props.setSelectedStationIndex}
                  selectedStation={props.selectedStation}
                  setSelectedStation={props.setSelectedStation}
                  setStationIdx={props.setStationIdx}
                  deleteStation={props.deleteStation}
                />
            </Grid>
          </StyledGrid>

        </Card>
      )}
      {error.length > 0 && (
        <div className={classes.errorRoot}>
          <div className={classes.errorContainer}>
            <p className={classes.errorText}>{error[error.length - 1].message}</p>
          </div>
        </div>
      )}

    </div>
  )
};

export default StationsSection;