import React from 'react';
import withRouter from '../../../../helpers/withRouter';
import {Box, Button, Card, Checkbox, IconButton, Typography, LinearProgress, Tooltip} from "@material-ui/core";
import DataTable from "react-data-table-component";
import ProjectTableHeader from "./ProjectTableHeader";
import moment from "moment";
import ProjectOptions from "./ProjectOptions";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withStyles} from "@material-ui/core/styles";
import ProjectStyles from "../../../../styles/jss/components/apps/ProjectStyles";
import {filterData, searchData, getAvailableOptions} from "../../../../helpers";
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from "axios";
import {cfg} from "../../../../config";
import {NotificationManager} from "react-notifications";
import {statusColor} from "../../../../helpers";
import InfoIcon from '@material-ui/icons/Info';
import { useNavigate, useParams } from 'react-router-dom';

const FuseProject = (props) => {
    const navigate = useNavigate();
    const params = useParams();

    const [name, setName] = React.useState('');
    const [aircraft, setAircraft] = React.useState('');
    const [data, setData] = React.useState([]);
    const [parsedData, setParsedData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
      fetchData()
    }, [])

    const fetchData = () => {
        return new Promise((resolve, reject) => {
            fetchAuthSession()
                .then(session => {
                    axios.get(`${cfg.apiUrl}/app/boxbeam/project/${params.id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': session.tokens?.idToken
                        }
                    })
                        .then(resp => {
                            if(resp.status === 200) {
                                setName(resp.data.name)
                                setAircraft(resp.data.aircraft)
                                setData(resp.data.analyses)
                                setParsedData(resp.data.analyses)
                                setLoading(false)
                                resolve()
                            } else {
                                NotificationManager.warning('Something went wrong, please check console for response.', 'WARNING');
                                reject(resp);
                            }
                        })
                        .catch(e => {
                            console.log(e)
                            if (e.response || e.request) {
                                NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                            } else {
                                NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                            }
                            reject(e);
                        })
                })
        })
        // axios logic for retrieving data from project with id of props.match.params.id

    }

    const getMinDate = (data) => {
        let minDate = moment().valueOf();
        for (let i = 0; i < data.length; i ++) {
            if (moment(data[i].runDate).valueOf() < minDate && data[i].runDate !== null) minDate = moment(data[i].runDate).valueOf()
        }
        return minDate
    }

    const filter = (filters) => {
        filterData(data, filters)
            .then(data => {
                setParsedData(data)
            })
    }

    const search = (term) => {
        searchData(data, term)
    }

    const refreshData = () => {
        console.log('...fetching updated data')
        fetchData();
    }

    const newAnalysis = () => {
        // axios request to create a new analysis with project ID
        fetchAuthSession()
            .then(session => {
                axios.post(`${cfg.apiUrl}/app/boxbeam/analysis`, {
                    "projectId": params.id
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': session.tokens?.idToken
                    }
                })
                    .then(resp => {
                        if(resp.status === 200) {

                            navigate(`/apps/wingbox/analysis/${params.id}/${resp.data.id}`);
                        } else {
                            NotificationManager.warning('Something went wrong, please check console for response.', 'WARNING');
                            console.log(resp)
                        }
                    })
                    .catch(e => {
                        console.log(e)
                    })
            })

    }


    const {classes, theme} = props;

    const columns = [
        {
            name: 'Analysis Name',
            selector: row => row['name'],
            // allowOverflow: false,
            cell: row => <Button classes={{label: classes.buttonLabel}} disableRipple disableElevation disableFocusRipple color={'primary'} variant={'text'} onClick={() => navigate(`/apps/wingbox/analysis/${params.id}/${row.id}`)}>
                <span className={classes.buttonText}>{row.name}</span>
            </Button>,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: true,
            cell: row => <span style={{color: statusColor(row.status, theme)}}>{row.status}</span>
        },
        {
            name: 'Run Date',
            selector: row => row['modified'],
            cell: row => <span>{row.runDate === null ? 'Not Completed' : moment(row.runDate).format('ll')}</span>,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => <ProjectOptions project={params.id} row={row} updateData={refreshData} />,
            // allowOverflow: true,
            right: "true",
        }
    ];
    return (
        <div>
            {loading ? (
                <div>
                    <LinearProgress />
                </div>
            ) : (
                <div>
                    <Box className={classes.headerContainer}>
                        <Typography variant={'h5'} className={classes.headerTitleContainer}>
                            <IconButton
                                disableFocusRipple
                                disableRipple
                                onClick={() => navigate('/apps/wingbox')}
                                size="large">
                                <ArrowBackIcon />
                            </IconButton> | {name}
                            <Tooltip title={`ID: ${params.id}`} placement={'right'}>
                                <InfoIcon style={{marginLeft: '10px'}} fontSize={'small'} color={'disabled'} />
                            </Tooltip>
                        </Typography>
                        <Button variant={'contained'} color={'primary'} onClick={() => newAnalysis()}>+ New Analysis</Button>
                    </Box>
                    <Card className={classes.tableContainer}>
                        <DataTable
                            style={{marginTop: '0px', height: '100%'}}
                            columns={columns}
                            data={parsedData}
                            striped
                            pagination
                            paginationPerPage={15}
                            noHeader
                            subHeader
                            subHeaderComponent={<ProjectTableHeader filter={filter} search={search} missionMixes={getAvailableOptions(data, 'args.missionMix')} minDate={getMinDate(data)} />}
                            selectableRowsComponent={Checkbox}
                        />
                    </Card>
                </div>

            )}
        </div>
    );

}

export default (withStyles(ProjectStyles, {withTheme: true})(withRouter(FuseProject)))