import React from 'react';
import {Typography, Card, CardContent, makeStyles, CardHeader} from '@material-ui/core';
import ASPECStyles from "../../../../../styles/jss/components/apps/ASPECStyles";

const useStyles = makeStyles(ASPECStyles);

const ActivityLog = (props) => {
    const classes = useStyles();
    const {data} = props;
    return(
        <Card style={{height: '100%'}}>
            <CardHeader
                title={'Activity Log'}
                classes={{
                    title: classes.cardHeaderTitle,
                    root: classes.cardHeaderRoot
                }}
            />
            <CardContent className={classes.cardBody}>
                {data.activityLog.map((item, idx) => (
                    <div key={idx}>
                        <Typography variant={'caption'}>{item.title}</Typography>
                        <Typography variant={'body1'}>{item.content}</Typography>
                    </div>
                ))}
            </CardContent>
        </Card>
    )
}

export default ActivityLog;