import React from "react";
import LinkCard from "../components/common/LinkCard";
import APIStatus from "../components/layout/status/APIStatus";
import { cfg } from "../config";
import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';

const Home = (props) => {
  const [state, setState] = React.useState({
    data: { billing: { active: false } },
  });

  React.useEffect(() => {
    fetchAuthSession().then((session) => {
      axios
        .get(`${cfg.apiUrl}/org`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: session.tokens?.idToken,
          },
        })
        .then((resp) => {
          console.log(resp);
          setState({
            ...state,
            isLoading: false,
            data: resp.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }).catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    <div>
        <div>
          <LinkCard
            history={props.history}
            link={"/apps"}
            title={"Applications"}
            subtitle={""}
            icon={"apps"}
          />
        </div>
    </div>
  );
};

export default Home;
