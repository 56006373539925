import * as React from 'react';
import TextField from '@material-ui/core/TextField';
// import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  Tooltip,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core/';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { withStyles } from '@material-ui/styles';

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
 const convertPixelsToRem = (unitValue) => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

const StationsTable = (props) => {
  const tableUnlocked = props.status !== "SUCCESSFUL";

  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
    },

  });

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  // used to setup styles for short text fields
  const ShortTextField = withStyles((theme) => ({
    root: {
      minWidth: 60,
      maxWidth: 85,
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);


  const handleTextfieldChange = async (e, flangeIdx, column, section, props) => {
    if (section !== '') {
      props.update(parseFloat(e.target.value), `data.stations[${flangeIdx}].${section}.${column}`);
    } else {
      props.update(parseFloat(e.target.value), `data.stations[${flangeIdx}].${column}`);
    }
  };

  const stationColumns = ['capArea', 'effFactor', 'x', 'z', 'py'];
  const panelColumns = ['del2A', 't', 'delS', 'geG'];
  const leftBorderColumns = ['flangeNo']

  const getStringerStrn = (idx) => {
    return (idx + 1) * 100
  }

  const getField = (flange, column, flangeIdx, section='') => {
    const value = getValue(flange, column, section)
    return (
      <ShortTextField
        name={column}
        defaultValue={value}
        error={value ? Boolean(value.error) : false}
        focused={value ? value.focus : false}
        type={'text'}
        variant="outlined"
        onBlur={(e) => handleTextfieldChange(e, flangeIdx, column, section, props)}
      />
    );

  };

  const getValue = (flange, column, section='', digits=6) => {
    let val;
    if (section === 'inboard') {
      val = flange.inboard[column];
    } else if (section === 'outboard') {
      val = flange.outboard[column];
    } else if (section === 'panel') {
      val = flange.panel[column];
    } else {
      val = flange[column];
    }
    return Math.round((val + Number.EPSILON) * 10**digits) / 10**digits // round to x digits
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked && props.data && props.data.stations) {
  //     const newSelecteds = props.data.stations.map((_, i) => i);
  //     props.setSelectedStationIndex(newSelecteds);
  //     return;
  //   }
  //   props.setSelectedStationIndex([]);
  // };

  const handleClickSelect = (event, station, stationIdx) => {
    // console.log("handleClickSelect=" + JSON.stringify(station))
    props.setSelectedStationIndex(station.id);
    props.setSelectedStation(station);
    props.setStationIdx(stationIdx);
  };

  const handleClickDelete = (event, station, stationIdx) => {
    // console.log("handleClickDelete=" + JSON.stringify(station))
    // props.setSelectedStationIndex(null);
    // props.setSelectedStation(null);
    // props.setStationIdx(null);

    props.deleteStation(selectedStationIdx);
  };

  const isSelected = (id) => props.selectedStationIndex === id;

  const rowCount = () =>
    props.data && props.data.stations ? props.data.stations.length : 0;

  // Delete dialog logic
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const[selectedStation, setSelectedStation] = React.useState(null);
  const[selectedStationIdx, setSelectedStationIdx] = React.useState(null);

  const openDeleteDialog = (event, station, stationIdx) => {
    setSelectedStation(station);
    setSelectedStationIdx(stationIdx);
    setDeleteDialog(true);

    // setAnchorEl(null);
    event.stopPropagation();
  };
  const closeDeleteDialog = () => setDeleteDialog(false);

  const sendDelete = () => {
    props.deleteStation(selectedStationIdx);
    closeDeleteDialog();
  };

  // overflowX: 'unset' to avoid small vertical scroll issue (space after the last row)
  return (
    <TableContainer className="hideScrollbar" style={{overflowX: 'unset'}}>
      <Dialog
        open={deleteDialog}
        onClose={closeDeleteDialog}
        aria-labelledby="form-dialog-title"
        // classes={{ paper: classes.dialogContainer }}
      >
        <DialogTitle id="form-dialog-title">Delete Station</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to delete station <b>{selectedStation ? selectedStation.id : ''}</b>? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{}}>
          <Button onClick={() => closeDeleteDialog()}>
            Cancel
          </Button>
          <Button onClick={() => sendDelete()} color="primary" variant={'contained'}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} padding="checkbox" align='center' style={{padding: 4}}>
              ID
            </TableCell>
            {tableUnlocked && (
              <TableCell
                style={{
                  width: "10%",
                }}
              />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.stations
            ? props.data.stations.map((station, stationIdx) => {
                const isItemSelected = isSelected(station.id);

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`station-${stationIdx}`}
                    selected={isItemSelected}
                    onClick={(event) => handleClickSelect(event, station, stationIdx)}
                  >
                    <TableCell
                      className={useStyles.tableRightBorder}
                      align='center'
                      key='flangeNo'
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingRight: 0,
                        paddingLeft: 0,
                        // width: "6.66%",
                        borderRight: 1,
                        borderRightStyle: "solid",
                        borderRightColor: "rgba(224, 224, 224, 1)",
                      }}
                    >
                      {station.id}
                    </TableCell>
                    {tableUnlocked && (
                      <TableCell padding="checkbox" align='center'>
                        <Tooltip title={"Delete station"}>
                          <Checkbox
                            color="primary"
                            // size="normal"
                            style={{ padding: 0 }}
                            // checked={isItemSelected}
                            icon={(<DeleteOutlinedIcon/>)}
                            onClick={(event) => openDeleteDialog(event, station, stationIdx)}
                          />
                        </Tooltip>
                      </TableCell>
                    )}
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StationsTable;
