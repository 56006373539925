import React from 'react';
import {makeStyles, Grid, Button, Typography, IconButton} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import withRouter from '../../../../helpers/withRouter';
import { downloadData } from 'aws-amplify/storage';
import { fetchUserAttributes  } from 'aws-amplify/auth';
import {downloadBlob} from "../../../../helpers";
import AnalysisOptions from "./AnalysisOptions";
import { useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = makeStyles(ASPECStyles);

const BoxbeamHeader = (props) => {

    const {data} = props;

    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    const status = props.status === "SUCCESSFUL";

    const {paste, saveDraft, submit} = props.functions;

    const [isDownloading, setIsDownloading] = React.useState(false);

    const handleRun = async (e) => {
      e.preventDefault();
      await submit();
    }

    const handleSave = async (e) => {
      e.preventDefault();
      await saveDraft();
    }

    const downloadAllFiles = () => {
        setIsDownloading(true);
        fetchUserAttributes ()
            .then(attributes => {
                console.log(attributes)
                for(let i = 0; i < data.results.output.length; i++) {
                    downloadData({key: `private/org/${attributes['custom:org']}/project/${data.results.output[i]}`}).result
                        .then(resp => {
                            let stringSplit = data.results.output[i].split('/');
                            downloadBlob(resp.body, stringSplit[stringSplit.length - 1])
                        })
                        .catch(e => {console.log(e)})
                        .finally(e => {setIsDownloading(false);})
                }
            })

    }

    return (
        <div>
            <Grid container spacing={3} alignItems={'center'} style={{marginBottom: '10px'}}>
                <Grid item md={3}>
                    <Typography variant={'h5'} className={classes.headerTitleContainer}>
                        <IconButton
                            disableFocusRipple
                            disableRipple
                            onClick={() => navigate('/apps/ringframe/project/' + params.projectId)}
                            size="large">
                            <ArrowBackIcon />
                        </IconButton> Analysis Details
                    </Typography>
                </Grid>
                <Grid item md={9}>
                    <div className={classes.headerRightContent}>
                        {status ? (
                            <>
                                <div style={{display: 'flex'}}>
                                    <LoadingButton
                                      style={{marginRight: 10}}
                                      color={'primary'}
                                      variant={'contained'}
                                      onClick={() => downloadAllFiles()}
                                      loading={isDownloading}
                                      loadingPosition="start"
                                      startIcon={<DownloadIcon />}
                                    >
                                        Download Files
                                      </LoadingButton>
                                    <AnalysisOptions data={props.data} status={status} />
                                </div>
                            </>
                        ) : (
                            <>
                                <LoadingButton
                                  variant="outlined"
                                  style={{ marginRight: 10 }}
                                  disabled={
                                    // !nodePath.current ||
                                    // JSON.stringify(nodeData) === JSON.stringify(savedNodeData.current) ||
                                    props.isRunning
                                  }
                                  onClick={handleSave}
                                  loading={props.isSaving}
                                  loadingPosition="start"
                                  startIcon={<SaveIcon />}
                                >
                                  {'SAVE'}
                                </LoadingButton>
                                <Button variant={'contained'} color={'primary'} disabled={props.isSaving} onClick={(e) => handleRun(e)}>Run Analysis</Button>
                                <AnalysisOptions data={props.data} status={status} paste={paste} />
                            </>
                        )}



                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default withRouter(BoxbeamHeader);