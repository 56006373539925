import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import WingspanHeader from "./WingspanHeader";
import withRouter from "../../../../helpers/withRouter";
import AnalysisDetails from "./LeftMenuItems/AnalysisDetails";
import { NotificationManager } from "react-notifications";
import WingspanBodyGeometry from "./LeftMenuItems/WingspanBodyGeometry";
import { setNestedValue, checkForData } from "../../../../helpers";
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import ContentLoading from "../../../common/ContentLoading";
import ActivityLog from "./LeftMenuItems/ActivityLog";
import { fetchAuthSession } from 'aws-amplify/auth';
import { cfg } from "../../../../config";
import axios from "axios";
import moment from "moment";
import * as _ from 'lodash';
import StationsSection from "./StationsSection";
import StationSection from "./StationSection";
import { useParams } from "react-router-dom";

const FuseAnalysis = (props) => {
  const params = useParams();

  const [data, setData] = React.useState({});
  const [lock, setLock] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRunning, setIsRunning] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [project, setProject] = React.useState({
    projectName: "",
    aircraft: "",
    projectID: 1,
    refs: [],
  });

  const [selectedStationIndex, setSelectedStationIndex] = React.useState(null);
  const [selectedStation, setSelectedStation] = React.useState(null);
  const [stationIdx, setStationIdx] = React.useState(0);

  // const [selectedLoadcaseIndex, setSelectedLoadcaseIndex] = React.useState(null);
  // const [selectedLoadcase, setSelectedLoadcase] = React.useState(null);
  // const [loadcaseIdx, setLoadcaseIdx] = React.useState(0);

  React.useEffect(() => {
    fetchData()
      .then(() => setIsLoading(false))
      .catch((e) => console.log(e));
  }, []);

  React.useEffect(() => {
    console.log("selectedStation=")
    console.log(setSelectedStation)
  }, [selectedStation]);


  const saveDraft = async (auto = false) => {
    return new Promise((resolve, reject) => {
      setIsSaving(true);

      let newData = { ...data };

      // console.log(newData)

      if (newData.name === lock.name) {
        delete newData.name;
      }
      delete newData.activityLog;
      delete newData.files;

      fetchAuthSession().then((session) => {
        axios
          .patch(
            `${cfg.apiUrl}/app/wingspan/analysis/${params.projectId}/${params.id}`,
            {
              ...newData,
            },
            {
              headers: {
                Authorization: session.tokens?.idToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((resp) => {
            let tempLock = { ...lock };
            tempLock.name = data.name;
            setLock(tempLock);

            if (resp.status === 200) {
              console.log("Draft Saved.");
              NotificationManager.success(`Data saved succesfully`, 'SUCCESS');
              resolve();
            } else {
              NotificationManager.error(
                "Something went wrong when fetching data. Please check console for more info.",
                "ERROR"
              );
              reject();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 400) {
                NotificationManager.error(
                  err.response.data.error.message,
                  "Error"
                );
              } else {
                NotificationManager.error(
                  "An error has occurred, please try again.",
                  "Error"
                );
              }
            } else if (err.request) {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            } else {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            }
            reject(err);
          })
          .finally(() => {
            setIsSaving(false);
          });
      });
    });
  };


  const fetchData = async () => {
    // Axios logic for fetching data pertaining to analysis ID
    return new Promise((resolve, reject) => {
      fetchAuthSession().then((session) => {
        axios
          .get(`${cfg.apiUrl}/app/wingspan/analysis/${params.projectId}/${params.id}`, {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          })
          .then(async (resp) => {
            if (resp.status === 200) {
              const newData = {
                name: resp.data.name,
                args: {
                  analysis: checkForData(resp, "data.args.analysis", 1),
                  skinEffectivity: checkForData(resp, "data.args.skinEffectivity", 2),

                  // loadedRing: checkForData(resp, "data.args.loadedRing", ""),
                  // adjacentRing: checkForData(resp, "data.args.adjacentRing", ""),
                  // legacyMode : checkForData(resp, "data.args.legacyMode", false),

                  // shellRadius: checkForData(resp, "data.args.shellRadius", 0),
                  // ringRadius: checkForData(resp, "data.args.ringRadius", 0),
                  // shellThickness: checkForData(resp, "data.args.shellThickness", 0),
                  // ringMomentInertia: checkForData(resp, "data.args.ringMomentInertia", 0),
                  // equivalentShellThickness: checkForData(resp, "data.args.equivalentShellThickness", 0),

                  // modulusElasticity: checkForData(resp, "data.args.modulusElasticity", 0),
                  // ringSpacing: checkForData(resp, "data.args.ringSpacing", 0),
                  // shearModulus: checkForData(resp, "data.args.shearModulus", 0),
                  // structuralParamA: checkForData(resp, "data.args.structuralParamA", 0),
                  // structuralParamB: checkForData(resp, "data.args.structuralParamB", 0),
                },
                data: checkForData(resp, "data.data",
                  {
                    // nextLoadcaseIndex: 1,
                    stations: []
                  }
                ),
                activityLog: [
                  {
                    title: "Date Created",
                    content: resp.data.created,
                  },
                  {
                    title: "Last Update",
                    content: resp.data.modified,
                  },
                ],
                results: checkForData(resp, "data.results", {}),
                status: resp.data.status,
                runDate: resp.data.runDate ? moment(resp.data.runDate).format("ll") : "",
              };

              setProject(resp.data.project);
              console.log(newData)
              setData({...newData});
              setLock({...newData}); // needed to avoid "name already exist" error when saving
              // await sleep(1000)
              // Reset selected loadcase
              setSelectedStationIndex (null);
              setSelectedStation(null);
              setStationIdx(null);

              resolve();
            } else {
              reject();
              NotificationManager.error(
                "Something went wrong when fetching data. Please check console for more info.",
                "ERROR"
              );
            }
          })
          .catch((e) => {
            console.log(e);
            NotificationManager.error(
              "Something went wrong when fetching data. Please check console for more info.",
              "ERROR"
            );
            reject(e);
          });
      });
    });
  };

  /*
    Updates the current state.data.
     */
  const updateData = (value, objectLocation, forceUpdate = false) => {
    console.log("updateData " + objectLocation)
    if (objectLocation.endsWith(".id")) { // updating the station id
      // Make sure that a station with the new ID doesn't exist
      const existingStationIds = data.data.stations.map(station => station.id);
      console.log("existingStationIds=")
      console.log(existingStationIds)
      if (existingStationIds.includes(value)) {
        setData({ ...data });
        return; // skip update if a loadcase with the new ID already exists
      }
      // setSelec
    }
    let newData = _.set({ ...data }, objectLocation, value) //setNestedValue({ ...data }, objectLocation, value);
    setData(newData);
  };

  const stationIdAlreadyTaken = (value) => {
    // Make sure that a loadcase with the new ID doesn't exist
    const existingStationIds = data.data.stations.map(loadcase => loadcase.id);
    console.log("existingStationIds=")
    console.log(existingStationIds)
    return existingStationIds.includes(value);
  }

  const runAnalysis = async () => {
    setIsLoading(true);
    setIsRunning(true);

    await saveDraft(true);

    // console.log("... sleeping for 1 second");
    // await sleep(1000); // temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)

    fetchAuthSession().then((session) => {
      axios
        .post(
          `${cfg.apiUrl}/app/wingspan/analyze`,
          {
            id: params.id,
            projectId: params.projectId,
          },
          {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (resp) => {
          if (resp.status === 200) {
            await fetchData();
            NotificationManager.success(`Analysis run succesfully`, 'SUCCESS');
            setIsLoading(false);
          } else {
            NotificationManager.warning(
              "Something went wrong, please check the console.",
              "WARNING"
            );
          }
        })
        .catch(async (e) => {
          if (e.response?.data?.error?.message) {
            NotificationManager.error(
              e.response.data.error.message,
              "ERROR"
            );
          } else {
            NotificationManager.error(
              "Something went wrong, please see console for details.",
              "ERROR"
            );
          }
          console.log(e);
          await fetchData();
          setIsLoading(false);
        })
        .finally(async (resp) => {
          setIsRunning(false);
        });
    });
  };

  const pasteAnalysis = async () => {
    const copied = JSON.parse(window.sessionStorage.getItem("copyProject"));
    if (!copied) return;
    if (copied.args.aircraft === data.args.aircraft) {
      let name = data.name;
      copied.name = name;
      setData(copied);
      setLock(copied);
      window.sessionStorage.removeItem("copyProject");
    } else {
      NotificationManager.warning(
        "Cannot copy analysis. MissionMix/Aircraft mismatch.",
        "FAILED"
      );
    }
  };

  // Stringers

  const getNextStationIndex = () => {
    const existingIndexes = data.data.stations.map(l => l.id);
    console.log("existingIndexes")
    console.log(existingIndexes)
    let id = 1;
    while (existingIndexes.includes(id)) {
      id += 1;
    }
    console.log(id)
    return id;
  }

  const getMaxLoadcaseCount = () => {
    const loadcaseCounts = data.data.stations.map(station => station.loadcases.length);
    return Math.max(loadcaseCounts);
  }

  const addStation = async () => {

    // Having more than 1 station requires a maximum of 1 loadcase per station
    console.log("data.data.stations =" + data.data.stations.length )
    if (data.data.stations.length > 0) {
      const maxLoadcaseCount = getMaxLoadcaseCount();
      console.log("maxLoadcaseCount=" + maxLoadcaseCount)
      if (maxLoadcaseCount > 1) {
        NotificationManager.error(
          "Having more than 1 station is only supported with 1 loadcase per station. Please remove extra loadcases.",
          "ERROR"
        );
        return;
      }
    }

    let newRow = {
      id: getNextStationIndex(),
      segments: [],
      loadcases: []
    };
    let newData = { ...data };
    newData.data.stations.push(newRow);
    // newData.data.nextLoadcaseIndex += 1;
    setData(newData);
    console.log(newData)

  };

  const deleteStation = async (stationIndex) => {
    console.log("deleteStation = " + stationIndex)
    let newData = { ...data };
    newData.data.stations = newData.data.stations.filter((station, id) => {
      return id !== stationIndex;
    });
    setData(newData);

    // Reset selected station
    setSelectedStationIndex(null);
    setSelectedStation(null);
    setStationIdx(null);

  }

  // const addLoadcase = async () => {
  //   let station = {
  //     'capArea': 0,
  //     'effFactor': 0,
  //     'x': 0,
  //     'z': 0,
  //     'py': 0
  //   }
  //   let panel = {
  //     'del2A': 0,
  //     't': 0,
  //     'delS': 0,
  //     'geG': 0
  //   }
  //   let newRow = {
  //     index: getNextStationIndex(),
  //     appliedLoadPointCount: 0,
  //     limitedOutput: false,
  //     LO1: 0,
  //     LO2: 0,
  //     LO3: 0,
  //     LO4: 0,
  //     LO5: 0,
  //     LO6: 0,
  //     LAD1: 0,
  //     LAD2: 0,
  //     LAD3: 0,
  //     LAD4: 0,
  //     LAD5: 0,
  //     LAD6: 0,

  //     loads: [],
  //   };
  //   let newData = { ...data };
  //   newData.data.loadcases.push(newRow);
  //   // newData.data.nextLoadcaseIndex += 1;
  //   setData(newData);
  //   console.log(newData)

  // };

  // Segments

  const addSegment = async () => {
    console.log("Current addSegment=" + selectedStationIndex)
    let segment = {
      'sn': 0,
      'z': 0,
      'x': 0,
      'skin_t': 0,
      'skw': 0,
      'stfa': 0,
      'zbar_st': 0,
      'el_path': 0,
      'ckb_shear': 0,
      'comp_all': 0,
      'shr_all': 0,
      'tens_all': 0,
      'k_test': 0,
      'tcr_sk': 0,
      'seg_area': 0,
      'skin_area': 0,
      'delta_z': 0,
      'tan_theta': 0,
      'tan_phi': 0,
      'delta_skin_area': 0
    }
    let newRow = segment
    let newData = { ...data };
    let currentStation = newData.data.stations.filter(station => station.id === selectedStationIndex)[0];
    console.log(currentStation)
    currentStation.segments.push(newRow);
    setData(newData);
  }

  const deleteSegment = async (stationIndex) => {
    console.log("deleteSegment = " + stationIndex)
    let newData = { ...data };
    newData.data.stations = newData.data.stations.filter((station, id) => {
      return id !== stationIndex;
    });
    setData(newData);

    // Reset selected loadcase
    setSelectedStationIndex(null);
    setSelectedStation(null);
    setStationIdx(null);

  }

  const deleteSegments = async (rowIndexes) => {
    let newData = { ...data };
    let currentStation = newData.data.stations.filter(station => station.id === selectedStationIndex)[0];
    console.log("deleteSegments")
    console.log(currentStation.segments)
    currentStation.segments = currentStation.segments.filter((_, id) => {
      return !rowIndexes.includes(id);
    });
    console.log(currentStation.segments)
    setData(newData);
  }

  // Loadcases

  const addLoadcase = async () => {

    let newData = { ...data };
    let currentStation = newData.data.stations.filter(station => station.id === selectedStationIndex)[0];

    // Having more than 1 loadcase is only supported for a single station
    console.log("currentStation.loadcases =" + currentStation.loadcases.length )
    if (data.data.stations.length > 1 && currentStation.loadcases.length > 0) {
      // const maxLoadcaseCount = getMaxLoadcaseCount();
      // console.log("maxLoadcaseCount=" + maxLoadcaseCount)
      // if (maxLoadcaseCount > 1) {
        NotificationManager.error(
          "Having more than 1 loadcase is only supported with 1 single station. Please remove extra stations.",
          "ERROR"
        );
        return;
      // }
    }

    // Loadcase id = use incremental integer for single station - use the same id as the 1st loadcase of the 1st station for multiple stations
    const loadcaseId = data.data.stations.length === 1 ? currentStation.loadcases.length : data.data.stations[0].loadcases[0].id;

    console.log("Current addLoadcase=" + selectedStationIndex)
    let loadcase = {
      'id': loadcaseId,
      'x_lra': 0,
      'z_lra': 0,
      'x_ctr': 0,
      'z_ctr': 0,
      'vx': 0,
      'vz1': 0,
      'vz2': 0,
      'mx1': 0,
      'mx2': 0,
      'mz': 0,
      't_lra': 0,
      'kb1': 0,
      'kb2': 0,
      'x': 0,
      'y': 0,
      'z': 0,
      'vy': 0,
      'vz': 0,
      'mx': 0
    }
    let newRow = loadcase

    console.log(currentStation)
    currentStation.loadcases.push(newRow);
    setData(newData);
  };

  const deleteLoadcase = async (loadcaseIndex) => {
    console.log("deleteLoadcase = " + loadcaseIndex)
    let newData = { ...data };
    newData.data.stations = newData.data.stations.filter((station, id) => {
      return id !== loadcaseIndex;
    });
    setData(newData);

    // Reset selected loadcase
    setSelectedStationIndex(null);
    setSelectedStation(null);
    setStationIdx(null);

  }

  const deleteLoadcases = async (rowIndexes) => {
    let newData = { ...data };
    let currentLoadcase = newData.data.stations.filter(station => station.id === selectedStationIndex)[0];
    console.log("deleteLoadcases")
    console.log(currentLoadcase.loadcases)
    currentLoadcase.loadcases = currentLoadcase.loadcases.filter((_, id) => {
      return !rowIndexes.includes(id);
    });
    console.log(currentLoadcase.loadcases)
    setData(newData);
  }

  return !isLoading ? (
    <div>
      <WingspanHeader
        projectID={project.projectID}
        data={data}
        status={data.status}
        isSaving={isSaving}
        isRunning={isRunning}
        functions={{
          updateData: updateData,
          saveDraft: saveDraft,
          submit: runAnalysis,
          paste: pasteAnalysis,
        }}
      />
      <Grid container spacing={2} style={{ paddingBottom: "8px" }}>
        <Grid item md={7}>
          <AnalysisDetails
            refs={project.refs}
            name={data.name}
            updateData={updateData}
            project={project}
            run={data.runDate}
            status={data.status}
          />
        </Grid>
        <Grid item md={5}>
          <ActivityLog data={data} />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <WingspanBodyGeometry
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            data={data}
            updateData={updateData}
            saveDraft={saveDraft}
            refresh={fetchData}
            aircraft={project.aircraft?.toUpperCase() ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: "18px" }} alignItems="stretch">
        <Grid item md={2} xs >
          <StationsSection
            refresh={fetchData}
            data={data.data}
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            update={updateData}
            saveDraft={saveDraft}
            tableUnlocked={true}
            addLoadcase={addLoadcase}
            addStation={addStation}
            deleteStation={deleteStation}
            deleteLoadcase={deleteLoadcase}
            selectedStationIndex={selectedStationIndex}
            setSelectedStationIndex={setSelectedStationIndex}
            selectedStation={selectedStation}
            setSelectedStation={setSelectedStation}
            setStationIdx={setStationIdx}
          />
        </Grid>
        <Grid item md={10} xs>
          <StationSection
            refresh={fetchData}
            data={data.data}
            analysis={data.args.analysis}
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            update={updateData}
            saveDraft={saveDraft}
            tableUnlocked={true}
            addSegment={addSegment}
            deleteSegments ={deleteSegments}
            addLoadcase={addLoadcase}
            deleteLoadcase={deleteLoadcase}
            deleteLoadcases={deleteLoadcases}
            selectedStationIndex={selectedStationIndex}
            setSelectedStationIndex={setSelectedStationIndex}
            selectedStation={selectedStation}
            setSelectedStation={setSelectedStation}
            stationIdx={stationIdx}
            setStationIdx={setStationIdx}
            stationIdAlreadyTaken={stationIdAlreadyTaken}
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <div>
      <ContentLoading />
    </div>
  );
};

// Temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default withStyles(ASPECStyles, { withTheme: true })(
  withRouter(FuseAnalysis)
);
