/*
TODO:
    1. Link fetchData to DynamoDB user data
    2. Create 'Invite User' dialog
    3. Create 'editUser' function
    4. Create 'deleteUser' function
 */

import React from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api';
import mock from "../data/mock-users";
import {Grid} from "@mui/material";
import {searchData} from "../helpers";
import MemberList from "../components/members/MemberList";
import MemberMenu from "../components/members/MemberMenu";
import MemberHeader from "../components/members/MemberHeader";

class Members extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            selectedMembers: [],
            searchedMembers: []
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        // Axios get_users request
        // Two options for user list:
        //  1. Store users in DynamoDB and get users that way (then we can store user details)
        //  2. Get users from cognito admin actions found below:
        // https://docs.amplify.aws/cli/auth/admin#enable-admin-queries

        this.setState({
            ...this.state,
            members: mock,
            selectedMembers: mock.allMembers,
            searchedMembers: []
        })
    }

    removeUser = async ( username ) => {

        // Amplify Admin action to remove and disable user.

        let headers = {
            'Content-Type' : 'application/json',
            Authorization: `${(await fetchAuthSession()).getAccessToken().getJwtToken()}`
        }

        try {
            await post('AdminQueries', '/disableUser', {
                body: { "username": username },
                headers
            });
            await post('AdminQueries', '/addUserToGroup', {
                body: { "username": username, "groupname": "Removed"},
                headers
            })
        } catch (e) {
            console.log(e)
        }
    }

    changeMemberList = (type) => {
        this.setState({
            ...this.state,
            selectedMembers: this.state.members[type],
            searchedMembers: []
        })
    }

    filter = async (term) => {
        let newData = searchData(this.state.selectedMembers, term);
        this.setState({
            ...this.state,
            searchedMembers: newData
        })
    }

    render() {
        const {selectedMembers, searchedMembers} = this.state;
        return(
            <div>
                {/*Remove when cognito groups is set up*/}

                <div style={{color: '#530000', marginBottom: '30px'}}>Members page will only be available for Admins. Admins can add, edit, and delete users.</div>

                <Grid container spacing={3}>
                    <Grid item md={3}>
                        <MemberMenu members={this.state.members} changeMembers={this.changeMemberList} />
                    </Grid>
                    <Grid item md={9}>
                        <MemberHeader members={this.state.selectedMembers} filterData={this.filter} />
                        <MemberList members={searchedMembers.length > 0 ? searchedMembers : selectedMembers} />
                    </Grid>
                </Grid>
            </div>
        )
    }

}

export default Members;