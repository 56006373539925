const MissionMix = (state = [], action) => {
    switch(action.type) {
        case "SET_MISSION_MIX":
            return {
                ...state,
                mixes: action.payload
            };
        case "REMOVE_MISSION_MIX":
            return {
                ...state,
                mixes: []
            }
        default:
            return state
    }
};

export default MissionMix;