/*
NOTES:

Should be done... too many request errors. Need to test later.
 */

import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import { useNavigate  } from "react-router-dom";
import withRouter from '../helpers/withRouter';
import {
    Box,
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
    makeStyles
} from "@material-ui/core";
import { fetchAuthSession } from 'aws-amplify/auth';
import LockIcon from "@material-ui/icons/Lock";
import CodeIcon from "@material-ui/icons/Code";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    rootContainer: {
        position: 'relative',
        marginTop: -theme.appBar.height,
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    boxContainer: {
        minWidth: '400px',
    },
    loadingBox: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'row'
    }
}))

const ForgotPassword = (props) => {

    const navigate = useNavigate();

    const [username, setUsername] = React.useState('');
    const [code, setCode] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [codeSent, setCodeSent] = React.useState(true);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        getUserInfo();
    }, [])

    const getUserInfo = async () => {
        // Auth.currentAuthenticatedUser()
        //     .then(resp => {
        //         if(resp.username) setUsername(resp.username)
        //         return Auth.forgotPassword(resp.username)
        //     })
        //     .then(resp => {
        //         setCodeSent(true);
        //         NotificationManager.success('Please check your email for code.', 'Success')
        //         console.log(resp)
        //     })
        //     .catch(err => {
        //         if (err.code === "TooManyRequestsException" || err.code === "LimitExceededException") {
        //             NotificationManager.warning('You have submitted too many request. Please try again later.', 'Error');
        //             setError('You have submitted too many request. Please try again later.')
        //         } else {
        //             NotificationManager.warning('An error has occurred. Please try again later.', 'Error');
        //             setError('An error has occurred. Please try again later.')
        //             console.error(err);
        //         }
        //     })
    }

    const submitReset = async () => {
        // try {
        //     await Auth.forgotPasswordSubmit(username, code, newPassword);
        //     navigate('/company_profile');
        // } catch (err) {
        //     if (err.code === "UserNotFoundException") {
        //         // The error happens when the supplied username/email does not exist in the Cognito user pool
        //         setError("Failed to find user");
        //     } else if (err.code === "ExpiredCodeException") {
        //         NotificationManager.error("Code has expired. Please try again.", "Error");
        //         setError("Reset code has expired, please try again.")
        //     } else if (err.code === "CodeMismatchException") {
        //         NotificationManager.error("Code does not match, please double check.", "Error");
        //         setError('Code does not match what server was expecting.');
        //     } else if (err.code === "InvalidPasswordException") {
        //         NotificationManager.error("Password is invalid.", "Error");
        //         setError('Password is invalid.')
        //     } else {
        //         NotificationManager.error("An error has occurred.", "Error");
        //         setError('An error has occurred, please try again.')
        //         console.error(err);
        //     }
        // }

    }

    const classes = useStyles();

    return(
        <div className={classes.rootContainer}>
            <NotificationContainer />
            {codeSent ? (
                <Box m={'auto'} className={classes.boxContainer}>
                    <Typography>Please check email for password reset code.</Typography>
                    {Boolean(error) && <Typography color={'error'}>{error}</Typography>}
                    <div style={{flexGrow: 1}}>
                        <form onSubmit={submitReset}>
                            <Box mt={3}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="code">Code</InputLabel>
                                    <OutlinedInput
                                        id="code"
                                        name={'code'}
                                        value={code}
                                        onChange={e => setCode(e.target.value)}
                                        startAdornment={<InputAdornment position="start"><CodeIcon /></InputAdornment>}
                                        required
                                        label={'code'}
                                    />
                                </FormControl>
                            </Box>
                            <Box mt={3}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="new_password">New Password</InputLabel>
                                    <OutlinedInput
                                        id="new_password"
                                        name={'new_password'}
                                        type={'password'}
                                        value={newPassword}
                                        onChange={e => setNewPassword(e.target.value)}
                                        startAdornment={<InputAdornment position="start"><LockIcon /></InputAdornment>}
                                        required
                                        label={'New Password'}
                                    />
                                </FormControl>
                            </Box>
                            <Box mt={3}>
                                <Button variant={'contained'} fullWidth color={'primary'} type={'submit'}>Reset Password</Button>
                            </Box>
                        </form>
                    </div>
                </Box>
            ) : (
                <Box m={'auto'} className={classes.loadingBox}>
                    <CircularProgress color={'inherit'} size={24} style={{marginRight: '20px'}} />
                    <Typography>Loading User Data...</Typography>
                </Box>
            )}

        </div>
    )
}

export default withRouter(ForgotPassword);