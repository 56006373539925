const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const TOGGLE_MOBILE_SIDEBAR = 'TOGGLE_MOBILE_SIDEBAR';
const SET_MISSION_MIX = 'SET_MISSION_MIX';
const REMOVE_MISSION_MIX = 'REMOVE_MISSION_MIX';
const SET_ORG_ID = "SET_ORG_ID";
const TOGGLE_SIDEBAR_ITEM = "TOGGLE_SIDEBAR_ITEM";

export const ToggleSidebar = () => {
    return {
        type: TOGGLE_SIDEBAR
    }
};

export const ToggleSidebarItem = (name) => {
    return{
        type: TOGGLE_SIDEBAR_ITEM,
        payload: name
    }
}

export const ToggleMobileSidebar = () => {
    return {
        type: TOGGLE_MOBILE_SIDEBAR
    }
};

export const SetOrgID = (id) => {
    if(id !== 0) {
        return {
            type: SET_ORG_ID,
            payload: id
        }
    }
};

export const SetMissionMix = (payload = []) => {
    return {
        type: SET_MISSION_MIX,
        payload: payload
    }
}

export const RemoveMissionMix = () => {
    return {
        type: REMOVE_MISSION_MIX
    }
}