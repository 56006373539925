import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
    FormControl,
    Box,
    Link,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PasswordDialogStyles from "../../../styles/jss/components/PasswordDialogStyles";
import { getCurrentUser } from 'aws-amplify/auth';
import { updatePassword } from 'aws-amplify/auth';
import {NotificationContainer, NotificationManager} from "react-notifications";

const useStyles = makeStyles(PasswordDialogStyles);

const PasswordDialog = (props) => {
    const classes = useStyles();
    const [errors, setErrors] = React.useState(false)
    const {closeDialog} = props;
    const handleClose = () => {
        setData({
            current_password: {
                value: '',
                error: false,
                errorMessage: false
            },
            new_password: {
                value: '',
                error: false,
                errorMessage: false
            },
            confirm: {
                value: '',
                error: false,
                errorMessage: ''
            },
        });
        closeDialog();
    }

    const [data, setData] = React.useState({
        current_password: {
            value: '',
            error: false,
            errorMessage: false
        },
        new_password: {
            value: '',
            error: false,
            errorMessage: false
        },
        confirm: {
            value: '',
            error: false,
            errorMessage: ''
        },
    });

    const handleChange = event => {
        const name = event.target.name;
        const val = event.target.value;
        validation(val, name)
          .then(values => {
              setData({ ...data, [name]: values })
          })

    };

    React.useEffect(() => {
        checkForErrors()
    }, [data])

    const checkForErrors = () => {
        if(data.confirm.error || data.current_password.error || data.new_password.error) {
            setErrors(true)
        } else {
            setErrors(false)
        }
    }

    const validation = async (input, field) => {
        switch(field){
            case 'new_password':
                if(input.match(/^.{8,}$/)) {
                    return {
                        value: input,
                        error: false,
                        errorMessage: false
                    }
                } else {
                    return {
                        value: input,
                        error: true,
                        errorMessage: 'Must include at least 8 characters'
                    }
                }
            case 'current_password':
                return {
                    value: input,
                    error: false,
                    errorMessage: false
                }
            case 'confirm':
                if(input.match(/^.{8,}$/)) {
                    if(input !== data.new_password.value) {
                        return {
                            value: input,
                            error: true,
                            errorMessage: 'Must match new password.'
                        }
                    } else {
                        return {
                            value: input,
                            error: false,
                            errorMessage: false
                        }
                    }
                } else {
                    return {
                        value: input,
                        error: true,
                        errorMessage: 'Must include at least 8 characters'
                    }
                }
            default:
                return {
                    value: input,
                    error: false,
                    errorMessage: false
                }
        }

    }

    const submit = async (e) => {
        e.preventDefault();
        try {
            getCurrentUser()
                .then(user => {
                    return updatePassword(data.current_password.value, data.new_password.value);
                })
                .then(data => {
                    if(data === "SUCCESS") {
                        NotificationManager.success('Successfully changed your password.', 'Success');
                        handleClose();
                    }
                })
                .catch(err => {
                    console.log(err)
                    NotificationManager.warning(err.message, 'Error');
                });
        } catch(e) {
            NotificationManager.error('Please check console for error', 'An Error Occurred');
            console.log(e)
        }
    }

    return (
        <div>
            <NotificationContainer />
            <Dialog open={props.open} onClose={handleClose}  aria-labelledby="form-dialog-title" classes={{paper: classes.dialogContainer}}>
                <form onSubmit={(e) => submit(e)}>
                    <DialogTitle id="form-dialog-title" className={classes.titleContainer}>Change Password</DialogTitle>
                    <DialogContent>
                        <Box mb={3}>
                            <FormControl fullWidth>
                                <TextField
                                    type={'password'}
                                    id={'current_password'}
                                    autoFocus
                                    name={'current_password'}
                                    variant='standard'
                                    fullWidth
                                    label={data.current_password.error ? data.current_password.errorMessage : 'Current Password'}
                                    error={data.current_password.error}
                                    value={data.current_password.value}
                                    required
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormControl>
                        </Box>
                        <Box mb={3}>
                            <FormControl fullWidth>
                                <TextField
                                    type={'password'}
                                    id={'new_password'}
                                    name={'new_password'}
                                    variant='standard'
                                    fullWidth
                                    error={data.new_password.error}
                                    label={data.new_password.error ? data.new_password.errorMessage : 'New Password'}
                                    value={data.new_password.value}
                                    required
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormControl>
                        </Box>
                        <Box mb={3}>
                            <FormControl fullWidth>
                                <TextField
                                    type={'password'}
                                    id={'confirm'}
                                    name={'confirm'}
                                    variant='standard'
                                    fullWidth
                                    error={data.confirm.error}
                                    label={data.confirm.error ? data.confirm.errorMessage : 'Confirm Password'}
                                    value={data.confirm.value}
                                    required
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions classes={{root: classes.buttonContainer}}>
                        <Button onClick={() => handleClose()}>
                            Cancel
                        </Button>
                        <Button disabled={errors} type={'submit'} color="primary" variant={'contained'}>
                            Change password
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default PasswordDialog;