import React from 'react';
import {makeStyles} from "@material-ui/core";
import UserOptions from "./UserOptions";
// You can uncomment when notifications and messaging is added
// import InfoIcon from '@mui/icons-material/Info';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import Hidden from "@mui/material/Hidden";
// import {Badge, IconButton} from "@mui/material";

const useStyles = makeStyles({
    rightMenuContainer: {
        display: 'flex'
    }
})

const RightMenu = (props) => {

    const classes = useStyles();

    return(
        <div className={classes.rightMenuContainer}>
            {/*You can uncomment when notifications and messaging is added*/}
            {/*<Hidden>*/}
            {/*    <IconButton aria-label="show 4 new mails" color="inherit">*/}
            {/*        <Badge color="secondary">*/}
            {/*            <InfoIcon />*/}
            {/*        </Badge>*/}
            {/*    </IconButton>*/}
            {/*    <IconButton aria-label="show 17 new notifications" color="inherit">*/}
            {/*        <Badge badgeContent={17} color="secondary">*/}
            {/*            <NotificationsIcon />*/}
            {/*        </Badge>*/}
            {/*    </IconButton>*/}
            {/*</Hidden>*/}
            <UserOptions org={props.org}/>
        </div>
    )
}

export default RightMenu;