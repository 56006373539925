import React from 'react';
import {Card, CardContent, Typography, makeStyles, Grid, CardHeader} from "@material-ui/core";
import ASPECStyles from "../../../../../styles/jss/components/apps/ASPECStyles";
import {statusColor} from "../../../../../helpers";
import {useTheme} from "@material-ui/core/styles";
import {NotificationManager} from "react-notifications";
import EditName from "../successfulAnalysis/EditName";
import Reference from "../Reference";

const useStyles = makeStyles(ASPECStyles);

const AnalysisDetails = props => {
    const classes = useStyles();
    const theme = useTheme();
    const {updateData} = props;

    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState(false);

    React.useEffect(() => {
        setName(props.name)
    }, [props.name])

    const handleNameChange = e => {
        let value = e.target.value;
        setNameError(false);
        if(value.length > 30) {
            NotificationManager.warning('Cannot have a name over 30 characters', 'WARNING', 2000)
        }
        if(value.length === 0) {
            setNameError(true)
            NotificationManager.warning('Cannot have a whitespace name.', 'WARNING')
        }
        setName(value.slice(0,30))
    }

    const sanitizeDate = d => {
      if (d instanceof Date) {
        return !isNaN(d) ? d : "";
      }
      return d
    }

    return(
        <Card style={{height: '100%'}}>
            <CardHeader
                title={'Project Details'}
                classes={{
                    title: classes.cardHeaderTitle,
                    root: classes.cardHeaderRoot
                }}
                action={
                    <Reference refs={props.refs} aircraft={props.project.aircraft} />
                }
            />
            <CardContent className={classes.cardBody}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Typography variant={'caption'}>Project Name:</Typography>
                        <Typography variant={'body2'}>{props.project.name}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography variant={'caption'}>Aircraft:</Typography>
                        <Typography variant={'body2'}>{props.project.aircraft?.toUpperCase() ?? ""}</Typography>
                    </Grid>
                    <Grid item md={6}>
                        <EditName name={props.name} update={updateData} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Typography variant={'caption'}>Run Date:</Typography>
                        <Typography variant={'body2'}>{sanitizeDate(props.run)}</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography variant={'caption'}>Status</Typography>
                        <Typography style={{color: statusColor(props.status, theme)}} variant={'body2'}>{props.status}</Typography>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    )
}

export default AnalysisDetails;