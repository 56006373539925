import React from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ProjectStyles from "../../../../styles/jss/components/apps/ProjectStyles";
import moment from "moment";
import MomentUtils from "@date-io/moment";
// import MomentUtils from "@material-ui/pickers/adapter/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const ProjectTableHeader = (props) => {
  const [filters, setFilters] = React.useState({
    name: "",
    status: "",
    "args.missionMix": "",
    runDate: null,
  });

  React.useEffect(() => {
    let sendFilters = {};
    for (let prop in filters) {
      filters[prop] !== "" &&
        (sendFilters[prop] = filters[prop]);
    }
    props.filter(sendFilters);
  }, [filters]);


  const updateFilters = async (e) => {
    // Check if date was cleared (will return e = null)
    if (e === null) {
      let tempFilters = {... filters};
      tempFilters["runDate"] = null;
      setFilters(tempFilters)
      return;
    }

    if ("_isAMomentObject" in e) { // Check if a date object was sent
      let tempFilters = {... filters};
      tempFilters["runDate"] = e.unix() * 1000;
      setFilters(tempFilters)
      return;
    } else {
      let tempFilters = {... filters};
      tempFilters[e.target.name] = e.target.value;
      tempFilters["runDate"] = null;
      setFilters(tempFilters)
    }
  };

  const { classes, missionMixes, minDate } = props;

  return (
    <Grid className={classes.actionContainer} container spacing={3}>
      <Grid item md={4} sm={12} xs={12}>
        <Box>
          <FormControl style={{ width: "100%" }}>
            <TextField
              id="standard-adornment-amount"
              label={"Search Analysis Name"}
              name={"name"}
              variant={"outlined"}
              value={filters.name}
              onChange={(e) => updateFilters(e)}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item md={8} sm={12} xs={12}>
        <Box className={classes.filterContainer}>
          <Typography variant={"h6"}>Filter:</Typography>
          <FormControl className={classes.filterInner} variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={filters.status}
              name={"status"}
              onChange={(e) => updateFilters(e)}
              label="Status"
            >
              <MenuItem value={""}>
                <em>All</em>
              </MenuItem>
              <MenuItem value={"Successful"}>
                <em>Successful</em>
              </MenuItem>
              <MenuItem value={"Failure"}>
                <em>Failure</em>
              </MenuItem>
              <MenuItem value={"Draft"}>
                <em>Draft</em>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.filterInner} variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Mission Mix
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={filters["args.missionMix"]}
              name={"args.missionMix"}
              onChange={(e) => updateFilters(e)}
              label="Mission Mix"
            >
              <MenuItem value={""}>
                <em>All</em>
              </MenuItem>
              {missionMixes.map((mix, idx) => (
                <MenuItem key={idx} value={mix}>
                  {mix}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.filterInner}>
            <MuiPickersUtilsProvider clearable utils={MomentUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                clearable
                allowKeyboardControl={false}
                disableToolbar
                variant="dialog"
                format="MM/DD/yyyy"
                id="date-picker-inline"
                label="Run Date"
                value={filters.runDate}
                minDate={moment(minDate)}
                disableFuture
                onChange={(e) => updateFilters(e)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(ProjectStyles, { withTheme: true })(
  ProjectTableHeader
);
