import React from 'react';
import { AppBar as Bar, Toolbar, IconButton, Typography, makeStyles } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { ToggleSidebar } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import theme from '../../../theme';
import RightMenu from "./RightMenu";
import AppbarStyles from "../../../styles/jss/components/AppbarStyles";

const useStyles = makeStyles(AppbarStyles);

const AppBar = (props) => {
    const classes = useStyles();
    const sidebar = useSelector(state => state.sidebar);
    const dispatch = useDispatch();

    return (
        <Bar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: sidebar.open && sidebar.screenWidth > theme.breakpoints.values.sm,
            })}
            color={'default'}
        >
            <Toolbar classes={classes.toolbar} style={{justifyContent: 'space-between'}}>
                <div className={classes.pageTitleContainer}>
                    {!sidebar.open && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => dispatch(ToggleSidebar())}
                            size="large">
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" noWrap>
                        {props.title}
                    </Typography>
                </div>
                <div>
                    <RightMenu org={props.org}/>
                </div>
            </Toolbar>
        </Bar>
    );
};

export default AppBar;