import React from 'react';
import {Card, CardContent, Typography, makeStyles, CardHeader} from "@material-ui/core";
import ASPECStyles from "../../../../../styles/jss/components/apps/ASPECStyles";

const useStyles = makeStyles(ASPECStyles);

const FlightSegmentDef = props => {
    const classes = useStyles();

    return(
        <Card style={{height: '100%'}}>
            <CardHeader
                title={'Mission Flight Segment Definition'}
                classes={{
                    title: classes.cardHeaderTitle,
                    root: classes.cardHeaderRoot
                }} />
            <CardContent style={{height: '80%', display: 'flex'}}>
                <img src={"/MissionFlightSegmentDefinition.svg"} alt="Mission Flight Segment Definition" className={classes.FSDImage}/>
            </CardContent>
        </Card>
    )
}

export default FlightSegmentDef;