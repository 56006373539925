import React from 'react';
import {IconButton, TextField, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import withRouter from '../../../../../helpers/withRouter';

const EditName = props => {
    const {name} = props;
    const [edit, setEdit] = React.useState(false);
    const [newName, setNewName] = React.useState(props.name);

    const handleClick = () => {
        setEdit(!edit)
    }

    const changeName = (e) => {
        e.preventDefault();
        if(newName !== props.name) {
            props.update(newName, 'name', true)
        }
        setEdit(false);
    }

    return (
        <div style={{display: 'flex', alignContent: 'center', alignItems: 'center'}}>
            {edit ? (
                <TextField
                    style={{minWidth: '80%'}}
                    autoFocus
                    margin={'dense'}
                    variant={'outlined'}
                    defaultValue={name}
                    onChange={e => setNewName(e.target.value)}
                    onBlur={e => changeName(e)}
                />
            ) : (
                <Typography variant={'h6'}>{name}</Typography>
            )}
            <IconButton onClick={handleClick} size="large">
                <EditIcon />
            </IconButton>
        </div>
    );
}

export default withRouter(EditName);