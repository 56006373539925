import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import RingHeader from "./RingHeader";
import withRouter from "../../../../helpers/withRouter";
import AnalysisDetails from "./LeftMenuItems/AnalysisDetails";
import { NotificationManager } from "react-notifications";
import RingBodyGeometry from "./LeftMenuItems/RingBodyGeometry";
import { setNestedValue, checkForData } from "../../../../helpers";
import ASPECStyles from "../../../../styles/jss/components/apps/ASPECStyles";
import ContentLoading from "../../../common/ContentLoading";
import ActivityLog from "./LeftMenuItems/ActivityLog";
import { fetchAuthSession } from 'aws-amplify/auth';
import { cfg } from "../../../../config";
import axios from "axios";
import moment from "moment";
import * as _ from 'lodash';
import LoadcasesSection from "./LoadcasesSection";
import LoadcaseSection from "./LoadcaseSection";
import { useParams } from "react-router-dom";

const FuseAnalysis = (props) => {
  const params = useParams();

  const [data, setData] = React.useState({});
  const [lock, setLock] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRunning, setIsRunning] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [project, setProject] = React.useState({
    projectName: "",
    aircraft: "",
    projectID: 1,
    refs: [],
  });
  const [selectedLoadcaseIndex, setSelectedLoadcaseIndex] = React.useState(null);
  const [selectedLoadcase, setSelectedLoadcase] = React.useState(null);
  const [loadcaseIdx, setLoadcaseIdx] = React.useState(0);

  React.useEffect(() => {
    fetchData()
      .then(() => setIsLoading(false))
      .catch((e) => console.log(e));
  }, []);

  React.useEffect(() => {
    console.log("selectedLoadcase=")
    console.log(selectedLoadcase)
  }, [selectedLoadcase]);


  const saveDraft = async (auto = false) => {
    return new Promise((resolve, reject) => {
      setIsSaving(true);

      let newData = { ...data };

      // console.log(newData)

      if (newData.name === lock.name) {
        delete newData.name;
      }
      delete newData.activityLog;
      delete newData.files;

      fetchAuthSession().then((session) => {
        axios
          .patch(
            `${cfg.apiUrl}/app/ring/analysis/${params.projectId}/${params.id}`,
            {
              ...newData,
            },
            {
              headers: {
                Authorization: session.tokens?.idToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((resp) => {
            let tempLock = { ...lock };
            tempLock.name = data.name;
            setLock(tempLock);

            if (resp.status === 200) {
              console.log("Draft Saved.");
              NotificationManager.success(`Data saved succesfully`, 'SUCCESS');
              resolve();
            } else {
              NotificationManager.error(
                "Something went wrong when fetching data. Please check console for more info.",
                "ERROR"
              );
              reject();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 400) {
                NotificationManager.error(
                  err.response.data.error.message,
                  "Error"
                );
              } else {
                NotificationManager.error(
                  "An error has occurred, please try again.",
                  "Error"
                );
              }
            } else if (err.request) {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            } else {
              NotificationManager.error(
                "An error has occurred, please try again.",
                "Error"
              );
            }
            reject(err);
          })
          .finally(() => {
            setIsSaving(false);
          });
      });
    });
  };


  const fetchData = async () => {
    // Axios logic for fetching data pertaining to analysis ID
    return new Promise((resolve, reject) => {
      fetchAuthSession().then((session) => {
        axios
          .get(`${cfg.apiUrl}/app/ring/analysis/${params.projectId}/${params.id}`, {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          })
          .then(async (resp) => {
            if (resp.status === 200) {
              const newData = {
                name: resp.data.name,
                args: {
                  loadedRing: checkForData(resp, "data.args.loadedRing", ""),
                  adjacentRing: checkForData(resp, "data.args.adjacentRing", ""),
                  legacyMode : checkForData(resp, "data.args.legacyMode", false),

                  shellRadius: checkForData(resp, "data.args.shellRadius", 0),
                  ringRadius: checkForData(resp, "data.args.ringRadius", 0),
                  shellThickness: checkForData(resp, "data.args.shellThickness", 0),
                  ringMomentInertia: checkForData(resp, "data.args.ringMomentInertia", 0),
                  equivalentShellThickness: checkForData(resp, "data.args.equivalentShellThickness", 0),

                  modulusElasticity: checkForData(resp, "data.args.modulusElasticity", 0),
                  ringSpacing: checkForData(resp, "data.args.ringSpacing", 0),
                  shearModulus: checkForData(resp, "data.args.shearModulus", 0),
                  structuralParamA: checkForData(resp, "data.args.structuralParamA", 0),
                  structuralParamB: checkForData(resp, "data.args.structuralParamB", 0),
                },
                data: checkForData(resp, "data.data",
                  {
                    // nextLoadcaseIndex: 1,
                    loadcases: []
                  }
                ),
                activityLog: [
                  {
                    title: "Date Created",
                    content: resp.data.created,
                  },
                  {
                    title: "Last Update",
                    content: resp.data.modified,
                  },
                ],
                results: checkForData(resp, "data.results", {}),
                status: resp.data.status,
                runDate: resp.data.runDate ? moment(resp.data.runDate).format("ll") : "",
              };

              setProject(resp.data.project);
              console.log(newData)
              setData({...newData});
              setLock({...newData}); // needed to avoid "name already exist" error when saving
              // await sleep(1000)
              // Reset selected loadcase
              setSelectedLoadcaseIndex(null);
              setSelectedLoadcase(null);
              setLoadcaseIdx(null);

              resolve();
            } else {
              reject();
              NotificationManager.error(
                "Something went wrong when fetching data. Please check console for more info.",
                "ERROR"
              );
            }
          })
          .catch((e) => {
            console.log(e);
            NotificationManager.error(
              "Something went wrong when fetching data. Please check console for more info.",
              "ERROR"
            );
            reject(e);
          });
      });
    });
  };

  /*
    Updates the current state.data.
     */
  const updateData = (value, objectLocation, forceUpdate = false) => {
    console.log("updateData " + objectLocation)
    if (objectLocation.endsWith(".index")) {
      // Make sure that a loadcase with the new ID doesn't exist
      const existingLoadcaseIds = data.data.loadcases.map(loadcase => loadcase.index);
      console.log("existingLoadcaseIds=")
      console.log(existingLoadcaseIds)
      if (existingLoadcaseIds.includes(value)) {
        setData({ ...data });
        return; // Skip update if a loadcase with the new ID already exists
      }
    }
    let newData = _.set({ ...data }, objectLocation, value) //setNestedValue({ ...data }, objectLocation, value);
    setData(newData);
  };

  const loadcaseIdAlreadyTaken = (value) => {
    // Make sure that a loadcase with the new ID doesn't exist
    const existingLoadcaseIds = data.data.loadcases.map(loadcase => loadcase.index);
    console.log("existingLoadcaseIds=")
    console.log(existingLoadcaseIds)
    return existingLoadcaseIds.includes(value);
  }

  const runAnalysis = async () => {
    setIsLoading(true);
    setIsRunning(true);

    await saveDraft(true);

    // console.log("... sleeping for 1 second");
    // await sleep(1000); // temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)

    fetchAuthSession().then((session) => {
      axios
        .post(
          `${cfg.apiUrl}/app/ring/analyze`,
          {
            id: params.id,
            projectId: params.projectId,
          },
          {
            headers: {
              Authorization: session.tokens?.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (resp) => {
          if (resp.status === 200) {
            await fetchData();
            NotificationManager.success(`Analysis run succesfully`, 'SUCCESS');
            setIsLoading(false);
          } else {
            NotificationManager.warning(
              "Something went wrong, please check the console.",
              "WARNING"
            );
          }
        })
        .catch(async (e) => {
          if (e.response || e.request) {
            NotificationManager.error(
              "Something went wrong, please see console for details.",
              "ERROR"
            );
          } else {
            NotificationManager.error(
              "Something went wrong, please see console for details.",
              "ERROR"
            );
          }
          console.log(e);
          await fetchData();
          setIsLoading(false);
        })
        .finally(async (resp) => {
          setIsRunning(false);
        });
    });
  };

  const pasteAnalysis = async () => {
    const copied = JSON.parse(window.sessionStorage.getItem("copyProject"));
    if (!copied) return;
    if (copied.args.aircraft === data.args.aircraft) {
      let name = data.name;
      copied.name = name;
      setData(copied);
      setLock(copied);
      window.sessionStorage.removeItem("copyProject");
    } else {
      NotificationManager.warning(
        "Cannot copy analysis. MissionMix/Aircraft mismatch.",
        "FAILED"
      );
    }
  };

  // Stringers

  const getNextLoadcaseIndex = () => {
    const existingIndexes = data.data.loadcases.map(l => l.index);
    let index = 1;
    while (existingIndexes.includes(index)) {
      index += 1;
    }
    return index;
  }

  const addLoadcase = async () => {
    let station = {
      'capArea': 0,
      'effFactor': 0,
      'x': 0,
      'z': 0,
      'py': 0
    }
    let panel = {
      'del2A': 0,
      't': 0,
      'delS': 0,
      'geG': 0
    }
    let newRow = {
      index: getNextLoadcaseIndex(),
      appliedLoadPointCount: 0,
      limitedOutput: false,
      LO1: 0,
      LO2: 0,
      LO3: 0,
      LO4: 0,
      LO5: 0,
      LO6: 0,
      LAD1: 0,
      LAD2: 0,
      LAD3: 0,
      LAD4: 0,
      LAD5: 0,
      LAD6: 0,

      loads: [],
    };
    let newData = { ...data };
    newData.data.loadcases.push(newRow);
    // newData.data.nextLoadcaseIndex += 1;
    setData(newData);
    console.log(newData)

  };

  // Loadcases

  const addAppliedLoad = async () => {
    console.log("Current loadcase=" + selectedLoadcaseIndex)
    let loadPoint = {
      'theta': 0,
      'type': 0,
      'P': 0,
      'T': 0,
      'AM': 0,
    }
    let newRow = loadPoint
    let newData = { ...data };
    let currentLoadcase = newData.data.loadcases.filter(loadcase => loadcase.index === selectedLoadcaseIndex)[0];
    console.log(currentLoadcase)
    currentLoadcase.loads.push(newRow);
    setData(newData);
  };

  const deleteLoadcase = async (loadcaseIndex) => {
    console.log("deleteLoadcase = " + loadcaseIndex)
    let newData = { ...data };
    newData.data.loadcases = newData.data.loadcases.filter((loadcase, id) => {
      return id !== loadcaseIndex;
    });
    setData(newData);

    // Reset selected loadcase
    setSelectedLoadcaseIndex(null);
    setSelectedLoadcase(null);
    setLoadcaseIdx(null);

  }

  const deleteAppliedLoads = async (rowIndexes) => {
    let newData = { ...data };
    let currentLoadcase = newData.data.loadcases.filter(loadcase => loadcase.index === selectedLoadcaseIndex)[0];
    console.log("deleteAppliedLoads")
    console.log(currentLoadcase.loads)
    currentLoadcase.loads = currentLoadcase.loads.filter((_, id) => {
      return !rowIndexes.includes(id);
    });
    console.log(currentLoadcase.loads)
    setData(newData);
  }

  return !isLoading ? (
    <div>
      <RingHeader
        projectID={project.projectID}
        data={data}
        status={data.status}
        isSaving={isSaving}
        isRunning={isRunning}
        functions={{
          updateData: updateData,
          saveDraft: saveDraft,
          submit: runAnalysis,
          paste: pasteAnalysis,
        }}
      />
      <Grid container spacing={2} style={{ paddingBottom: "8px" }}>
        <Grid item md={7}>
          <AnalysisDetails
            refs={project.refs}
            name={data.name}
            updateData={updateData}
            project={project}
            run={data.runDate}
            status={data.status}
          />
        </Grid>
        <Grid item md={5}>
          <ActivityLog data={data} />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingBottom: "18px" }}>
        <Grid item md={12}>
          <RingBodyGeometry
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            data={data}
            updateData={updateData}
            saveDraft={saveDraft}
            refresh={fetchData}
            aircraft={project.aircraft?.toUpperCase() ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container style={{ paddingBottom: "18px" }} alignItems="stretch">
        <Grid item md={2} xs >
          <LoadcasesSection
            refresh={fetchData}
            data={data.data}
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            update={updateData}
            saveDraft={saveDraft}
            tableUnlocked={true}
            addLoadcase={addLoadcase}
            deleteLoadcase={deleteLoadcase}
            selectedLoadcaseIndex={selectedLoadcaseIndex}
            setSelectedLoadcaseIndex={setSelectedLoadcaseIndex}
            selectedLoadcase={selectedLoadcase}
            setSelectedLoadcase={setSelectedLoadcase}
            setLoadcaseIdx={setLoadcaseIdx}
          />
        </Grid>
        <Grid item md={10} xs>
          <LoadcaseSection
            refresh={fetchData}
            data={data.data}
            status={data.status}
            isRunning={isRunning}
            isSaving={isSaving}
            update={updateData}
            saveDraft={saveDraft}
            tableUnlocked={true}
            addAppliedLoad={addAppliedLoad}
            deleteAppliedLoads={deleteAppliedLoads}
            selectedLoadcaseIndex={selectedLoadcaseIndex}
            setSelectedLoadcaseIndex={setSelectedLoadcaseIndex}
            selectedLoadcase={selectedLoadcase}
            setSelectedLoadcase={setSelectedLoadcase}
            loadcaseIdx={loadcaseIdx}
            setLoadcaseIdx={setLoadcaseIdx}
            loadcaseIdAlreadyTaken={loadcaseIdAlreadyTaken}
          />
        </Grid>
      </Grid>
    </div>
  ) : (
    <div>
      <ContentLoading />
    </div>
  );
};

// Temporary workaround for eventual consistent read issue in analyze-post (latest data not always fetched)
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default withStyles(ASPECStyles, { withTheme: true })(
  withRouter(FuseAnalysis)
);
