// import logo from './logo.svg';
import React from "react";

// import { withAuthenticator } from "aws-amplify-react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { publicRoutes, privateRoutes } from "./routes";
import { Header } from "./components/authentication/Header";
import { Footer } from "./components/authentication/Footer";
import { NotificationContainer } from "react-notifications";
import PrivateRoute from "./helpers/PrivateRoute";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <NotificationContainer />
        {/*<APIStatus />*/}
        <Router basename={""}>
          <div>
            <Routes>
              {publicRoutes.map((route, idx) => {
                // Public routes
                return (
                  <Route
                    key={idx + "-pu"}
                    path={route.path}
                    exact={route.exact}
                    element={
                      <route.layout title={route.pageTitle}>
                        <route.component />
                      </route.layout>
                    }
                  />
                );
              })}
              {privateRoutes.map((route, idx) => {
                // Private routes
                return (
                  <Route key={idx + "-pr1"}
                    element={<PrivateRoute />}
                  >
                    <Route
                      key={idx + "-pr2"}
                      path={route.path}
                      exact={route.exact}
                      element={
                        <route.layout title={route.pageTitle}>
                          <route.component />
                        </route.layout>
                      }
                    />
                  </Route>
                );
              })}
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default withAuthenticator(App, {
  loginMechanisms: ["email"],
  // setting totpIssuer has no effect, seems to be a bug, might need to update to latest Amplify
  // formFields: {
  //   setupTOTP: {
  //     QR: {
  //       totpIssuer: 'Aeronautica',
  //       // totpUsername: email,
  //     },
  //   },
  // },
  components: {
    Header,
    // SignIn: {
    //   Header: SignInHeader,
    //   Footer: SignInFooter
    // },
    Footer,
  },
});
