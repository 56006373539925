import * as React from 'react';
import TextField from '@material-ui/core/TextField';
// import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Checkbox,
  InputAdornment,
  Typography,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
 const convertPixelsToRem = (unitValue) => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

const LoadcasesTable = (props) => {
  const tableUnlocked = props.status !== "SUCCESSFUL";

  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
    },

  });

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  // used to setup styles for short text fields
  const ShortTextField = withStyles((theme) => ({
    root: {
      width: 80, // 135
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);


  const handleTextfieldChange = async (e, cbIdx, column, section, stationIdx, props) => {
    if (column === 'id') {
      // Update loadcase id for all stations to keep them in sync
      props.data.stations.forEach((_, stationIndex) => {
        props.update(parseFloat(e.target.value), `data.stations[${stationIndex}].loadcases[${cbIdx}].${column}`);
      });
    } else {
      props.update(parseFloat(e.target.value), `data.stations[${stationIdx}].loadcases[${cbIdx}].${column}`);
    }
  };

  const segmentCenterColumnHeaders = ['ID', 'x_lra', 'z_lra', 'x_ctr', 'z_ctr', 'vx', 'vz1', 'vz2', 'mx1', 'mx2', 'mz', 't_lra', 'kb1', 'kb2']; // 'totalPy'
  const segmentCenterColumns = ['id', 'x_lra', 'z_lra', 'x_ctr', 'z_ctr', 'vx', 'vz1', 'vz2', 'mx1', 'mx2', 'mz', 't_lra', 'kb1', 'kb2'];

  const segmentOutboardColumnHeaders = ['ID', 'x_lra', 'y_lra', 'z_lra', 'vx', 'vy', 'vz', 'mx', 't_lra', 'mz'];
  const segmentOutboardColumns = ['id', 'x', 'y', 'z', 'vx', 'vy', 'vz', 'mx', 't_lra', 'mz'];


  const leftBorderColumns = ['id']

  // const centerColumnWidth = "7.7%"

  // const getField = (loadcase, column, cbIdx) => {
  //   return (
  //     <ShortTextField
  //       defaultValue={getValue(loadcase, column)}
  //       variant="outlined"
  //       onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
  //     />
  //   );

  // };

  // const getValue = (loadcase, column) => {
  //   return loadcase[column];
  // };

  const getField = (loadcase, column, cbIdx, stationIdx, section='', unit='') => {
    const value = getValue(loadcase, column, section)
    if (column === "type") {
      return (
        <ShortTextField
        select
          name={column}
          defaultValue={value}
          error={value ? Boolean(value.error) : false}
          focused={value ? value.focus : false}
          type={'text'}
          variant="outlined"
          InputProps={ unit ? {
            endAdornment:
            <InputAdornment position="end">
              <Typography variant={'caption'} style={{ color: "rgba(0,0,0,0.54)" }}>{unit}</Typography>
            </InputAdornment>
          } : {}}
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, section, stationIdx, props)}
        >
          <MenuItem value={1}>Radial</MenuItem>
          <MenuItem value={2}>Tangential</MenuItem>
          <MenuItem value={3}>Moment</MenuItem>
        </ShortTextField>
      )
    }
    return (
      <ShortTextField
        name={column}
        defaultValue={value}
        error={value ? Boolean(value.error) : false}
        focused={value ? value.focus : false}
        type={'text'}
        variant="outlined"
        InputProps={ unit ? {
          endAdornment:
          <InputAdornment position="end">
            <Typography variant={'caption'} style={{ color: "rgba(0,0,0,0.54)" }}>{unit}</Typography>
          </InputAdornment>
        } : {}}
        onBlur={(e) => handleTextfieldChange(e, cbIdx, column, section, stationIdx, props)}
      />
    );

  };

  const getValue = (loadcase, column, section='') => {
    return loadcase[column];
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && props.selectedStation && props.selectedStation.loads) {
      const newSelecteds = props.selectedStation.loads.map((_, i) => i);
      props.setSelectedLoadcases(newSelecteds);
      return;
    }
    props.setSelectedLoadcases([]);
  };

  const handleClick = (event, loadIndex) => {
    const selectedIndex = props.selectedLoadcases.indexOf(loadIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedLoadcases, loadIndex);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedLoadcases.slice(1));
    } else if (selectedIndex === props.selectedLoadcases.length - 1) {
      newSelected = newSelected.concat(props.selectedLoadcases.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedLoadcases.slice(0, selectedIndex),
        props.selectedLoadcases.slice(selectedIndex + 1)
      );
    }

    props.setSelectedLoadcases(newSelected);
  };

  const isSelected = (load) => props.selectedLoadcases.indexOf(load) !== -1;

  const rowCount = () =>
    props.selectedStation && props.selectedStation.loads ? props.selectedStation.loads.length : 0;

  const columnHeaders = props.analysis === 1 ? segmentCenterColumnHeaders : segmentOutboardColumnHeaders;
  const colunms = props.analysis === 1 ? segmentCenterColumns : segmentOutboardColumns;
  const columnWidth = props.analysis === 1 ? "7.7%" : "11.1%";

  console.log(props.selectedStation)

  // overflowX: 'unset' to avoid small vertical scroll issue (space after the last row)
  return (
    <TableContainer style={{overflowX: 'unset' }}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableUnlocked && (
              <TableCell rowSpan={2}padding="checkbox" align='center' style={{padding: 2}} width="5%">
                <Checkbox
                  color="primary"
                  size="small"
                  style={{ padding: 2 }}
                  indeterminate={
                    props.selectedLoadcases.length > 0 && props.selectedLoadcases.length < rowCount()
                  }
                  checked={rowCount() > 0 && props.selectedLoadcases.length === rowCount()}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all circuit breakers',
                  }}
                />
              </TableCell>
            )}
            {
              columnHeaders.map((header) => {

                return(
                <TableCell align="center" width={columnWidth} style={{padding: 0}}><b>{header}</b></TableCell>
                )})
            }

          </TableRow>
        </TableHead>
        <TableBody>
          {props.selectedStation
            ? props.selectedStation.loadcases.map((loadcase, loadcaseIndex) => {

                // console.log("Loadcase=" + loadcase)
                const isItemSelected = isSelected(loadcaseIndex);

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`loadcase-${loadcaseIndex}`}
                    selected={isItemSelected}
                    // style={{ height: 41 }}
                  >
                    {tableUnlocked && (
                      <TableCell padding="checkbox" align='center' style={{padding: 2}} width="5%">
                        <Checkbox
                          color="primary"
                          size="small"
                          style={{ padding: 2 }}
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, loadcaseIndex)}
                        />
                      </TableCell>
                    )}

                    {colunms.map(column => {
                      return (
                        <TableCell
                          align={'center'}
                          key={column}
                          width={columnWidth}
                          style={{
                            padding: 0,
                          }}
                        >
                          {tableUnlocked
                            ? getField(loadcase, column, loadcaseIndex, props.stationIdx)
                            : getValue(loadcase, column)}
                        </TableCell>
            )})}
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LoadcasesTable;
