import Home from "./views/Home";
import Applications from "./views/Applications";
import AspecFatigueAnalysis from "./views/AspecFatigueAnalysis";
import Project from "./components/Apps/ASPECFatigueAnalysis/analysisList/Project";
import FuseProject from "./components/Apps/Fuse/analysisList/FuseProject";
import BoxbeamProject from "./components/Apps/Boxbeam/analysisList/BoxbeamProject";
import RingProject from "./components/Apps/Ring/analysisList/RingProject";
import WingspanProject from "./components/Apps/Wingspan/analysisList/WingspanProject";
import ASPECAnalysis from "./components/Apps/ASPECFatigueAnalysis/analysis/ASPECAnalysis";
import FuseAnalysis from "./components/Apps/Fuse/analysis/FuseAnalysis";
import BoxbeamAnalysis from "./components/Apps/Boxbeam/analysis/BoxbeamAnalysis";
import RingAnalysis from "./components/Apps/Ring/analysis/RingAnalysis";
import WingspanAnalysis from "./components/Apps/Wingspan/analysis/WingspanAnalysis";

// Import layout styles
import { DefaultLayout, PlainLayout } from "./layouts";

import Profile from "./views/Profile";
import CompanyProfile from "./views/CompanyProfile";
import TermsAndConditions from "./views/TermsAndConditions";
import PrivacyPolicy from "./views/PrivacyPolicy";
import CookiePolicy from "./views/CookiePolicy";
import Members from "./views/Members";
import ForgotPassword from "./views/ForgotPassword";
import Fuse from "./views/Fuse";
import Boxbeam from "./views/Boxbeam";
import Ring from "./views/Ring";
import Wingspan from "./views/Wingspan";

// set available routes
const publicRoutes = [
  {
    path: "/company_profile",
    component: CompanyProfile,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "Company Profile",
  },
  {
    path: "/terms_and_conditions",
    component: TermsAndConditions,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "Terms And Conditions",
  },
  {
    path: "/privacy_policy",
    component: PrivacyPolicy,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "Privacy Policy",
  },
  {
    path: "/cookie_policy",
    component: CookiePolicy,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "Cookie Policy",
  },
  // {
  //   path: "/profile",
  //   component: Profile,
  //   exact: true,
  //   layout: DefaultLayout,
  //   pageTitle: "Profile",
  // },
//   {
//     path: "/forgot_password",
//     component: ForgotPassword,
//     exact: true,
//     layout: DefaultLayout,
//     pageTitle: "Forgot Password",
//   },
];

const privateRoutes = [
  {
    path: "/",
    component: Home,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "Home",
  },
  {
    path: "/apps",
    component: Applications,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "Applications",
  },
  {
    path: "/apps/aspec-loadsgen",
    component: AspecFatigueAnalysis,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "ASPEC LOADSGEN Fatigue Spectral Analysis",
  },
  {
    path: "/apps/aspec-loadsgen/project/:id",
    component: Project,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "ASPEC LOADSGEN Project",
  },
  {
    path: "/apps/aspec-loadsgen/analysis/:projectId/:id",
    component: ASPECAnalysis,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "ASPEC LOADSGEN Analysis",
  },
  {
    path: "/apps/fuseloads",
    component: Fuse,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "FUSELOADS Fuselage Unit Beam Solver",
  },
  {
    path: "/apps/fuseloads/project/:id",
    component: FuseProject,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "FUSELOADS Project",
  },
  {
    path: "/apps/fuseloads/analysis/:projectId/:id",
    component: FuseAnalysis,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "FUSELOADS Analysis",
  },
  {
    path: "/apps/wingbox",
    component: Boxbeam,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "WINGBOX Multi-bay Box Beam Analysis",
  },
  {
    path: "/apps/wingbox/project/:id",
    component: BoxbeamProject,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "WINGBOX Project",
  },
  {
    path: "/apps/wingbox/analysis/:projectId/:id",
    component: BoxbeamAnalysis,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "WINGBOX Analysis",
  },
  {
    path: "/apps/ringframe",
    component: Ring,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "RINGFRAME Analysis",
  },
  {
    path: "/apps/ringframe/project/:id",
    component: RingProject,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "RINGFRAME Project",
  },
  {
    path: "/apps/ringframe/analysis/:projectId/:id",
    component: RingAnalysis,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "RINGFRAME Analysis",
  },
  {
    path: "/apps/wingspan",
    component: Wingspan,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "WINGSPAN Analysis",
  },
  {
    path: "/apps/wingspan/project/:id",
    component: WingspanProject,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "WINGSPAN Project",
  },
  {
    path: "/apps/wingspan/analysis/:projectId/:id",
    component: WingspanAnalysis,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "WINGSPAN Analysis",
  },
  {
    path: "/members",
    component: Members,
    exact: true,
    layout: DefaultLayout,
    pageTitle: "Members",
  },
];

export { publicRoutes, privateRoutes };

// export default [
//     {
//         path: "/",
//         component: Home,
//         exact: true,
//         layout: DefaultLayout,
//         pageTitle: 'Home'
//     },
//     {
//         path: "/apps",
//         component: Applications,
//         exact: true,
//         layout: DefaultLayout,
//         pageTitle: 'Applications'
//     },
//     {
//         path: "/apps/aspec-loadsgen",
//         component: AspecFatigueAnalysis,
//         exact: true,
//         layout: DefaultLayout,
//         pageTitle: 'ASPEC Fatigue Spectral Analysis'
//     },
//     {
//         path: "/apps/aspec-loadsgen/project/:id",
//         component: Project,
//         exact: true,
//         layout: DefaultLayout,
//         pageTitle: 'ASPEC Project'
//     },
//     {
//         path: "/apps/aspec-loadsgen/analysis/:projectId/:id",
//         component: ASPECAnalysis,
//         exact: true,
//         layout: DefaultLayout,
//         pageTitle: 'ASPEC Analysis'
//     },
//     {
//         path: "/profile",
//         component: Profile,
//         exact: true,
//         layout: DefaultLayout,
//         pageTitle: 'Profile'
//     },
//     {
//         path: "/company_profile",
//         component: CompanyProfile,
//         exact: true,
//         layout: DefaultLayout,
//         pageTitle: 'Company Profile'
//     },
//     {
//         path: "/members",
//         component: Members,
//         exact: true,
//         layout: DefaultLayout,
//         pageTitle: "Members"
//     },
//     {
//         path: "/forgot_password",
//         component: ForgotPassword,
//         exact: true,
//         layout: DefaultLayout,
//         pageTitle: "Forgot Password"
//     }
// ]
