import React from 'react';
import MainSidebar from '../components/layout/sidebar/MainSidebar';
import APIStatus from "../components/layout/status/APIStatus";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import AppBar from "../components/layout/appbar/AppBar";
import {useSelector} from "react-redux";
import DefaultLayoutStyles from "../styles/jss/DefaultLayout";
import theme from "../theme";
import {CssBaseline} from "@material-ui/core";
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import {cfg} from "../config";
import { useQuery } from "react-query";

const useStyles = makeStyles(DefaultLayoutStyles);

const getOrg = async () => {
  const session = await fetchAuthSession();

  return await axios
    .get(`${cfg.apiUrl}/org`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: session.tokens?.idToken,
      },
    });
}

const DefaultLayout = ({children, ...props}) => {
    const classes = useStyles();
    const sidebar = useSelector(state => state.sidebar);
    const [status, setStatus] = React.useState(true);

    const { data: sub, error } = useQuery("getOrg", getOrg);
    if (error) console.log(error);

    React.useEffect(() => {
        fetchAuthSession()
            .then(session => {
                axios.get(`${cfg.apiUrl}/status`, {
                    headers: {
                        "Authorization": session.tokens?.idToken,
                        "Content-Type": "application/json"
                    }
                })
                    .then(resp => {
                        if(resp.status === 200) {
                            if(resp.data.status === 'ok') {
                                setStatus(true)
                            } else {
                                setStatus(false)
                            }
                        } else {
                            setStatus(false)
                        }
                    })
                    .catch(e => {
                        setStatus(false)
                    })
            })
    }, []);

    return (
        <div className={classes.root}>
            <CssBaseline/>
            {!status && <APIStatus />}
            <AppBar title={props.title} org={sub ? sub.data : ''}/>
            <MainSidebar/>
            <main className={clsx(classes.content, {
                [classes.contentShift]: sidebar.open && sidebar.screenWidth > theme.breakpoints.values.sm,
            })}>
                <div className={classes.drawerHeader} />
                {children}
            </main>
            {/*Issue with footer: sitting on top of content.*/}
            {/*<MainFooter />*/}
        </div>
    );
}

export default DefaultLayout;