import React from 'react';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    IconButton,
    Menu,
    MenuItem, TextField,
    Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {makeStyles} from "@material-ui/core/styles";
import ProjectStyles from "../../../../styles/jss/components/apps/ProjectStyles";
import {cfg} from "../../../../config";
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import {NotificationManager} from "react-notifications";

const useStyles = makeStyles(ProjectStyles);

const ProjectListOptions = (props) => {

    const classes = useStyles();
    const {updateData, row, project} = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

    // Duplicate Project dialog logic
    const [duplicateName, setDuplicateName] = React.useState(row.name + ' [copy]');
    const changeDuplicateName = (e) => setDuplicateName(e.target.value);

    const [duplicateDialog, setDuplicateDialog] = React.useState(false);
    const openDuplicateDialog = () => { setDuplicateDialog(true); setAnchorEl(null); };
    const closeDuplicateDialog = () => setDuplicateDialog(false);

    const sendDuplicate = () => {
        setLoading(true);
        fetchAuthSession()
            .then(session => {
                axios.post(`${cfg.apiUrl}/app/ring/analysis/duplicate`, {
                    "name": duplicateName,
                    "id": row.id,
                    "projectId": project
                }, {
                    headers: {
                        "Authorization": session.tokens?.idToken,
                        "Content-Type": "application/json"
                    }
                })
                    .then(resp => {
                        setLoading(false);
                        if(resp.status === 200) {
                            closeDuplicateDialog();
                            console.log('Duplicated ' + row.name + ' with name ' + duplicateName);
                            updateData();
                        } else {
                            setLoading(false)
                            console.log('Something went wrong please try again.')
                        }
                    })
                    .catch(e => {
                        setLoading(false)
                        console.log(e)
                        if (e.response || e.request) {
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                        } else {
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                        }
                    })
            })


    }

    // Delete dialog logic
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const openDeleteDialog = () => { setDeleteDialog(true); setAnchorEl(null); }
    const closeDeleteDialog = () => setDeleteDialog(false);

    const [loading, setLoading] = React.useState(false);

    const sendDelete = () => {
        // Axios DELETE request API logic
        setLoading(true);
        fetchAuthSession()
            .then(session => {
                axios.delete(`${cfg.apiUrl}/app/ring/analysis/${project}/${row.id}`, {
                    headers: {
                        "Authorization": session.tokens?.idToken,
                        "Content-Type": "application/json"
                    }
                })
                    .then(resp => {
                        setLoading(false);
                        if(resp.status === 200) {
                            closeDeleteDialog();
                            console.log('Deleted '+ row.name);
                            updateData();
                        } else {
                            closeDeleteDialog();
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                            console.log(resp)
                        }
                    })
                    .catch(e => {
                        setLoading(false);
                        console.log(e)
                        if (e.response || e.request) {
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                        } else {
                            NotificationManager.error('Something went wrong, please see console for details.', 'ERROR');
                        }
                    })
            })

    }

    const ITEM_HEIGHT = 48;

    return (
        <div>
            <Dialog open={deleteDialog} onClose={closeDeleteDialog}  aria-labelledby="form-dialog-title" classes={{paper: classes.dialogContainer}}>
                <DialogTitle id="form-dialog-title">Delete the "{row.name}" analysis?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will delete the analysis. This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions classes={{root: classes.buttonContainer}}>
                    <Button disabled={loading} onClick={() => closeDeleteDialog()}>
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => sendDelete()} color="primary" variant={'contained'}>
                        Delete Analysis
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={duplicateDialog} onClose={closeDuplicateDialog}  aria-labelledby="form-dialog-title" classes={{paper: classes.dialogContainer}}>
                <DialogTitle id="form-dialog-title">Duplicate Analysis</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter a name for the duplicate analysis. The name must be unique.
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            type={'text'}
                            autoFocus
                            name={'name'}
                            fullWidth
                            label={'New Name'}
                            value={duplicateName}
                            onChange={(e) => changeDuplicateName(e)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions classes={{root: classes.buttonContainer}}>
                    <Button disabled={loading} onClick={() => closeDuplicateDialog()}>
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={() => sendDuplicate()} color="primary" variant={'contained'}>
                        Duplicate Analysis
                    </Button>
                </DialogActions>
            </Dialog>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large">
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={openDuplicateDialog}>
                    Duplicate
                </MenuItem>
                <MenuItem onClick={openDeleteDialog}>
                    <Typography color={'error'}>Delete</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default ProjectListOptions;