const basic_aircraft = [
  {
    value: 'Airbus A320',
    label: 'Airbus A320'
  }];
const premium_aircraft = [
  {
    value: 'Bizjet',
    label: 'Bizjet'
  },
  {
    value: 'Boeing 737',
    label: 'Boeing 737'
  },
  {
    value: 'Boeing 747',
    label: 'Boeing 747'
  },
  {
    value: 'Boeing 757',
    label: 'Boeing 757'
  },
  {
    value: 'Boeing 767',
    label: 'Boeing 767'
  },
  {
    value: 'Boeing 777',
    label: 'Boeing 777'
  },
  {
    value: 'CH47',
    label: 'CH47'
  },
  {
    value: 'CRJ100',
    label: 'CRJ100'
  },
  {
    value: 'DC-6',
    label: 'DC-6'
  },
  {
    value: 'OV10A Patrick AFB',
    label: 'OV10A Patrick AFB'
  },
  {
    value: 'OV10A George AFB',
    label: 'OV10A George AFB'
  },
  {
    value: 'P2V5 Firefighting',
    label: 'P2V5 Firefighting'
  },
  {
    value: 'P2V5 Maritime Patrol',
    label: 'P2V5 Maritime Patrol'
  },
  {
    value: 'P3A Firefighting',
    label: 'P3A Firefighting'
  },
  {
    value: 'P3A Maritime Patrol',
    label: 'P3A Maritime Patrol'
  },
  {
    value: 'T28D',
    label: 'T28D'
  }
];
const all_aircraft = basic_aircraft.concat(premium_aircraft);

export {basic_aircraft, all_aircraft};