import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

const MissionMixChangeDialog = (props) => {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const {confirmMissionMixChange, cancel} = props;

    return(
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Changing mission mix will reset inputs. If you have already started on
                    the analysis input form, this action will reset.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={confirmMissionMixChange} color="primary" autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MissionMixChangeDialog;