import * as React from 'react';
import TextField from '@material-ui/core/TextField';
// import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Checkbox,
  InputAdornment,
  Typography,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
 const convertPixelsToRem = (unitValue) => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

const LoadsTable = (props) => {
  const tableUnlocked = props.status !== "SUCCESSFUL";

  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
    },

  });

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  // used to setup styles for short text fields
  const ShortTextField = withStyles((theme) => ({
    root: {
      width: 150,
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);


  const handleTextfieldChange = async (e, cbIdx, column, section, loadcaseIdx, props) => {
    props.update(parseFloat(e.target.value), `data.loadcases[${loadcaseIdx}].loads[${cbIdx}].${column}`);
  };

  const loadPointColumns = ['theta', 'type', 'P', 'T', 'AM']; // 'totalPy'
  const leftBorderColumns = ['id']

  // const getField = (loadcase, column, cbIdx) => {
  //   return (
  //     <ShortTextField
  //       defaultValue={getValue(loadcase, column)}
  //       variant="outlined"
  //       onBlur={(e) => handleTextfieldChange(e, cbIdx, column, props)}
  //     />
  //   );

  // };

  // const getValue = (loadcase, column) => {
  //   return loadcase[column];
  // };

  const getField = (loadcase, column, cbIdx, loadcaseIdx, section='', unit='') => {
    const value = getValue(loadcase, column, section)
    if (column === "type") {
      return (
        <ShortTextField
        select
          name={column}
          defaultValue={value}
          error={value ? Boolean(value.error) : false}
          focused={value ? value.focus : false}
          type={'text'}
          variant="outlined"
          InputProps={ unit ? {
            endAdornment:
            <InputAdornment position="end">
              <Typography variant={'caption'} style={{ color: "rgba(0,0,0,0.54)" }}>{unit}</Typography>
            </InputAdornment>
          } : {}}
          onBlur={(e) => handleTextfieldChange(e, cbIdx, column, section, loadcaseIdx, props)}
        >
          <MenuItem value={1}>Radial</MenuItem>
          <MenuItem value={2}>Tangential</MenuItem>
          <MenuItem value={3}>Moment</MenuItem>
        </ShortTextField>
      )
    }
    return (
      <ShortTextField
        name={column}
        defaultValue={value}
        error={value ? Boolean(value.error) : false}
        focused={value ? value.focus : false}
        type={'text'}
        variant="outlined"
        InputProps={ unit ? {
          endAdornment:
          <InputAdornment position="end">
            <Typography variant={'caption'} style={{ color: "rgba(0,0,0,0.54)" }}>{unit}</Typography>
          </InputAdornment>
        } : {}}
        onBlur={(e) => handleTextfieldChange(e, cbIdx, column, section, loadcaseIdx, props)}
      />
    );

  };

  const getValue = (loadcase, column, section='') => {
    if (section === 'inboard') {
      return loadcase.inboard[column];
    } else if (section === 'outboard') {
      return loadcase.outboard[column];
    } else {
      return loadcase[column];
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && props.selectedLoadcase && props.selectedLoadcase.loads) {
      const newSelecteds = props.selectedLoadcase.loads.map((_, i) => i);
      props.setSelectedLoads(newSelecteds);
      return;
    }
    props.setSelectedLoads([]);
  };

  const handleClick = (event, loadIndex) => {
    const selectedIndex = props.selectedLoads.indexOf(loadIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedLoads, loadIndex);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedLoads.slice(1));
    } else if (selectedIndex === props.selectedLoads.length - 1) {
      newSelected = newSelected.concat(props.selectedLoads.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedLoads.slice(0, selectedIndex),
        props.selectedLoads.slice(selectedIndex + 1)
      );
    }

    props.setSelectedLoads(newSelected);
  };

  const isSelected = (load) => props.selectedLoads.indexOf(load) !== -1;

  const rowCount = () =>
    props.selectedLoadcase && props.selectedLoadcase.loads ? props.selectedLoadcase.loads.length : 0;

  // overflowX: 'unset' to avoid small vertical scroll issue (space after the last row)
  return (
    <TableContainer style={{overflowX: 'unset' }}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableUnlocked && (
              <TableCell rowSpan={2}padding="checkbox" align='center' style={{padding: 2}} width="5%">
                <Checkbox
                  color="primary"
                  size="small"
                  style={{ padding: 2 }}
                  indeterminate={
                    props.selectedLoads.length > 0 && props.selectedLoads.length < rowCount()
                  }
                  checked={rowCount() > 0 && props.selectedLoads.length === rowCount()}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all circuit breakers',
                  }}
                />
              </TableCell>
            )}
            <TableCell align="center" width="19%" style={{padding: 0}}><b>Theta</b></TableCell>
            <TableCell align="center" width="19%" style={{padding: 0}}><b>Type</b></TableCell>
            <TableCell align="center" width="19%" style={{padding: 0}}><b>P</b></TableCell>
            <TableCell align="center" width="19%" style={{padding: 0}}><b>T</b></TableCell>
            <TableCell align="center" width="19%" style={{padding: 0}}><b>AM</b></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {props.selectedLoadcase
            ? props.selectedLoadcase.loads.map((load, loadIndex) => {

                console.log("Load=" + load)
                const isItemSelected = isSelected(loadIndex);

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`load-${loadIndex}`}
                    selected={isItemSelected}
                    // style={{ height: 41 }}
                  >
                    {tableUnlocked && (
                      <TableCell padding="checkbox" align='center' style={{padding: 2}} width="5%">
                        <Checkbox
                          color="primary"
                          size="small"
                          style={{ padding: 2 }}
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, loadIndex)}
                        />
                      </TableCell>
                    )}

                    {loadPointColumns.map(function (column) {
                      return (
                        <TableCell
                          align={'center'}
                          key={column}
                          width="19%"
                          style={{
                            padding: 0,
                          }}
                        >
                          {tableUnlocked
                            ? getField(load, column, loadIndex, props.loadcaseIdx)
                            : getValue(load, column)}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LoadsTable;
