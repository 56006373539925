import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196F3',
            light: '#64B6F7',
            dark: '#0050C8',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#E91E63',
            light: '#EF6191',
            dark: '#BE134D',
            contrastText: '#FFFFFF'
        },
        action: {
            hover: '#F5F5F5',
            selected: '#EBEBEB',
            disabledBackground: '#E0E0E0',
            disabled: '#BDBDBD',
        },
        info: {
            main: '#2196F3',
            dark: '#0B79D0',
            light: '#64B6F7',
            contrastText: '#ffffff'
        },
        error: {
            main: '#F44336',
            dark: '#E31B0C',
            light: '#F88078',
            contrastText: '#FFFFFF'
        },
        warning: {
            main: '#FF9800',
            dark: '#C77700',
            light: '#FFB547',
            contrastText: 'rgba(0,0,0,.87)'
        },
        success: {
            main: '#3B873E',
            dark: '#3B873E',
            light: '#7BC67E',
            contrastText: '#FFFFFF'
        },
        common: {
            white: '#fff',
            black: '#000',
        }
    },
    typography: {
        h1: {
            fontSize: '96px'
        },
        h2: {
            fontSize: '60px'
        },
        h3: {
            fontSize: '48px'
        },
        h4: {
            fontSize: '34px'
        },
        h5: {
            fontSize: '24px'
        },
        h6: {
            fontSize: '20px'
        },
        subtitle1: {
            fontSize: '16px'
        },
        subtitle2: {
            fontSize: '14px'
        },
        body1: {
            fontSize: '16px'
        },
        body2: {
            fontSize: '14px',
        },
        button: {
            fontSize: '14px'
        },
        caption: {
            fontSize: '12px'
        },
        overline: {
            fontSize: '10px'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    appBar: {
        height: 64
    }
});

export default theme;