import React from "react";
import CardContent from "@mui/material/CardContent";
import { Grid, Card } from "@mui/material";
import ContentLoading from "../components/common/ContentLoading";

const CookiePolicy = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <div>
      {isLoading ? (
        <div>
          <ContentLoading />
        </div>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Card>
                <CardContent>
                  <h1>Lorem ipsum</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Vitae elementum curabitur vitae nunc sed. Massa
                    massa ultricies mi quis hendrerit dolor magna eget. Egestas
                    tellus rutrum tellus pellentesque. Viverra nibh cras
                    pulvinar mattis nunc. Platea dictumst quisque sagittis purus
                    sit amet volutpat consequat. Dui nunc mattis enim ut tellus
                    elementum sagittis. Euismod nisi porta lorem mollis aliquam
                    ut porttitor leo a. Tristique senectus et netus et. Dui
                    faucibus in ornare quam viverra orci sagittis eu. Pharetra
                    diam sit amet nisl suscipit adipiscing bibendum est. A
                    iaculis at erat pellentesque adipiscing commodo elit at
                    imperdiet. Integer enim neque volutpat ac tincidunt vitae.
                    Iaculis nunc sed augue lacus. Augue lacus viverra vitae
                    congue eu consequat ac felis donec. Facilisis gravida neque
                    convallis a cras semper auctor. Eleifend quam adipiscing
                    vitae proin sagittis nisl rhoncus. Auctor urna nunc id
                    cursus metus aliquam eleifend mi in. Tristique sollicitudin
                    nibh sit amet commodo nulla facilisi nullam.
                  </p>
                  <h1>Lorem ipsum</h1>
                  <p>
                    Eu lobortis elementum nibh tellus. Ornare arcu dui vivamus
                    arcu felis. Orci eu lobortis elementum nibh tellus molestie
                    nunc non blandit. Id aliquet risus feugiat in. Adipiscing
                    bibendum est ultricies integer quis auctor. Lectus sit amet
                    est placerat. Lacinia at quis risus sed vulputate odio ut.
                    Purus sit amet volutpat consequat mauris nunc congue nisi.
                    Cursus sit amet dictum sit amet justo donec enim diam. Nisi
                    scelerisque eu ultrices vitae auctor eu augue. Eu consequat
                    ac felis donec et odio pellentesque diam.
                  </p>
                  <h1>Lorem ipsum</h1>
                  <p>
                    Dictum varius duis at consectetur. Orci ac auctor augue
                    mauris. Rutrum quisque non tellus orci. Lectus mauris
                    ultrices eros in cursus turpis. Tristique sollicitudin nibh
                    sit amet commodo nulla facilisi nullam vehicula. Non sodales
                    neque sodales ut etiam. Pellentesque habitant morbi
                    tristique senectus et netus et malesuada fames. Elementum
                    pulvinar etiam non quam lacus suspendisse faucibus. Vel quam
                    elementum pulvinar etiam non quam. Massa massa ultricies mi
                    quis hendrerit dolor. Ornare aenean euismod elementum nisi
                    quis eleifend. Nibh sit amet commodo nulla facilisi nullam
                    vehicula ipsum a. Et malesuada fames ac turpis egestas sed
                    tempus urna. Vel turpis nunc eget lorem dolor sed viverra
                    ipsum nunc. Ultricies mi eget mauris pharetra et ultrices
                    neque. Molestie a iaculis at erat pellentesque adipiscing
                    commodo elit.
                  </p>
                  <h1>Lorem ipsum</h1>
                  <p>
                    At imperdiet dui accumsan sit amet nulla facilisi morbi.
                    Massa massa ultricies mi quis hendrerit dolor. Blandit
                    cursus risus at ultrices mi tempus imperdiet nulla. In eu mi
                    bibendum neque egestas congue quisque egestas. Odio ut sem
                    nulla pharetra. Ac turpis egestas sed tempus urna et
                    pharetra pharetra massa. Sed velit dignissim sodales ut eu
                    sem integer. Commodo quis imperdiet massa tincidunt nunc
                    pulvinar. Vestibulum sed arcu non odio euismod lacinia at
                    quis. Lorem donec massa sapien faucibus et molestie ac
                    feugiat sed. Vel pharetra vel turpis nunc eget lorem dolor.
                    Lacus sed viverra tellus in hac habitasse platea. Bibendum
                    enim facilisis gravida neque convallis a cras semper.
                    Volutpat sed cras ornare arcu dui vivamus arcu felis
                    bibendum. Diam vel quam elementum pulvinar etiam non. Amet
                    est placerat in egestas. Orci ac auctor augue mauris. Mus
                    mauris vitae ultricies leo integer malesuada. Arcu felis
                    bibendum ut tristique et egestas quis ipsum.
                  </p>
                  <h1>Lorem ipsum</h1>
                  <p>
                    Convallis convallis tellus id interdum velit laoreet id.
                    Imperdiet sed euismod nisi porta lorem mollis aliquam ut
                    porttitor. At in tellus integer feugiat scelerisque. Id
                    interdum velit laoreet id donec ultrices tincidunt arcu non.
                    Quis imperdiet massa tincidunt nunc pulvinar. Est ultricies
                    integer quis auctor elit sed vulputate mi. Eleifend quam
                    adipiscing vitae proin sagittis nisl rhoncus mattis. Urna
                    nunc id cursus metus. Dictum sit amet justo donec enim diam
                    vulputate ut. Ultrices eros in cursus turpis massa. Semper
                    eget duis at tellus at urna.
                  </p>
                  <h1>Lorem ipsum</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Vitae elementum curabitur vitae nunc sed. Massa
                    massa ultricies mi quis hendrerit dolor magna eget. Egestas
                    tellus rutrum tellus pellentesque. Viverra nibh cras
                    pulvinar mattis nunc. Platea dictumst quisque sagittis purus
                    sit amet volutpat consequat. Dui nunc mattis enim ut tellus
                    elementum sagittis. Euismod nisi porta lorem mollis aliquam
                    ut porttitor leo a. Tristique senectus et netus et. Dui
                    faucibus in ornare quam viverra orci sagittis eu. Pharetra
                    diam sit amet nisl suscipit adipiscing bibendum est. A
                    iaculis at erat pellentesque adipiscing commodo elit at
                    imperdiet. Integer enim neque volutpat ac tincidunt vitae.
                    Iaculis nunc sed augue lacus. Augue lacus viverra vitae
                    congue eu consequat ac felis donec. Facilisis gravida neque
                    convallis a cras semper auctor. Eleifend quam adipiscing
                    vitae proin sagittis nisl rhoncus. Auctor urna nunc id
                    cursus metus aliquam eleifend mi in. Tristique sollicitudin
                    nibh sit amet commodo nulla facilisi nullam.
                  </p>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
export default CookiePolicy;
