// Styling for common/LinkCard component

const NewProjectDialogStyles = theme => ({
    selectInput: {
        '&:focus': {
            backgroundColor: 'rgba(0,0,0,0)'
        }
    },
    dialogContainer: {
        [theme.breakpoints.down('sm')]: {
            width: '95%',
            margin: '0'
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: '35%'
        },
    },
    buttonContainer: {
        padding: '16px 24px'
    }
});

export default NewProjectDialogStyles;

