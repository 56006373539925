// Add new links to the Navigation Sidebar
// Be sure to import the respective MUI icon (https://material-ui.com/components/material-icons/)

import HomeIcon from "@material-ui/icons/Home";
import AppsIcon from "@material-ui/icons/Apps";
import OrganizationIcon from "@material-ui/icons/Business";
import ArticleIcon from '@mui/icons-material/Article';
import React from "react";

const allApplications = [
  {
    title: "ASPEC LOADSGEN",
    to: "/apps/aspec-loadsgen",
  },
  {
    title: "FUSELOADS",
    to: "/apps/fuseloads",
  },
  {
    title: "WINGBOX",
    to: "/apps/wingbox",
  },
  {
    title: "RINGFRAME",
    to: "/apps/ringframe",
  },
  {
    title: "WINGSPAN",
    to: "/apps/wingspan",
  },
];

const bottonItems = [
  {
    title: "Terms and conditions",
    // icon: <ArticleIcon />,
    to: "/terms_and_conditions",
  },
  {
    title: "Privacy Policy",
    // icon: <ArticleIcon />,
    to: "/privacy_policy",
  },
  {
    title: "Cookie policy",
    // icon: <ArticleIcon />,
    to: "/cookie_policy",
  },
];

const getTopItems = (allowedApplications) => {
  return [
    {
      title: "Home",
      icon: <HomeIcon />,
      to: "/",
    },
    {
      title: "Applications",
      icon: <AppsIcon />,
      items: allApplications.filter(app => allowedApplications.includes(app.title)),
    },
    {
      title: "Organization",
      icon: <OrganizationIcon />,
      items: [
        {
          title: "Company Profile",
          to: "/company_profile",
        },
      ],
    },
  ];
}

export { getTopItems, bottonItems };
