import React from 'react';
import {
    Box,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Grid,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    actionContainer: {
        display: 'flex',
        paddingTop: 20,
    }
});

const ProjectListTableHeader = (props) => {

    const [aircraft, setAircraft] = React.useState('');
    const changeAircraft = (craft) => {
        setAircraft(craft);
    }

    const [search, setSearch] = React.useState('');
    const changeSearch = (input) => {
        setSearch(input);
    }

    const classes = useStyles();
    const {sortAircraft, sortBySearch} = props;

    return(

        <Grid className={classes.actionContainer} container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
                <Box>
                    <FormControl style={{width: '100%'}}>
                        <TextField
                            label={'Search'}
                            variant={'outlined'}
                            value={search}
                            onChange={(e) => {
                                changeAircraft('');
                                sortAircraft('');
                                sortBySearch(e.target.value);
                                changeSearch(e.target.value)
                            }}
                        />
                    </FormControl>
                </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <Box>
                    <FormControl style={{width: '100%'}}>
                      <TextField
                          label={'Aircraft'}
                          variant={'outlined'}
                          value={aircraft}
                          onChange={(e) => {
                              sortBySearch('');
                              changeSearch('')
                              changeAircraft(e.target.value);
                              sortAircraft(e.target.value);
                          }}
                      />
                </FormControl>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ProjectListTableHeader;